import styled, { css } from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import { breakpoints } from "../../core/utils/style";

export const Wrapper = styled.aside`
    background: rgba(55, 71, 79, 0.9);
    bottom: 0;
    color: var(--white);
    left: -77.77vw;
    position: fixed;
    top: 0;
    transition: left 0.3s linear;
    overflow-y: auto;
    width: 77.77vw;
    z-index: 999;

    @media (min-width: ${breakpoints.sm}) {
        max-width: 280px;
    }

    @media (min-width: ${breakpoints.md}) {
        background: rgba(55, 71, 79, 0.51);
        flex: 0 0 332px;
        max-width: 332px;
        overflow-y: hidden;
        position: static;
        width: 332px;
    }

    @media (max-width: ${breakpoints.md}) {
        z-index: 1200;
    }
    ${({ isVisible }) =>
        isVisible &&
        css`
            left: 0;
        `}
`;

export const DrawerTopSection = styled.section`
    border-bottom: 1px solid #c8c8c8;
    padding-top: 16px;
    padding-bottom: 16px;

    @media (min-width: ${breakpoints.md}) {
        padding-bottom: 18px;
        padding-top: 32px;
    }
`;

export const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.4);
    display: ${({ isVisible }) => (isVisible ? "block" : "none")};
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1000;
    @media (min-width: ${breakpoints.md}) {
        display: none;
    }
`;

export const LogoWrapper = styled.div`
    display: block;
    height: 26px;
    margin-bottom: 16px;
    padding-left: 16px;

    @media (min-width: ${breakpoints.md}) {
        display: none;
    }
`;

export const Logo = styled.img`
    display: block;
`;

export const Title = styled.h5`
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-bottom: 6px;
    margin-left: 16px;
    margin-right: 16px;

    @media (min-width: ${breakpoints.md}) {
        margin-left: 24px;
    }
`;

export const StockFilterWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 2px 16px 10px;

    @media (min-width: ${breakpoints.md}) {
        margin: 4px 24px 10px;
    }

    .MuiSwitch-thumb {
        width: 16px;
        height: 16px;
    }

    .MuiSwitch-root {
        height: 35px;
        padding-right: 0;
        width: 45px;
    }
    .MuiFormControlLabel-root {
        margin-left: 0;
        margin-right: 0;
    }
`;

export const StockFilterText = styled.h5`
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
`;

export const SelectFormControl = styled(FormControl)`
    background: white;
    border-radius: 5px;
    box-sizing: border-box;
    margin:  ${({ type }) => type === "noMargin" ? 0 : "0 16px !important"};
    width: calc(100% - 32px);

    @media (min-width: ${breakpoints.md}) {
        border-radius: 2px;
        margin:  ${({ type }) => type === "noMargin" ? 0 : "0 24px !important"};
        width: calc(100% - 48px);
    }

    fieldset {
        border: 0;
    }

    .MuiSelect-root {
        color: var(--main-color);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink,
    .PrivateNotchedOutline-legendNotched-4 {
        display: none;
    }

    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        color: #37474f;
        font-size: 14px;
    }
`;

export const Categories = styled.nav`
    height: ${({ height }) => height}px;
    overflow-y: auto;
    padding-top: 8px;

    @media (min-width: ${breakpoints.md}) {
        height: calc(100vh - 271px);
        padding-bottom: 30px;
    }
`;

export const CategoryList = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;
export const CategoryListItem = styled.li``;
export const CategoryListItemLink = styled.div`
    display: block;
    cursor: pointer;
    color: var(--white);
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 8px 8px 8px 16px;
    text-decoration: none;

    @media (min-width: ${breakpoints.md}) {
        font-size: 14px;
        padding-left: 24px;
        line-height: 20px;

        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    ${({ isactive }) =>
        isactive &&
        css`
            background: white;
            color: var(--main-color);

            &:hover {
                background: white;
            }
        `}
`;

export const CategorySubList = styled.ul`
    display: none;

    ${({ isactive }) =>
        isactive &&
        css`
            display: block;
        `}
`;
export const CategorySubListItem = styled.li`
    &:not(:last-child) {
        margin-bottom: 8px;
    }
`;
export const CategorySubListItemLink = styled.div`
    display: block;
    cursor: pointer;
    color: var(--white);
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 4px 43px;
    text-decoration: none;

    ${({ isactive }) =>
        isactive &&
        css`
            background: var(--white);
            color: var(--main-color);
        `}

    &:hover {
        @media (min-width: ${breakpoints.md}) {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    ${({ isactive }) =>
        isactive &&
        css`
            background: var(--white);
            color: var(--main-color);

            &:hover {
                @media (min-width: ${breakpoints.md}) {
                    background: var(--white);
                    color: var(--main-color);
                }
            }
        `}
`;

export const SubCategorySubListItemLink = styled(CategorySubListItemLink)`
    padding-left: 75px;
`;
