import {
    ADD_BASKET_FAILURE,
    ADD_BASKET_REQUEST,
    ADD_BASKET_SUCCESS,
    ADD_CART,
    ADD_DEFAULT_BASKET_FAILURE,
    ADD_DEFAULT_BASKET_REQUEST,
    ADD_DEFAULT_BASKET_SUCCESS,
    ADD_ITEM_BASKET_FAILURE,
    ADD_ITEM_BASKET_REQUEST,
    ADD_ITEM_BASKET_SUCCESS,
    ADD_ITEMS_TO_CART,
    DELETE_DEFAULT_BASKET_FAILURE,
    DELETE_DEFAULT_BASKET_REQUEST,
    DELETE_DEFAULT_BASKET_SUCCESS,
    EMPTY_BASKET_FAILURE,
    EMPTY_BASKET_REQUEST,
    EMPTY_BASKET_SUCCESS,
    DELETE_BASKET_FAILURE,
    DELETE_BASKET_REQUEST,
    DELETE_BASKET_SUCCESS,
    FETCH_BASKET_FAILURE,
    FETCH_BASKET_REQUEST,
    FETCH_BASKET_SUCCESS,
    FETCH_INFO_BASKET_REQUEST,
    ORDER_INFO_REQUEST,
    ORDER_INFO_SUCCESS,
    ORDER_INFO_FAILURE,
    CHANGE_COUNT_ITEMS,
    FETCH_INFO_BASKET_SUCCESS, 
    ORDER_SUBMIT_REQUEST, 
    ORDER_SUBMIT_SUCCESS, 
    ORDER_SUBMIT_FAILURE,
    FETCH_INFO_BASKET_FAILURE,
    SET_SOURCE_LOCATION
} from "../actionTypes/carts";

export const addCart = (cartName) => ({
    type: ADD_CART,
    payload: cartName,
});

export const addItemsToCart = (cartName, item, count) => ({
    type: ADD_ITEMS_TO_CART,
    payload: {
        cartName,
        item,
        count,
    },
});

export const fetchBasketRequest = () => ({
    type: FETCH_BASKET_REQUEST,
});

export const fetchBasketSuccess = (basketList) => ({
    type: FETCH_BASKET_SUCCESS,
    payload: basketList,
});

export const fetchBasketFailure = (error) => ({
    type: FETCH_BASKET_FAILURE,
    payload: error,
});

export const fetchAddItemBasketRequest = () => ({
    type: ADD_ITEM_BASKET_REQUEST,
});

export const fetchAddItemBasketSuccess = () => ({
    type: ADD_ITEM_BASKET_SUCCESS,
});

export const fetchAddItemBasketFailure = (error) => ({
    type: ADD_ITEM_BASKET_FAILURE,
    payload: error,
});

export const fetchInfoBasketRequest = () => ({
    type: FETCH_INFO_BASKET_REQUEST,
});

export const fetchInfoBasketSuccess = (payload) => ({
    type: FETCH_INFO_BASKET_SUCCESS,
    payload,
});

export const fetchInfoBasketFailure = (error) => ({
    type: FETCH_INFO_BASKET_FAILURE,
    payload: error,
});

export const addBasketRequest = () => ({
    type: ADD_BASKET_REQUEST,
});

export const addBasketSuccess = (basketList) => ({
    type: ADD_BASKET_SUCCESS,
    payload: basketList,
});

export const addBasketFailure = (error) => ({
    type: ADD_BASKET_FAILURE,
    payload: error,
});

export const addDefaultBasketRequest = () => ({
    type: ADD_DEFAULT_BASKET_REQUEST,
});

export const addDefaultBasketSuccess = (defaultBasketId) => ({
    type: ADD_DEFAULT_BASKET_SUCCESS,
    payload: defaultBasketId,
});

export const addDefaultBasketFailure = () => ({
    type: ADD_DEFAULT_BASKET_FAILURE,
});

export const deleteDefaultBasketRequest = () => ({
    type: DELETE_DEFAULT_BASKET_REQUEST,
});

export const deleteDefaultBasketSuccess = (defaultBasketId) => ({
    type: DELETE_DEFAULT_BASKET_SUCCESS,
    payload: defaultBasketId,
});

export const deleteDefaultBasketFailure = () => ({
    type: DELETE_DEFAULT_BASKET_FAILURE,
});

export const orderInfoRequest = () => ({
    type: ORDER_INFO_REQUEST,
});

export const orderInfoSuccess = (payload) => ({
    type: ORDER_INFO_SUCCESS,
    payload
});

export const orderInfoFailure = (payload) => ({
    type: ORDER_INFO_FAILURE,
    payload
});

export const changeCountItems = (payload) => ({
    type: CHANGE_COUNT_ITEMS,
    payload
});

export const orderSubmitRequest = () => ({
    type: ORDER_SUBMIT_REQUEST
});

export const orderSubmitSuccess = (payload) => ({
    type: ORDER_SUBMIT_SUCCESS,
    payload
});

export const orderSubmitFailure = (payload) => ({
    type: ORDER_SUBMIT_FAILURE,
    payload
});

export const deleteBasketFailure = (error) => ({
    type: DELETE_BASKET_FAILURE,
    payload: error,
});

export const deleteBasketRequest = () => ({
    type: DELETE_BASKET_REQUEST,
});

export const deleteBasketSuccess = (BasketId) => ({
    type: DELETE_BASKET_SUCCESS,
    payload: BasketId,
});

export const emptyBasketFailure = (error) => ({
    type: EMPTY_BASKET_FAILURE,
    payload: error,
});

export const emptyBasketRequest = () => ({
    type: EMPTY_BASKET_REQUEST,
});

export const emptyBasketSuccess = (BasketId) => ({
    type: EMPTY_BASKET_SUCCESS,
    payload: BasketId,
});

export const setSourceLocation = (sourceLocation) => ({
    type: SET_SOURCE_LOCATION,
    payload: sourceLocation,
});
