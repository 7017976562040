import React from "react";

export const MiniInput = ({ inputValue, minq, handleInputChange, style }) => {
    return (
        <>
            <input
                type="text"
                name="minQuantityValue"
                value={inputValue}
                placeholder={minq}
                onFocus={(event) => {
                    event.target.style.border = "1px solid #f28a22";
                    event.target.style.borderRadius = "2px";
                }}
                onBlur={(event) => {
                    event.target.style.border = "1px solid #000000";
                    event.target.style.borderRadius = "2px";
                }}
                style={{
                    width: "35px",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    borderRadius: "2px",
                    borderWidth: "1px",
                    ...style
                }}
                onInput={handleInputChange}
            />
        </>
    );
};