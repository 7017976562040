import styled from "styled-components";
import Link from "@material-ui/core/Link";

export const StyledLink = styled(Link)`
    font-size: 14px;
    display: flex;
    cursor: pointer;
    &.MuiTypography-colorPrimary {
        color: ${({ theme }) => theme.colors.font.darkBlue};
    }
`;