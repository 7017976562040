import React from "react";
import { PageLayout, FormContainer, LogoContainer } from "./style";
import { Logo } from "../../../../components/Logo";
import Typography from "../../../../components/Typography";
import { useTranslation } from "react-i18next";

export const RegistrationSuccess = () => {
    const { t } = useTranslation();

    return (
        <PageLayout>
            <FormContainer>
                <LogoContainer>
                    <Logo />
                </LogoContainer>
                <Typography textAlign="left">
                    {t("register.registrationSuccess")}
                </Typography>
            </FormContainer>
        </PageLayout>
    );
};
