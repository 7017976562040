import styled, { css } from "styled-components";
import Popper from "@material-ui/core/Popper";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import { breakpoints } from "../../core/utils/style";

export const TourOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background: ${ props => props.transparent ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.5)' };
`

export const ButtonHelp = styled(Fab)`
  position: fixed !important;
  right: 32px;
  bottom: 32px;
  z-index: 10003;
  background-color: ${ (props) => props.color === 'primary' ? '#ff9800' : '#465d66' } !important;
`

export const Popup = styled(Popper)`
  z-index: 10003;
`
export const ButtonTour = styled(Button)`
  position: fixed !important;
  right: 32px;
  bottom: 90px;
  z-index: 10003;
  font-size: 16px !important;
  padding: 10px 25px !important;
  color: #fff !important; 
  background-color: #465d66 !important;
  text-transform: capitalize !important;
`

export const Content = styled.div`
  max-width: 350px;
  display: grid;
  grid-gap: 15px 10px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 0 5px #465d66;

  @media (max-width: ${breakpoints.sm}) {
    max-width: 85vw;
  }
`;

export const Step = styled.div`
  position: relative;
  z-index: 2;

  grid-row: 1 / 2;
  grid-column: 1 / 2;

  margin-top: -5px;

  width: 30px;
  height: 30px;

  font-weight: bold;
  line-height: 30px;

  color: #fff;
  background-color: #ff9800;
  text-align: center;
  border-radius: 50%;

  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
`

export const Text = styled.div`
  position: relative;
  z-index: 2;

  grid-row: 1 / 2;
  grid-column: 2 / 5;

  white-space: pre-wrap;
`

export const ButtonNext = styled(Button)`
  position: relative;
  z-index: 2;

  grid-row: 2 / 3;
  grid-column: 4 / 5;
  
  color: white !important;
  background-color: #ff9800 !important;
  text-transform: capitalize !important;
`

export const ButtonSkip = styled.div`
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-top: -10px;
  margin-left: -2px;
  margin-right: -10px;

  grid-row: 1 / 3;
  grid-column: 5 / 5;

  svg {
    fill: #37474f !important;
    font-size: 21px;

    &:hover {
        fill: #ff9800 !important;
    }
  }
`

export const Arrow = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  background: #fff;
  border: 5px solid transparent;

  ${({ placement }) =>
  ((placement === 'bottom') || (placement === 'bottom-start')) ?
    css`
      top: 0;
      left: 0;
      margin-left: 10px;
      margin-top: -15px;
      border-left-color: #465d66;
      border-top-color: #465d66;
      transform: rotate(45deg);
    `
    :
    placement === 'bottom-middle' ?
    css`
      top: 0;
      left: 0;
      margin-left: 50px;
      margin-top: -15px;
      border-left-color: #465d66;
      border-top-color: #465d66;
      transform: rotate(45deg);
    `
    :
    placement === 'bottom-end' ?
    css`
      top: 0;
      right: 0;
      margin-right: 20px;
      margin-top: -15px;
      border-left-color: #465d66;
      border-top-color: #465d66;
      transform: rotate(45deg);
    ` :
    placement === 'top' ?
    css`
      bottom: 0;
      left: 0;
      margin-left: 10px;
      margin-bottom: -15px;
      border-right-color: #465d66;
      border-bottom-color: #465d66;
      transform: rotate(45deg);
    ` :
    placement === 'top-middle' ?
    css`
      bottom: 0;
      left: 0;
      margin-left: 50px;
      margin-bottom: -15px;
      border-right-color: #465d66;
      border-bottom-color: #465d66;
      transform: rotate(45deg);
    ` :
    placement === 'top-end' ?
    css`
      bottom: 0;
      right: 0;
      margin-right: 10px;
      margin-bottom: -15px;
      border-right-color: #465d66;
      border-bottom-color: #465d66;
      transform: rotate(45deg);
    ` :
    ((placement === 'right') || (placement === 'right-start')) ?
    css`
      left: 0;
      margin-left: -15px;
      border-left-color: #465d66;
      border-bottom-color: #465d66;
      transform: rotate(45deg);
      ` 
    :
    ((placement === 'left') || (placement === 'left-start')) &&
    css`
      right: 0;
      margin-right: -15px;
      border-right-color: #465d66;
      border-top-color: #465d66;
      transform: rotate(45deg);
    ` 
  } 

`;
