import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "../../../components/Typography";

export const ResetLinkSuccessfullySent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Typography textAlign="left" customVariant="subheadingSlim">
                {t("passwordReset.resetLinkSuccessfullySentStep.text1")}
            </Typography>
            <Typography textAlign="left" customVariant="subheadingSlim">
                {t("passwordReset.resetLinkSuccessfullySentStep.text2")}
            </Typography>
        </>
    );
};
