import React, { memo } from "react";
import Box from "@material-ui/core/Box";

import Typography from "../../../../../Typography";
import { Separator, SeparatorDirection } from "../../../../../Separator";
import { StyledCircularProgress } from "../../../../../CircularProgress";

const InfoCodes = ({ codes = [], isLoadingDetails }) => {
    return (
        <Box minHeight="100px">
            {isLoadingDetails && (
                <Box justifyContent="center" my="xl">
                    <StyledCircularProgress disableShrink/>
                </Box>
            )}
            {!isLoadingDetails && Boolean(codes.length) && (
                <>
                    {codes.map(({ label, value }) => (
                        <React.Fragment key={label + value}>
                            <Box display="flex" justifyContent="flex-start">
                                <Box width="50%" padding="10px 32px">
                                    <Typography
                                        textAlign="left"
                                        customVariant="body"
                                        texttransform="uppercase"
                                        fontWeight={700}
                                    >
                                        {label}
                                    </Typography>
                                </Box>

                                <Box width="50%" padding="10px 32px">
                                    <Typography
                                        textAlign="left"
                                        customVariant="body"
                                        texttransform="uppercase"
                                        fontWeight={400}
                                    >
                                        {value}
                                    </Typography>
                                </Box>
                            </Box>
                            <Separator
                                direction={SeparatorDirection.Horizontal}
                            />
                        </React.Fragment>
                    ))}
                </>
            )}
        </Box>
    );
};

export default memo(InfoCodes);
