import {
    SEND_RESET_LINK_REQUEST,
    SEND_RESET_LINK_SUCCESS,
    SEND_RESET_LINK_FAILURE,
    VALIDATE_RESET_LINK_REQUEST,
    VALIDATE_RESET_LINK_SUCCESS,
    VALIDATE_RESET_LINK_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
} from "../actionTypes/passwordReset";

export const sendResetLinkRequest = () => ({
    type: SEND_RESET_LINK_REQUEST,
});

export const sendResetLinkSuccess = (data) => ({
    type: SEND_RESET_LINK_SUCCESS,
    payload: data,
});

export const sendResetLinkFailure = (error) => ({
    type: SEND_RESET_LINK_FAILURE,
    error,
});

export const validateResetLinkRequest = () => ({
    type: VALIDATE_RESET_LINK_REQUEST,
});

export const validateResetLinkSuccess = (data) => ({
    type: VALIDATE_RESET_LINK_SUCCESS,
    payload: data,
});

export const validateResetLinkFailure = (error) => ({
    type: VALIDATE_RESET_LINK_FAILURE,
    error,
});

export const changePasswordRequest = () => ({
    type: CHANGE_PASSWORD_REQUEST,
});

export const changePasswordSuccess = (data) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data,
});

export const changePasswordFailure = (error) => ({
    type: CHANGE_PASSWORD_FAILURE,
    error,
});
