import * as passwordResetActions from "../actions/passwordReset";
import api from "../../api";
import resources from "../../api/resources";

export const sendResetLink = (email) => {
    return async (dispatch) => {
        dispatch(passwordResetActions.sendResetLinkRequest());
        try {
            const { data } = await api.post(
                resources.passwordReset.sendResetLink,
                { email }
            );
            return dispatch(passwordResetActions.sendResetLinkSuccess(data));
        } catch (error) {
            return dispatch(passwordResetActions.sendResetLinkFailure(error));
        }
    };
};

export const validateResetLink = (token, uid) => {
    return async (dispatch) => {
        dispatch(passwordResetActions.validateResetLinkRequest());
        try {
            const { data } = await api.post(
                resources.passwordReset.validateResetLink,
                { token, uid }
            );
            return dispatch(
                passwordResetActions.validateResetLinkSuccess(data)
            );
        } catch (error) {
            return dispatch(
                passwordResetActions.validateResetLinkFailure(error)
            );
        }
    };
};

export const changePassword = (newPassword, confirmPassword, token, uid) => {
    return async (dispatch) => {
        dispatch(passwordResetActions.changePasswordRequest());
        try {
            const { data } = await api.post(
                resources.passwordReset.changePassword,
                {
                    new_password1: newPassword,
                    new_password2: confirmPassword,
                    token,
                    uid,
                }
            );
            return dispatch(passwordResetActions.changePasswordSuccess(data));
        } catch (error) {
            return dispatch(passwordResetActions.changePasswordFailure(error));
        }
    };
};
