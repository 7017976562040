import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ChooseCartTitle, CartFlyoutWrapper } from "../../Listing/components/CartButton/style";
import Button from "../../Button";
import { Input } from "../../Form";
import Typography from "../../Typography";
import { Box } from "../../Box";
import { UserMenuSeparator } from "../style";
import { SelectedCategoriesWrapper } from "../../Listing/style";
import { Chip } from "@material-ui/core";
import { setTagItem } from "../../../core/redux/asyncActions/global";

export const TagsFlyout = React.forwardRef(
    ({ close, id, title }, ref) => {
        const [newTag, setNewTag] = useState("");
        const [errorMessageVisible, setErrorMessageVisible] = useState(false);
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const { tagsList } = useSelector(
            (state) => state.global
        );

        const handleTagItem = (e) => {
            const tag = e?.target?.textContent;
            dispatch(setTagItem(tag, id, title));
            close();
        };

        const createTag = (e) => {
            if (newTag) {
                dispatch(setTagItem(newTag, id, title));
                close();
            } else {
                setErrorMessageVisible(true);
            }
        };

        const handleTag = useCallback(
            ({ target: { value } }) => {
                setNewTag(value);
                if (newTag) {
                    setErrorMessageVisible(false);
                }
            },
            [newTag]
        );

        const stopPropagation = (e) => {
            e.stopPropagation()
        }

        return (
            <CartFlyoutWrapper ref={ref} onClick={stopPropagation}>
                <div>
                    <ChooseCartTitle>
                        {t('header.tags.addTag')}
                    </ChooseCartTitle>
                </div>
                <SelectedCategoriesWrapper style={{ marginBottom: "10px" }}>
                    {tagsList?.results?.map((tag) => (
                        <Chip
                            label={tag.title}
                            onClick={handleTagItem}
                            key={tag.id}
                            style={{ margin: "5px" }}
                        />))
                    }
                </SelectedCategoriesWrapper>
                <UserMenuSeparator />
                <Box mb="xs">
                    <Input
                        type="text"
                        value={newTag}
                        placeholder={
                            t('header.tags.inputTag')
                        }
                        error={errorMessageVisible}
                        onClick={(e) => e?.stopPropagation}
                        onChange={handleTag}
                    />
                </Box>
                {errorMessageVisible &&
                    <Box mb="xs" justifyContent="center">
                        <Typography color="error" customVariant="caption">
                            {t('header.tags.addTag')}!
                        </Typography>
                    </Box>
                }
                <Button
                    buttonVariant={"Light"}
                    height="48px"
                    onClick={createTag}
                >
                    {t('header.tags.createTag').toUpperCase()}
                </Button>
            </CartFlyoutWrapper>
        );
    }
);
