import styled, { css } from "styled-components";
import { breakpoints } from "../../core/utils/style";

// TODO: clear this file of unused style

export const Wrapper = styled.section`
    padding: 20px 16px;
    background: rgba(55, 71, 79, 0.11);
    width: 100%;
    height: 100vh;
    overflow: auto;
    @media (min-width: ${breakpoints.md}) {
        padding: 32px 23px;
        width: calc(100vw - 332px); // drawer width
        height: calc(100vh - 64px); // header height
        flex: 1 1 calc(100vw - 332px);
    }
`;

export const Title = styled.h3`
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 7px;
    text-transform: uppercase;

    @media (min-width: ${breakpoints.md}) {
        font-size: 25px;
        margin-bottom: 14px;
    }
`;

export const Filters = styled.div`
    margin-top: 7px;
    @media (min-width: ${breakpoints.md}) {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 26px;
        margin-top: 0px;
    }
`;
export const FilterLine = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    
    .MuiFormControlLabel-root {
        margin-right: 0;
    }

    &:last-child {
        @media (min-width: ${breakpoints.md}) {
            margin-left: 20px;
        }
    }
`;
export const FilterTitle = styled.h6`
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000;
    @media (min-width: ${breakpoints.md}) {
        font-size: 14px;
        margin-right: 12px;
    }
`;

export const ProductListHeader = styled.header`
    display: none;
    margin-bottom: 10px;
    @media (min-width: ${breakpoints.md}) {
        display: flex;
    }
`;
export const ProductListHeaderItem = styled.div`
    font-weight: bold;
    font-size: 14.5px;
    flex: 0 0 auto;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: center;

    &:first-child {
        width: 9%;
        margin-right: 1%;
    }
    &:nth-child(2) {
        width: 9%;
        margin-right: 1%;
        text-align: left;
        ${({ type }) =>
        type === "order" &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 19%;
                    margin-right: 1%;
                }
            `
    }
    }
    &:nth-child(3) {
        width: 9%;
        margin-right: 1%;
        text-align: left;
    }
    &:nth-child(4) {
        width: 9%;
        margin-right: 1%;
        text-align: left;
        ${({ type }) =>
        type === "orderItem" &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 34%;
                    margin-right: 1%;
                }
            `
    }
    }
     &:nth-child(5) {
        width: 26%;
        margin-right: 1%;
        text-align: left;
        ${({ type }) =>
        type === "order" ?
            css`
                @media (min-width: ${breakpoints.md}) {
                    width: 14%;
                    margin-right: 1%;
                }
            `
            :
            type === "orderItem" &&
            css`
                @media (min-width: ${breakpoints.md}) {
                    width: 16%;
                    margin-right: 1%;
                }
            `
    }
    }
    &:nth-child(6) {
        width: 7%;
        margin-right: 1%;
        ${({ showQuantity }) =>
        showQuantity === false &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 17%;
                    margin-right: 1%;
                }
        `}
    }
    &:nth-child(7) {
        width: 9%;
        margin-right: 1%;
        ${({ showQuantity }) =>
        showQuantity === false &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 14%;
                    margin-right: 1%;
                }
            `
    }
        ${({ type }) =>
        type === "order" &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 26%;
                    margin-right: 1%;
                }
            `
    }
    }
`;

export const ProductImageWrapper = styled.div`
    margin-right: 1%;
    flex: 0 0 9%;
    text-align: center;
    width: 9%;

    @media (min-width: ${breakpoints.md}) {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        margin-right: 1%;
        width: 9%;
    }
`;

export const ProductSpec = styled.div`
    flex: 0 0 50%;
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    color: #37474f;
    word-break: break-word;

    @media (min-width: ${breakpoints.md}) {
        flex: 0 0 auto;
        text-align: center;
        margin-bottom: 0;
        font-size: 16px;
        position: relative;
    }

    &:nth-child(1) {
        @media (min-width: ${breakpoints.md}) {
            width: 9%;
            margin-left: 0;
            margin-right: 1%;
        }
    }
    &:nth-child(2) {
        @media (min-width: ${breakpoints.md}) {
            width: 9%;
            margin-right: 1%;
            text-align: left;
        }

        ${({ type }) =>
        type === "order" &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 19%;
                    margin-right: 1%;
                }
            `
    }
    }
    &:nth-child(3) {
        @media (min-width: ${breakpoints.md}) {
            width: 9%;
            margin-right: 1%;
            text-align: left;
        }
    }
    &:nth-child(4) {
        @media (min-width: ${breakpoints.md}) {
            width: 9%;
            margin-right: 1%;
            text-align: left;
        }

        ${({ type }) =>
        type === "orderItem" &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 34%;
                    margin-right: 1%;
                }
            `
    }
    }

    &:nth-child(5) {
        @media (min-width: ${breakpoints.md}) {
            width: 26%;
            margin-right: 1%;
            text-align: left;
        }

        ${({ type }) =>
        type === "order" ?
            css`
                @media (min-width: ${breakpoints.md}) {
                    width: 14%;
                    margin-right: 1%;
                }
            `
            :
            type === "orderItem" &&
            css`
                @media (min-width: ${breakpoints.md}) {
                    width: 16%;
                    margin-right: 1%;
                }
            `
    }
    }
    &:nth-child(6) {
        @media (min-width: ${breakpoints.md}) {
            width: 7%;
            margin-right: 1%;
        }
        ${({ showQuantity }) =>
        showQuantity === false &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 17%;
                    margin-right: 1%;
                }
        `}
    }
    &:nth-child(7) {
        @media (min-width: ${breakpoints.md}) {
            width: 9%;
            margin-right: 1%;
        }
        ${({ showQuantity }) =>
        showQuantity === false &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 14%;
                    margin-right: 1%;
                }
        `}
        ${({ type }) =>
        type === "order" &&
        css`
                @media (min-width: ${breakpoints.md}) {
                    width: 26%;
                    margin-right: 1%;
                }
            `
    }
    }

    &:nth-child(8) {
        @media (min-width: ${breakpoints.md}) {
            width: 14%;
            margin-right: 1%;
        }
    }
}
    
`;

export const ProductSpecTitle = styled.span`
    @media (min-width: ${breakpoints.md}) {
        display: none;
    }
`;

export const Quantity = styled.div`
    width: 88px;
    align-items: center;
`;

export const ProductListItem = styled.div`
    background: ${({ autoDelivery }) => (autoDelivery ? "#fce2c7" : "#ffffff")};
    flex: 0 0 50%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    display: flex;
    margin-bottom: 8px;
    padding: 16px 0;
    transition: 0.05s;
    cursor: pointer;

    @media (min-width: ${breakpoints.md}) {
        ${({ type }) =>
        type === "listing" &&
        css`min-height: 70px;
                padding: 8px 0;`}
        ${({ type }) =>
        !type &&
        css`height: fit-content;
                padding: 16px 0;`}
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 10px;
        
        &:hover {
            background: #f3f3f3;
        }
    }
`;

export const SelectedCategoriesWrapper = styled.div`
    flex: 0 auto;
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: column;

    ${({ type }) =>
        type === "vendor" &&
        css`flex-flow: row wrap;`}
    @media (min-width: ${breakpoints.md}) {
        justify-content: flex-start;
        flex-flow: row wrap;
    }
`;

export const SelectedCategoryText = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(55, 71, 79, 0.11);
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #37474f;
    word-break: break-word;
    padding: 5px 10px;
    margin: 10px 10px 0 0;
    width: fit-content;
    
    @media (min-width: ${breakpoints.md}) {
        font-size: 16px;
    }
    svg {
        height: 16px;
        width: 16px; 
 	cursor: pointer;
	@media (min-width: ${breakpoints.md}) {
            height: 18px;
            width: 18px;
        }
  }
`;

export const PublicProductListHeaderItem = styled.div`
    font-weight: bold;
    font-size: 16px;
    flex: 0 0 auto;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000;
    text-align: center;

    &:first-child {
        width: 10%; 
        margin-right: 2%;
    }
    &:nth-child(2) {
        width: 10%;
        margin-right: 2%;
        text-align: left;
    }
    &:nth-child(3) {
        width: 10%;
        margin-right: 2%;
        text-align: left;
    }
    &:nth-child(4) {
        width: 35%;
        margin-right: 2%;
        text-align: left;
    }
    &:nth-child(5) {
        width: 25%;
        margin-right: 2%;
    }
`;

export const PublicProductSpecs = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;

    @media (min-width: ${breakpoints.md}) {
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-bottom: 0;
        flex: 0 0 auto;
        width: 100%;
        margin-left: 0;
    }

    @media (max-width: ${"1280px"}) {
        margin-left: 3%;
    }
`;

export const PublicProductSpec = styled.div`
    cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
    flex: 0 0 50%;
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    color: #37474f;
    word-break: break-word;
    cursor: pointer;

    @media (min-width: ${breakpoints.md}) {
        flex: 0 0 auto;
        text-align: center;
        margin-bottom: 0;
        font-size: 16px;
        position: relative;
    }
    
    &:nth-child(2) {
        @media (min-width: ${breakpoints.md}) {
            width: 10%;
            margin-left: 0;
            margin-right: 2%;
            text-align: left;
        }
    }
    &:nth-child(3) {
        @media (min-width: ${breakpoints.md}) {
            width: 10%;
            margin-right: 2%;
            text-align: left;
        }
    }
    &:nth-child(4) {
        @media (min-width: ${breakpoints.md}) {
            width: 35%;
            margin-right: 2%;
            text-align: left;
        }
    }
    &:nth-child(5) {
        @media (min-width: ${breakpoints.md}) {
            width: 25%;
            margin-right: 2%;
        }
    }
`;

export const PublicProductImageWrapper = styled.div`
    flex: 0 0 9%;
    text-align: center;
    width: 10%;

    @media (min-width: ${breakpoints.md}) {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        margin-right: 2%;
        width: 10%;
    }
`;
export const PublicProductItemContent = styled.div`
    flex: 1 1 auto;

    @media (min-width: ${breakpoints.md}) {
        display: flex;
        flex-wrap: nowrap;
        width: 90%;
    }
`;

export const Availability = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #37474f;
    margin-bottom: 15px;
`;

export const PopoutFlyoutWrapper = styled.div` 
        background: #ffffff;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 4px 16px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        padding: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 91.11vw;
        z-index: 1100;

        @media (min-width: ${breakpoints.md}) {
            position: absolute;
            top: 40vh;
            left: 45vw;
            transform: none;
            width: 350px;
            z-index: 300;
        }

        .MuiFormControl-root {
            margin-bottom: 24px;
            display: flex;
        }
`;

export const PopoutFlyoutCloseButton = styled.div`
    text-align: right; 
`;

export const PopoutFlyoutLoginButton = styled.div`
    cursor: pointer;
    &:hover {
        text-decoration: underline; 
    }
`;

export const PopoutFlyoutContent = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding-bottom: 10px;
    padding-right: 15px;
    color: #37474f;   
    text-align: left;
`;