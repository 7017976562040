import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import Header from "../../components/Header";
import Wrapper from "../../components/Wrapper";
import Drawer from "../../components/Drawer";
import { fetchMenuProducts } from "../../core/redux/asyncActions/global";
import { ProductListing } from "../../components/Listing";

export default function PublicSearchPage() {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.global.lang);

    useEffect(() => {
        dispatch(fetchMenuProducts());
    }, [language, dispatch]);

    return (
        <div>
            <Header page="publicSearch" />
            <Wrapper>
                <Drawer />
                <ProductListing showPublicProducts={true} />
            </Wrapper>
        </div>
    );
}
