import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const ItemVehicleWrapper = styled(Grid)`
    padding: 16px 12px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    background: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 2px;
`;

export const TitleTypography = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: #37474f;
    padding-bottom: 8px;
`;

export const TxtTypography = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #37474f;
`;
