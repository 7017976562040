import styled from "styled-components";
import { Box, AccordionSummary } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";

import Typography from "../../components/Typography";
import { breakpoints } from "../../core/utils/style";

export const WrapperItem = styled.section`
    padding: 20px 16px;
    width: 100%;

    @media (min-width: ${breakpoints.md}) {
        padding: 32px 23px;
        width: calc(100vw - 332px); // drawer width
        height: calc(100vh - 64px); // header height
        overflow: auto;
        flex: 1 1 calc(100vw - 332px);
        display: flex;
        flex-direction: column;
    }
`;

export const ContainerItem = styled(Box)`
    width: 100%;
    height: 80vh;

    @media (max-width: ${breakpoints.sm}) {
        padding: 0;
        height: 100%;
    }
`;

export const ContentWrapper = styled(Box)`
    display: flex;
    @media (max-width: ${"1280px"}) {
        display: block;
        flex-direction: column;
    }
`;

export const ProductImage = styled(Box)`
    border: 1px solid #e5e5e5;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media (max-width: ${"1600px"}) {
        width: 40%;
    }

    @media (max-width: ${"1280px"}) {
        height: fit-content;
        width: 100%;
    }

    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
    }

    .tooltip {
        cursor: pointer;
        position: absolute;
        color: #fff;
        padding: 5px 10px;
        background: rgba(0, 0, 0, 0.8);
        transform: translate3d(-50%, -50%, 0);
        border-radius: 50%;
        pointer-events: none;
        z-index: 10;
    }
`;

export const OrderList = styled(Box)`
    border: 1px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    width: 50%;
    overflow-y: auto;

    @media (max-width: ${"1600px"}) {
        width: 60%;
    }

    @media (max-width: ${"1280px"}) {
        width: 100%;
        overflow-y: initial;
    }
`;

export const Container = styled(Box)`
    display: grid;
    grid-template-columns: 20px minmax(100px, 200px) minmax(100px, 200px);
    grid-gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Number = styled(Typography)`
    border: 1px solid #37474f;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: ${({ isactive }) => isactive && "#fafafa"};
    background-color: ${({ isactive }) => isactive && "#37474F"};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TableContainer = styled(Box)`
    background-color: #fafafa;
    white-space: nowrap;
    overflow-x: auto;
`;

export const ColumnContainer = styled(Box)`
    display: flex;
    width: 100%;
    margin-bottom: 32px;

    @media (max-width: ${breakpoints.sm}) {
        width: 100%;
        overflow-y: initial;
        flex-direction: column;
        margin-bottom: 16px;
    }
`;

export const CustomAccordionSummary = styled(AccordionSummary)`
    .MuiAccordionSummary-expandIcon {
        transform: rotate(270deg);

        .MuiSvgIcon-root {
            color: ${({ isdisabled }) => (isdisabled ? "gray" : orange[500])};
        }
    }
    .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(360deg);
    }
`;

export const TableColumn = styled(Box)`
    display: flex;
    flex-grow: 1;
    text-align: left;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: ${breakpoints.sm}) {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
    }
`;

export const TabelBoldElement = styled(Box)`
    font-weight: bold;
    padding: 12px 16px 8px;
    border-bottom: 1px solid #e5e5e5;

    @media (max-width: ${breakpoints.sm}) {
        border-bottom: 0;
        padding: 16px;
    }
`;

export const PriceContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    @media (max-width: ${breakpoints.sm}) {
        padding: 0;
        justify-content: flex-end;
    }
`;

export const QuantityContainer = styled(Box)`
    @media (max-width: ${breakpoints.sm}) {
        width: 56%;
        justify-content: flex-end;
    }
`;
