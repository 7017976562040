import {
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
} from "../actionTypes/register";

export const registerRequest = () => ({
    type: REGISTER_REQUEST,
});

export const registerSuccess = (data) => ({
    type: REGISTER_SUCCESS,
    payload: data,
});

export const registerFailure = (error) => ({
    type: REGISTER_FAILURE,
    error,
});
