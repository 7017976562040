import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getAuthState } from "../../../core/api/authHelper";

export const ROUTE_TYPES = {
    Guest: "guest",
    Private: "private",
};

const AuthRoute = (props) => {
    const { type } = props;
    const userAuthenticatedStatus = useSelector(
        (state) => state.global.userAuthenticated
    );
    const authState = getAuthState();
    const isUserAuthenticated =
        userAuthenticatedStatus && authState?.accessToken;
    if (type === ROUTE_TYPES.Guest && isUserAuthenticated)
        return <Redirect to="/home" />;
    else if (type === ROUTE_TYPES.Private && !isUserAuthenticated)
        return <Redirect to="/" />;

    return <Route {...props} />;
};

export { AuthRoute };