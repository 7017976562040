import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ChooseCartTitle, CartFlyoutWrapper } from "../../Listing/components/CartButton/style";
import Button from "../../Button";
import { Input } from "../../Form";
import Typography from "../../Typography";
import { Box } from "../../Box";
import { setComment } from "../../../core/redux/asyncActions/global";

export const CommentFlyout = React.forwardRef(
    ({ id, close, title }, ref) => {
        const [newComment, setNewComment] = useState("");
        const [errorMessageVisible, setErrorMessageVisible] = useState(false);
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const createComment = (e) => {
            if (newComment) {
                dispatch(setComment(newComment, id, title));
                close();
            } else {
                setErrorMessageVisible(true);
            }
        };

        const handleComment = useCallback(
            ({ target: { value } }) => {
                setNewComment(value);
                if (newComment) {
                    setErrorMessageVisible(false);
                }
            },
            [setNewComment, newComment]
        );

        const stopPropagation = (e) => {
            e.stopPropagation()
        }

        return (
            <CartFlyoutWrapper ref={ref} onClick={stopPropagation}>
                <div>
                    <ChooseCartTitle>
                        {t("header.comments.addComment")}
                    </ChooseCartTitle>
                </div>
                <Box mb="xs">
                    <Input
                        type="text"
                        value={newComment}
                        placeholder={
                            t("header.comments.inputComment")
                        }
                        error={errorMessageVisible}
                        onClick={stopPropagation}
                        onChange={handleComment}
                    />
                </Box>
                {errorMessageVisible &&
                    <Box mb="xs" justifyContent="center">
                        <Typography color="error" customVariant="caption">
                            {t("header.comments.inputComment")}!
                        </Typography>
                    </Box>
                }
                <Button
                    buttonVariant={"Light"}
                    height="60px"
                    onClick={createComment}
                >
                    {t("header.comments.createComment").toUpperCase()}
                </Button>
            </CartFlyoutWrapper>
        );
    }
);
