import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popper } from "../../../Popper";
import { CartFlyout } from "./CartFlyout";
import { QuantityButton, QuantityButtonText } from "./style";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import { useDispatch, useSelector } from "react-redux";
import { addBasket, addItemToBasket } from "../../../../core/redux/asyncActions/carts";
import TourPopup from "../../../Tour/TourPopup";
import { useTourController } from "../../../Tour";
import { useHistory } from "react-router";
import { useMediaQuery, useTheme as useThemeMaterial } from "@material-ui/core";


export const CartButtonSizes = {
    Small: "small",
    Large: "large",
};

export const CartButton = ({
    count,
    item,
    disabled,
    size = CartButtonSizes.Small,
    placement = "left-start",
    showTourTooltip,
    ...rest
}) => {
    const [cartPopupRef, setCartPopupRef] = useState();
    const { currentStep } = useTourController();

    const themeMaterial = useThemeMaterial();
    const isMobileScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));

    const { t } = useTranslation();
    const defaultCart = useSelector((state) => state.carts.defaultBasket);
    const language = useSelector((state) => state.global.lang);

    const history = useHistory();
    const dispatch = useDispatch();
    const carts = useSelector((state) => state.carts?.cartsList || []);
    const sourceLocation = useSelector((state) => state.carts.sourceLocation);
    const [popperVisibility, setPopperVisibility] = useState(currentStep === 16);
    const buttonRef = useRef();

    const closePopper = useCallback(() => {
        setPopperVisibility(false);
    }, [setPopperVisibility]);

    const handleAddItemToExistingCart = useCallback(
        (cartId, titleCard) => {
            if (count) {
                dispatch(addItemToBasket(cartId, item.item_id || item.id, +count, titleCard, item?.description, false, sourceLocation))
            }
            closePopper();
        },
        [dispatch, closePopper, item, count, language]
    );

    const togglePopper = useCallback(
        (e) => {
            e.stopPropagation();
            const defCart = carts.find((item) => item.id === +defaultCart)
            if (defaultCart && defCart && count) {
                handleAddItemToExistingCart(defCart.id, defCart.title)
                return ''
            }
            setPopperVisibility((curValue) => !curValue);
        },
        [defaultCart, count, carts, handleAddItemToExistingCart]
    );

    const handleCreateCartAndAddItem = useCallback(
        async (newCartName) => {
            const cartId = await dispatch(addBasket(newCartName));
            if (count && cartId) {
                await handleAddItemToExistingCart(cartId, newCartName)
                return cartId;
            }
            else closePopper();
        },
        [dispatch, closePopper, item, handleAddItemToExistingCart, count]
    );

    const handleTooltipHide = async () => {
        let cartId;

        if (!carts.length) {
            cartId = await handleCreateCartAndAddItem('Example cart')
        } else {
            cartId = carts[0].id
            await handleAddItemToExistingCart(cartId, carts[0].title)
        }

        setTimeout(() => {
            history.push(`/order/${cartId}`);
        }, 1000);
    }

    useEffect(() => {
        if (showTourTooltip && currentStep === 16) {
            setPopperVisibility(true);
        }
    }, [ currentStep ])

    return (
        <>
            <QuantityButton
                ref={buttonRef}
                onClick={togglePopper}
                size={size}
                disabled={disabled}
                {...rest}
            >
                {size === CartButtonSizes.Small ? (
                    <LocalMallOutlinedIcon />
                ) : (
                    <QuantityButtonText>
                        {t("productsList.cartButton.title")}
                    </QuantityButtonText>
                )}
            </QuantityButton>
            <Popper
                id={item.id}
                visibility={popperVisibility}
                anchorEl={buttonRef?.current}
                onClickAway={closePopper}
                placement={placement}
            >
                <>
                    <CartFlyout
                        ref={ ref => setCartPopupRef(ref) }
                        onNewCart={handleCreateCartAndAddItem}
                        onAddItem={handleAddItemToExistingCart}
                        carts={carts}
                    />
                </>
            </Popper>
            { showTourTooltip && popperVisibility && cartPopupRef && (
                <TourPopup
                    delay={200}
                    step={17}
                    anchorEl={ cartPopupRef }
                    placement={ isMobileScreen ? "top" : "left-start" }
                    onHide={ handleTooltipHide }
                >
                    { t('appTour.chooseBasket') }
                </TourPopup>
            )}
        </>
    );
};
