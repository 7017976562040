export const theme = {
    colors: {
        background: "#F4F4F4",
        orange: "#F28A22",
        orangeDark: "#bf6102",
        otherOrange: "#f27a02",
        grey: "#99A1A5",
        white: "#ffffff",
        font: {
            lighter: "#9AA1A5",
            light: "#888888",
            base: "#4F4F4F",
            dark: "#202020",
            error: "#a94442",
            darkBlue: "#37474F",
            black: "#000000"
        },
        border: {
            darker: "#E0E0E0",
            lighter: "#EEEEEE",
        },
    },
    spaces: {
        xs: "8px",
        s: "12px",
        m: "24px",
        l: "32px",
        xl: "48px",
    },
    typography: {
        displayXL: {
            fontSize: "48px",
            lineHeight: "56px",
            fontWeight: 700,
        },
        displayL: {
            fontSize: "40px",
            lineHeight: "48px",
            fontWeight: 700,
        },
        displayM: {
            fontSize: "32px",
            lineHeight: "34px",
            fontWeight: 700,
        },
        displayS: {
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 700,
        },
        displayS2: {
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: 400,
        },
        heading: {
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: 700,
        },
        subheading: {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 600,
        },
        subheadingSlim: {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
        },
        body: {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
        },
        bodySlim: {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
        },
        caption: {
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: 500,
        },
        captionSlim: {
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: 400,
        },
        overline: {
            fontSize: "10px",
            lineHeight: "12px",
            fontWeight: 500,
        },
    },
    borderRadiuses: {
        base: "4px",
    },
};
