import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import cartsReducer from "./cartsReducer";

const rootReducer = combineReducers({
    global: globalReducer,
    carts: cartsReducer,
});

export default rootReducer;
