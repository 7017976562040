import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "../Typography";
import { StyledLink } from "./style";

const BreadCrumbs = ({
    searchValueTitle = "",
    itemId = "",
    isMobileScreen = false,
    detailsCodes = [],
    onDeleteChip,
}) => {
    const isIncludesOemPath = window.location.pathname.includes("/oem");
    const history = useHistory();
    const { t } = useTranslation();

    const handleClickBreadcrumbs = (path) => {
        history.push(path);
    };

    return (
        <Box style={{ display: "flex", alignItems: "center", height: "30px" }}>
            <Typography>{t("productsList.vehicle")}:&nbsp;</Typography>
            <Box>
                <Breadcrumbs
                    aria-label="breadcrumb"
                >
                    <Typography>
                        {searchValueTitle}
                    </Typography>
                    {Boolean(itemId) && (
                        <StyledLink
                            onClick={() =>
                                handleClickBreadcrumbs(
                                    isIncludesOemPath ?
                                        `/oem/${searchValueTitle}`
                                        : `/plate/${searchValueTitle}`
                                )
                            }
                        >
                            <KeyboardBackspaceIcon
                                style={{ height: "20px" }}
                            />
                        </StyledLink>
                    )}

                    {Boolean(!isMobileScreen && itemId && detailsCodes.length) && (
                        <>
                            {detailsCodes.map((code) => (
                                <Chip
                                    label={code}
                                    onDelete={() => onDeleteChip(code)}
                                    style={{
                                        height: "24px",
                                        margin: "8px",
                                        "& .MuiSvgIcon-root": {
                                            height: "16px",
                                        }
                                    }}
                                />
                            ))}
                        </>
                    )}
                </Breadcrumbs>
            </Box>
        </Box>
    );
};

export default memo(BreadCrumbs);
