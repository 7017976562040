import React from "react";
import { PasswordInput } from "../../../components/PasswordInput";
import Typography from "../../../components/Typography";
import Button from "../../../components/Button";
import { FitBox } from "../../../components/Box";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const ResetPassword = ({ onChangePassword }) => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
    const { t } = useTranslation();

    const handleNewPasswordChange = ({ target: { value } }) => {
        setErrorMessageVisible(false);
        setNewPassword(value);
    };
    const handleConfirmPasswordChange = ({ target: { value } }) => {
        setErrorMessageVisible(false);
        setConfirmPassword(value);
    };
    const handleChangePassword = () => {
        if (newPassword === confirmPassword) {
            onChangePassword(newPassword, confirmPassword);
        } else {
            setErrorMessageVisible(true);
        }
    };
    return (
        <>
            <Typography textAlign="left">
                {t("passwordReset.resetPasswordStep.text")}
            </Typography>
            <PasswordInput
                placeholder={t("passwordReset.resetPasswordStep.newPassword")}
                value={newPassword}
                onChange={handleNewPasswordChange}
                onEnter={handleChangePassword}
            ></PasswordInput>
            <PasswordInput
                placeholder={t(
                    "passwordReset.resetPasswordStep.confirmPassword"
                )}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onEnter={handleChangePassword}
            ></PasswordInput>
            <Button height="45px" onClick={handleChangePassword}>
                {t("passwordReset.resetPasswordStep.submit")}
            </Button>
            {errorMessageVisible && (
                <FitBox mt="-10px">
                    <Typography color="error" customVariant="caption">
                        {t("passwordReset.resetPasswordStep.errorMsg")}
                    </Typography>
                </FitBox>
            )}
        </>
    );
};
