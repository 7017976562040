import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
    ErrorMessage,
    FormGroup,
    Form,
    FormLabel,
    Input,
    SubmitButton,
    SubTitle,
    Title,
    Wrapper,
    FormLine,
    InputExplanation,
    RegisterSelectFormControl,
    FormGroupInner,
} from "../../../../components/Form";
import { CenteredErrorMessage } from "./style";
import { RegisterSchema } from "./validation";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useState } from "react";
import Select from "@material-ui/core/Select";
import { COUNTRIES } from "./constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { register as registerCall } from "../../../../core/redux/asyncActions/register";
import { REGISTER_SUCCESS } from "../../../../core/redux/actionTypes/register";
import { useHistory } from "react-router-dom";
import { REGISTER_SUCCESS_PATH } from "../../../../core/routes/index";

export default function RegisterForm() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(RegisterSchema),
    });

    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(COUNTRIES[0]);
    const onSubmit = async (data) => {
        const result = await dispatch(
            registerCall({
                name: data.name,
                registration_number: data.registrationNumber,
                vat_registration_number: data.vat,
                address: data.address,
                city: data.city,
                postal_code: data.postalCode,
                email: data.email,
                phone_number: data.phone,
                username: data.userName,
                new_password1: data.password,
                new_password2: data.confirmPassword,
                first_name: data.firstName,
                last_name: data.lastName,
                user_email: data.userEmail,
                country_code: selectedCountry.substr(0, 3).toUpperCase(),
            })
        );

        if (result.type === REGISTER_SUCCESS) {
            history.push(REGISTER_SUCCESS_PATH);
        } else {
            setErrorMessage(Object.values(result.error)[0]);
        }
    };

    return (
        <Wrapper>
            <Title>{t("register.title")}</Title>
            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="autokada">
                <FormGroup>
                    <SubTitle>{t("register.company.subtitle")}</SubTitle>
                    <FormGroupInner>
                        <FormLine>
                            <FormLabel htmlFor="name" required>
                                {t("register.company.name")}
                            </FormLabel>
                            <Input
                                type="text"
                                name="name"
                                placeholder="Enter your name"
                                id="name"
                                ref={register}
                            />
                            {errors.name && (
                                <ErrorMessage>
                                    {t(errors.name.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="registrationNumber" required>
                                {t("register.company.registrationNumber")}
                            </FormLabel>
                            <Input
                                id="registrationNumber"
                                name="registrationNumber"
                                ref={register}
                            />
                            {errors.registrationNumber && (
                                <ErrorMessage>
                                    {t(errors.registrationNumber.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="vat">
                                {t("register.company.vatRegistrationNr")}
                            </FormLabel>
                            <Input
                                type="text"
                                id="vat"
                                name="vat"
                                ref={register}
                            />
                            {errors.vat && (
                                <ErrorMessage>
                                    {t(errors.vat.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="address" required>
                                {t("register.company.address")}
                            </FormLabel>
                            <Input
                                type="text"
                                id="address"
                                name="address"
                                ref={register}
                            />
                            {errors.address && (
                                <ErrorMessage>
                                    {t(errors.address.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="city" required>
                                {t("register.company.city")}
                            </FormLabel>
                            <Input
                                type="text"
                                id="city"
                                name="city"
                                ref={register}
                            />
                            {errors.city && (
                                <ErrorMessage>
                                    {t(errors.city.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="postalCode" required>
                                {t("register.company.postalCode")}
                            </FormLabel>
                            <Input
                                type="text"
                                id="postalCode"
                                name="postalCode"
                                ref={register}
                            />
                            {errors.postalCode && (
                                <ErrorMessage>
                                    {t(errors.postalCode.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                    </FormGroupInner>
                    <FormGroupInner>
                        <FormLine>
                            <FormLabel htmlFor="country" required>
                                {t("register.company.country")}
                            </FormLabel>
                            <RegisterSelectFormControl
                                variant="outlined"
                                size="small"
                            >
                                <InputLabel id="country-label">
                                    {t("register.company.chooseCountry")}
                                </InputLabel>
                                <Select
                                    labelId="country-label"
                                    id="country"
                                    name="country"
                                    value={selectedCountry}
                                    onChange={(e) =>
                                        setSelectedCountry(e.target.value)
                                    }
                                    ref={register}
                                    label={t("register.company.chooseCountry")}
                                >
                                    {COUNTRIES.map((country) => (
                                        <MenuItem value={country} key={country}>
                                            {country}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </RegisterSelectFormControl>
                            {errors.country && (
                                <ErrorMessage>
                                    {t(errors.country.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>

                        <FormLine>
                            <FormLabel htmlFor="email" required>
                                {t("register.company.email")}
                            </FormLabel>
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                ref={register}
                            />
                            {errors.email && (
                                <ErrorMessage>
                                    {t(errors.email.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="phone" required>
                                {t("register.company.phoneNumber")}
                            </FormLabel>
                            <Input
                                type="tel"
                                id="phone"
                                name="phone"
                                ref={register}
                            />
                            {errors.phone && (
                                <ErrorMessage>
                                    {t(errors.phone.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                    </FormGroupInner>
                </FormGroup>
                <FormGroup>
                    <SubTitle>{t("register.user.subtitle")}</SubTitle>
                    <FormGroupInner>
                        <FormLine hasExplanation>
                            <FormLabel htmlFor="userName" required>
                                {t("login.clientId")}
                            </FormLabel>
                            <Input
                                type="text"
                                id="userName"
                                name="userName"
                                ref={register}
                            />
                            <InputExplanation>
                                {t("register.user.usernameInputExplanation")}
                            </InputExplanation>
                            {errors.userName && (
                                <ErrorMessage>
                                    {t(errors.userName.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="password" required>
                                {t("register.user.password")}
                            </FormLabel>
                            <Input
                                type="password"
                                id="password"
                                name="password"
                                ref={register}
                            />
                            {errors.password && (
                                <ErrorMessage>
                                    {t(errors.password.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="confirmPassword" required>
                                {t("register.user.passwordConfirmation")}
                            </FormLabel>
                            <Input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                ref={register}
                            />
                            {errors.confirmPassword && (
                                <ErrorMessage>
                                    {t(errors.confirmPassword.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="firstName" required>
                                {t("register.user.firstName")}
                            </FormLabel>
                            <Input
                                type="text"
                                id="firstName"
                                name="firstName"
                                ref={register}
                            />
                            {errors.firstName && (
                                <ErrorMessage>
                                    {t(errors.firstName.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="lastName" required>
                                {t("register.user.lastName")}
                            </FormLabel>
                            <Input
                                type="text"
                                id="lastName"
                                name="lastName"
                                ref={register}
                            />
                            {errors.lastName && (
                                <ErrorMessage>
                                    {t(errors.lastName.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                        <FormLine>
                            <FormLabel htmlFor="userEmail" required>
                                {t("register.user.email")}
                            </FormLabel>
                            <Input
                                type="email"
                                id="userEmail"
                                name="userEmail"
                                ref={register}
                            />
                            {errors.userEmail && (
                                <ErrorMessage>
                                    {t(errors.userEmail.message)}
                                </ErrorMessage>
                            )}
                        </FormLine>
                    </FormGroupInner>
                </FormGroup>
                {errorMessage && (
                    <CenteredErrorMessage>{errorMessage}</CenteredErrorMessage>
                )}

                <SubmitButton type="submit">
                    {t("register.register")}
                </SubmitButton>
            </Form>
        </Wrapper>
    );
}
