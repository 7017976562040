import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Table } from "../../../../../TableProducts";

import { DescriptionInfo } from "./DescriptionInfo";

const VehiclesList = ({ vehicles }) => {
    const { scope: currentCountry } = useSelector(
        (state) => state.global.userData
    );
    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            {
                id: "vehiclemodelseries_description",
                label: t(
                    "productsList.tableVehiclesHeader.description"
                ).toUpperCase(),
                valueGetter: "vehiclemodelseries_description",
                renderer: ({ value, rowData }) => {
                    const { engines, driver_cabs, wheelbases } = rowData;
                    return (
                        <DescriptionInfo
                            description={value}
                            {...{ engines, driver_cabs, wheelbases }}
                        />
                    );
                },
            },
            {
                id: "year_from",
                label: t(
                    "productsList.tableVehiclesHeader.model"
                ).toUpperCase(),
                valueGetter: "year_from",
            },
            {
                id: "engine_output_from_kw",
                label: "kV",
                valueGetter: "engine_output_from_kw",
            },
            {
                id: "engine_output_from_hp",
                label: "HP",
                valueGetter: "engine_output_from_hp",
            },
            {
                id: "engine_capacity_cc_technical",
                label: "cc",
                valueGetter: "engine_capacity_cc_technical",
            },
            {
                id: "body_type",
                label: t(
                    "productsList.tableVehiclesHeader.build"
                ).toUpperCase(),
                valueGetter: "body_type",
            },
            {
                id: "tonnage",
                label: t(
                    "productsList.tableVehiclesHeader.tonnage"
                ).toUpperCase(),
                valueGetter: "tonnage",
            },
            {
                id: "axle_configuration",
                label: t("productsList.tableVehiclesHeader.axle").toUpperCase(),
                valueGetter: "axle_configuration",
            },
        ],
        // eslint-disable-next-line
        [currentCountry, t] 
    );

    return <Table items={vehicles} columns={columns} />;
}; 

export default memo(VehiclesList);
