import React from "react";
import Typography from "../../../Typography";
import { PriceFlyoutWrapper, PriceLine } from "./style";

export const PriceFlyout = React.forwardRef(({ price }, ref) => {
    return (
        <PriceFlyoutWrapper ref={ref}>
            {
                typeof price === 'number' ?
                    <Typography>{price}</Typography>
                    : price.map((pricing, i) => (
                        <PriceLine key={i}>
                        <Typography>{`${pricing[0]}+ `}</Typography>
                        <Typography fontWeight={700}>{pricing[1]}</Typography>
                        </PriceLine>
                        )
                    )
            }
        </PriceFlyoutWrapper>
    );
});
