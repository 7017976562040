import styled from "styled-components";

export const HeaderWrapper = styled.div`
    padding: 15px 15px 0;
    display: flex;

    & > svg {
        margin-left: auto;
        cursor: pointer;
    }
`;
