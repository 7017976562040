import React, { useRef, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { StyledCircularProgress } from "../../CircularProgress";
import { CartWrapper, FavListWrapper, Arrow } from "../Carts/style";
import FavoriteItem from "./item";

export default function Favourites({ overlayClick, isMobileScreen, anchorEl }) {
    const {
        favoritesList,
    } = useSelector((state) => state?.global);
    const favRef = useRef(null);

    const handleClick = (e) => {
        if (
            favRef.current &&
            !favRef.current.contains(e.target) &&
            e.target !== document.body && !Boolean(document.getElementById("new-cart-name"))
        ) {
            overlayClick();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return (
        <CartWrapper style={{ height: "fit-content" }} ref={favRef}>
            <Arrow left={anchorEl?.current} />
            <Box
                style={{
                    minHeight: "100px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    maxHeight: isMobileScreen ? "100vh" : "500px",
                    justifyContent: "center",
                    flexDirection: "column"
                }}
            >
                {(favoritesList && favoritesList?.isLoading) ?
                    <StyledCircularProgress disableShrink/> :
                    <FavListWrapper>
                        {favoritesList?.results?.map((item) => (
                            <FavoriteItem
                                key={item.id}
                                data={item}
                                fav={true}
                            />
                        ))}
                    </FavListWrapper>}
            </Box>
        </CartWrapper>
    )
}
