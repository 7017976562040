// Box variations are divs with flex & spacing props which can be used to wrap components that don't have these props in order to layout them better.
// TODO ADI B. replace this component with equivalent Box from material-ui
import styled, { css } from "styled-components";

export const FitBox = styled.div(
    ({ theme, py, px, pt, pb, pl, pr, my, mx, mt, mb, ml, mr }) => css`
        width: max-content;
        height: max-content;
        padding-top: ${theme.spaces[py] || theme.spaces[pt] || pt};
        padding-bottom: ${theme.spaces[py] || theme.spaces[pb] || pb};
        padding-left: ${theme.spaces[px] || theme.spaces[pl] || pl};
        padding-right: ${theme.spaces[px] || theme.spaces[pr] || pr};
        margin-top: ${theme.spaces[my] || theme.spaces[mt] || mt};
        margin-bottom: ${theme.spaces[my] || theme.spaces[mb] || mb};
        margin-left: ${theme.spaces[mx] || theme.spaces[ml] || ml};
        margin-right: ${theme.spaces[mx] || theme.spaces[mr] || mr};
    `
);

export const Box = styled(FitBox)(
    ({
        flexDirection = "row",
        justifyContent,
        alignItems,
        width = "100%",
        height,
        flexWrap = "nowrap",
        flex,
    }) => css`
        display: flex;
        flex-direction: ${flexDirection};
        justify-content: ${justifyContent};
        align-items: ${alignItems};
        width: ${width};
        height: ${height};
        flex-wrap: ${flexWrap};
        flex: ${flex};
    `
);
