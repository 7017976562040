import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";

import { Link } from "react-router-dom";
import { getImageBaseUrl } from "../../core/utils";
import { ImageWithZoom } from "./components/ImageWithZoom";
import { ItemCard } from "./components/ItemCard";
import { Price } from "./components/Price";
import { Stock } from "./components/Stock";
import {
    ProductImageWrapper,
    ProductListItem,
    ProductSpec,
    Quantity,
} from "./style";
import QuantityButton from "./components/QuantityButton";

export default function Item({ data, order, orderItem, handleItemPopup,
    handleItemPopupOut, handleItemPopupOrderHistory, type, isAvailability, showQuantity = true, orderPage, itemNo }) {
    const {
        id,
        description: title,
        ak_code: akCode,
        oem_code: oem,
        vendor,
        stock = '',
        prices,
        image,
        unit_price = '',
        autokada_code = '',
        name = '',
        availability_data = [],
        vendor_item_code: order_oem,
        item_id = '',
        vendor_image
    } = { ...data };

    const {
        customer = '',
        date_created = '',
        number = '',
        source_location_label_short = '',
        state_label = '',
        total = '',
        user = '',
    } = { ...order };

    const {
        autokada_code: orderAKcode,
        count: orderItemCount,
        description = '',
        total: orderTotal,
        unit_price: orderUnitPrice,
        vendor_item_code = '',
        vendor_name = '',
    } = { ...orderItem };

    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [productUnits, setProductUnits] = useState(1);

    const handleItemPopupClick = (e, type) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (type === "desktop" && window.innerWidth < 960) {
            return false;
        }
        if (handleItemPopup) {
            handleItemPopup(id || item_id);
        }
        if (handleItemPopupOut) {
            handleItemPopupOut(data);
        }
        if (handleItemPopupOrderHistory) {
            handleItemPopupOrderHistory(orderItem.b2b_item);
        }
    };

    const productUnitsCount = (unitsCount) => {
        setProductUnits(unitsCount);
    }

    if (isPhoneScreen) {
        return (
            <Box marginBottom="8px">
                <ItemCard
                    data={data}
                    order={order}
                    orderItem={orderItem}
                    isAvailability={isAvailability}
                    showQuantity={showQuantity}
                    orderPage={orderPage}
                    handleItemPopupClick={handleItemPopupClick}
                    itemNo={itemNo}
                />
            </Box>
        );
    }

    return (
        <>
            {data ?
                <ProductListItem autoDelivery={data?.is_stockmin} type={type} onClick={(e) => handleItemPopupClick(e, "desktop")}>
                    <ProductImageWrapper>
                        <ImageWithZoom
                            popupSize={
                                type === "listing"
                                    ? isPhoneScreen
                                        ? { width: "100%", height: "100%" }
                                        : { width: "500px", height: "500px" }
                                    : { width: "380px", height: "380px" }
                            }
                            src={
                                image
                                    ? `${getImageBaseUrl()}${image}`
                                    : "/product-dot.svg"
                            }
                            imgSize={image && { imgWidth: "auto", imgHeight: "50px", width: "auto" }}
                        />
                    </ProductImageWrapper>
                    <ProductSpec>{autokada_code || akCode}</ProductSpec>
                    <ProductSpec>{order_oem || oem}</ProductSpec>
                    <ProductSpec>
                        {(vendor_image) ?
                            <img src={vendor_image} alt={name || vendor} style={{ maxWidth: "60px", maxHeight: "30px" }} /> :
                            name || vendor
                        }
                    </ProductSpec>
                    <ProductSpec>{title}</ProductSpec>
                    {stock && (
                        <ProductSpec>
                            <Stock stock={stock} id={data.id} />
                        </ProductSpec>
                    )}
                    {(isAvailability !== undefined) &&
                        <ProductSpec showQuantity={showQuantity}>
                            {(isAvailability) && (
                                (availability_data?.length === 0) ? "-" :
                                    availability_data.map((data, i) => (
                                        <div key={i} style={{ marginBottom: "4px", color: "#F28A22" }}>
                                            {data.row_value}
                                        </div>
                                    ))
                            )}
                        </ProductSpec>}
                    <ProductSpec showQuantity={showQuantity}>
                        <Price price={unit_price || prices} productUnits={productUnits} orderPage={orderPage} />
                    </ProductSpec>
                    {showQuantity &&
                        <ProductSpec onClick={(e) => e.stopPropagation()}>
                            <Quantity>
                                <QuantityButton
                                    data={data}
                                    productUnitsCount={productUnitsCount}
                                />
                            </Quantity>
                        </ProductSpec>
                    }
                </ProductListItem>
                : order ?
                    <Link to={`/orders/history/${number.slice(2)}`} style={{"text-decoration": "none"}}>
                        <ProductListItem type={type}>
                            <ProductSpec>{number}</ProductSpec>
                            <ProductSpec type="order">{date_created}</ProductSpec>
                            <ProductSpec>{state_label}</ProductSpec>
                            <ProductSpec>{user}</ProductSpec>
                            <ProductSpec type="order">{customer || '-'}</ProductSpec>
                            <ProductSpec>{total}</ProductSpec>
                            <ProductSpec type="order">{source_location_label_short}</ProductSpec>
                        </ProductListItem>
                    </Link>
                    :
                    <ProductListItem onClick={(e) => handleItemPopupClick(e)}>
                        <ProductSpec>{orderAKcode || '-'}</ProductSpec>
                        <ProductSpec>{vendor_item_code}</ProductSpec>
                        <ProductSpec> {(orderItem?.vendor_image) ?
                            <img src={orderItem?.vendor_image} alt={vendor_name} style={{ maxWidth: "60px", maxHeight: "30px" }} /> :
                            vendor_name}
                        </ProductSpec>
                        <ProductSpec type="orderItem">{description || '-'}</ProductSpec>
                        <ProductSpec type="orderItem">{orderUnitPrice}</ProductSpec>
                        <ProductSpec>{orderItemCount}</ProductSpec>
                        <ProductSpec>{orderTotal}</ProductSpec>
                    </ProductListItem>
            }
        </>
    );
}
