import * as cartsActionTypes from "./../actionTypes/carts";

export const defaultCartsState = {
    defaultBasket: null,
    orderInfo: {},
    isLoadingBasket: false,
    error: null,
    isLoadingOrderBasket: false,
    countItems: 1,
    cartsList: [
        {
            cartName: "Cart 1",
            itemsList: [],
        },
    ],
    isLoadingSubmitOrder: false,
    errorSubmitOrder: '',
    sourceLocation: null
};

const errorCarts = {
    getBasket: "Error getting baskets",
    getInfo: "Error getting info",
    setDefaultBasket: "Error setting default basket",
    deleteDefaultBasket: "Error deleting recycle bin by default",
    submit: 'Order error, please try again later'
};

const cartsReducer = (state = defaultCartsState, { type, payload }) => {
    switch (type) {
        case cartsActionTypes.ADD_BASKET_REQUEST: {
            return {
                ...state,
                error: null,
            };
        }

        case cartsActionTypes.ADD_BASKET_SUCCESS: {
            return {
                ...state,
                error: null,
                cartsList: [...state.cartsList, payload],
            };
        }

        case cartsActionTypes.ADD_BASKET_FAILURE: {
            return {
                ...state,
                error: errorCarts.getInfo,
            };
        }

        case cartsActionTypes.FETCH_INFO_BASKET_REQUEST: {
            return {
                ...state,
                isLoadingBasket: true,
                isLoadingOrderBasket: false,
                error: null,
            };
        }

        case cartsActionTypes.FETCH_INFO_BASKET_SUCCESS: {
            const { cartId, cartInfo } = payload;

            const updatedList = state.cartsList.map((item) => {
                if (item.id === cartId) return cartInfo;
                return item;
            });

            return {
                ...state,
                isLoadingBasket: false,
                error: null,
                isLoadingOrderBasket: false,
                cartsList: updatedList,
            };
        }

        case cartsActionTypes.FETCH_INFO_BASKET_FAILURE: {
            return {
                ...state,
                isLoadingBasket: false,
                isLoadingOrderBasket: false,
                error: errorCarts.getInfo,
            };
        }

        case cartsActionTypes.FETCH_BASKET_REQUEST: {
            return {
                ...state,
                isLoadingBasket: true,
                error: null,
            };
        }

        case cartsActionTypes.FETCH_BASKET_SUCCESS: {
            return {
                ...state,
                isLoadingBasket: false,
                error: null,
                cartsList: payload,
            };
        }

        case cartsActionTypes.FETCH_BASKET_FAILURE: {
            return {
                ...state,
                isLoadingBasket: false,
                error: errorCarts.getBasket,
            };
        }

        case cartsActionTypes.ADD_CART: {
            return {
                ...state,
                cartsList: [
                    ...state.cartsList,
                    {
                        cartName: payload,
                        itemsList: [],
                    },
                ],
            };
        }

        case cartsActionTypes.ADD_DEFAULT_BASKET_SUCCESS:
            return {
                ...state,
                defaultBasket: payload,
                error: null,
            };

        case cartsActionTypes.ADD_DEFAULT_BASKET_REQUEST:
            return {
                ...state,
                defaultBasket: null,
                error: null,
            };
        case cartsActionTypes.ADD_DEFAULT_BASKET_FAILURE:
            return {
                ...state,
                error: errorCarts.setDefaultBasket,
            };

        case cartsActionTypes.DELETE_DEFAULT_BASKET_FAILURE:
            return {
                ...state,
                error: errorCarts.deleteDefaultBasket,
            };

        case cartsActionTypes.ORDER_INFO_REQUEST: {
            return {
                ...state,
                isLoadingOrderBasket: true,
                errorLoadingOrder: null,
            };
        }
        case cartsActionTypes.ORDER_INFO_SUCCESS: {
            return {
                ...state,
                orderInfo: payload,
                isLoadingOrderBasket: false,
                errorLoadingOrder: null,
                isLoadingSubmitOrder: false,
            };
        }
        case cartsActionTypes.ORDER_INFO_FAILURE: {
            return {
                ...state,
                isLoadingOrderBasket: false,
                errorLoadingOrder: payload,
            };
        }
        case cartsActionTypes.CHANGE_COUNT_ITEMS: {
            return {
                ...state,
                countItems: Number(payload)
            };
        }

        case cartsActionTypes.ORDER_SUBMIT_REQUEST: {
            return {
                ...state,
                isLoadingSubmitOrder: true,
                errorSubmitOrder: ''
            };
        }

        case cartsActionTypes.ORDER_SUBMIT_SUCCESS: {
            return {
                ...state,
                isLoadingSubmitOrder: false,
                errorSubmitOrder: ''
            };
        }

        case cartsActionTypes.ORDER_SUBMIT_FAILURE: {
            return {
                ...state,
                isLoadingSubmitOrder: false,
                errorSubmitOrder: errorCarts.submit
            };
        }
        case cartsActionTypes.SET_SOURCE_LOCATION: {
            return {
                ...state,
                sourceLocation: payload,
            };
        }
        default:
            return state;
    }
};

export default cartsReducer;
