import Box from "@material-ui/core/Box";
import React from "react";

export const TabPanel = ({ children, selected, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={!selected}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {selected && <Box>{children}</Box>}
    </div>
);
