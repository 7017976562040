import settings from "../../settings.json";
import { COUNTRIES, countryToScope } from "../translation/languages";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem("autokada_state");

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (error) {
        console.error(error);
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("autokada_state", serializedState);
    } catch (error) {
        console.error(error);
    }
};

export const throttle = (func, wait, options = {}) => {
    let context;
    let args;
    let result;
    let timeout = null;
    let previous = 0;
    const later = () => {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function () {
        const now = Date.now();
        if (!previous && options.leading === false) previous = now;
        const remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
};

export const getImageBaseUrl = () => {
    const currentEnv = process.env.REACT_APP_ENV
        ? process.env.REACT_APP_ENV.trim()
        : "dev";
    return "";
    return settings[currentEnv].baseUrl.replace("/api", "");
};

export const COUNTRY_CURRENCY = {
    [countryToScope(COUNTRIES[0])]: "€", // Latvia
    [countryToScope(COUNTRIES[1])]: "€", // Estonia
    [countryToScope(COUNTRIES[2])]: "€", // Lithuania
    [countryToScope(COUNTRIES[3])]: "₽", // Russia
    [countryToScope(COUNTRIES[4])]: "kr", // Sweden
};
