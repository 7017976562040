import styled from "styled-components";
import { breakpoints } from "../../../core/utils/style";
import Box from "@material-ui/core/Box";

export const SearchContainer = styled(Box)`
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 4px 16px rgba(0, 0, 0, 0.25);
    width: 47%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;

    @media (max-width: ${breakpoints.md}) {
        width: 90%;
        min-height: 250px;
        margin: 20px 0;
    }
`;
