import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTheme as useThemeMaterial } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import { Skeleton } from "@material-ui/lab";
import { fetchOemVehicles, fetchYqOemVehicle, fetchPlateVehicles } from "../../core/redux/asyncActions/global";

import { ImageWithZoom } from "../Listing/components/ImageWithZoom";
import { Separator, SeparatorDirection } from "../Separator";
import Typography from "../Typography";

import {
    Wrapper,
    ImagePreview,
    Title,
    ProductListItem,
    BreadCrumbsWrapper,
    ProductsWrapper,
} from "./style";

import { ItemDetailsModal } from "../Listing/components/ItemDetailsModal";
import BreadCrumbs from "../BreadCrumbs";

const PHOTO_STYLE_LARGE = {
    width: "500px",
    height: "500px",
};

const PHOTO_STYLE_MIDDLE = {
    width: "250px",
    height: "250px",
};

export default function SideProdLis() {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { vin = "", plate = "" } = useParams();
    const themeMaterial = useThemeMaterial();
    const isMdScreen = useMediaQuery(themeMaterial.breakpoints.down("md"));
    const isMobileScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));

    const { units: oemVehicles = [], error, vehicle = null } = useSelector(
        (state) => state.global?.oemVehicles || {}
    );
    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const [listVehicles, setListVehicles] = useState(oemVehicles.slice(0, 50))

    const { lang, searchQuery, selectedItemCategory, selectedItemGroup, selectedItemModel } = useSelector(
        (state) => state.global
    );

    const currentProduct = useSelector(
        (state) => state.global?.currentProduct || null
    );

    const selectProductItem = (id) => () => {
        if (vehicle?.vehicleid?.value && vehicle?.catalog?.value && vehicle?.ssd?.value) {
            history.push(`/oem/${vin}/${id}/${vehicle?.catalog?.value}`);
        }
        else {
            if (vin) {
                history.push(`/oem/${vin}/${id}`);
            } else {
                history.push(`/plate/${plate}/${id}`);
            }
        }

    };

    useEffect(() => {
        if ((Boolean(!vehicle) || searchQuery) && vin) {
            dispatch(fetchOemVehicles(vin));
        } else {
            if (Boolean(vehicle)) {
                dispatch(fetchYqOemVehicle(vehicle?.catalog?.value, vin, vehicle?.ssd?.value));
            }
            else {
                dispatch(fetchPlateVehicles(plate.substring(0, 2), plate.substring(2)));
            }
        }
    }, // eslint-disable-next-line
        [searchQuery,
            lang,
            dispatch
        ]);

    useEffect(() => {
        if (selectedItemCategory?.units?.length) {
            if (selectedItemGroup?.units?.length) {
                if (selectedItemModel?.units?.length) {
                    setFilteredVehicles(
                        (oemVehicles.filter(({ unitid }) =>
                            selectedItemModel.units.includes(unitid)
                        )))
                    return;
                }
                setFilteredVehicles(
                    (oemVehicles.filter(({ unitid }) =>
                        selectedItemGroup.units.includes(unitid)
                    )))
                return;
            }
            setFilteredVehicles(
                (oemVehicles.filter(({ unitid }) =>
                    selectedItemCategory.units.includes(unitid)
                )))
            return;
        } else {
            setFilteredVehicles([])
        }
    }, [selectedItemCategory,
        selectedItemGroup,
        selectedItemModel,
        oemVehicles])

    useEffect(() => {
        if (filteredVehicles && selectedItemCategory?.units?.length) { setListVehicles(filteredVehicles.slice(0, 50)) } else {
            setListVehicles(oemVehicles.slice(0, 50))
        }
    }, [filteredVehicles])

    const Loop = () => {
        var skelet = [];
        for (var i = 0; i < 21; i++) {
            skelet.push(
                <ProductListItem key={i} style={{ height: "290px" }}>
                    <Skeleton style={{ width: "184px", height: "500px" }} />
                </ProductListItem>)
        }
        return (
            <>
                {skelet}
            </>
        )
    }

    function handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.scrollHeight || isFetching) return;
        setIsFetching(true);
    }

    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    function fetchMoreListItems() {
        setTimeout(() => {
            if (Boolean(selectedItemCategory)) {
                setListVehicles(prevState => ([...prevState, ...filteredVehicles.slice(listVehicles.length, listVehicles.length + 50)]));
            } else {
                setListVehicles(prevState => ([...prevState, ...oemVehicles.slice(listVehicles.length, listVehicles.length + 50)]));
            }
            setIsFetching(false);
        }, 500);
    }

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    return (
        <>
            {currentProduct && (
                <ItemDetailsModal />
            )}
            <Wrapper>
                <Title>{t("productsList.productCatalog")}</Title>
                {error?.response?.status === 404 ?
                    <Typography>
                        {t("productsList.noInfoError")}
                    </Typography>
                    :
                    <>
                        <BreadCrumbsWrapper>
                            <BreadCrumbs
                                searchValueTitle={vin || plate}
                                isMobileScreen={isMobileScreen}
                            />
                        </BreadCrumbsWrapper>
                        <Box display="flex" justifyContent="center" width="100%">
                            <ProductsWrapper width="100%" display="flex" flexWrap="wrap">
                                {!Boolean(oemVehicles?.length) ? (
                                    <Loop />
                                ) : (
                                    listVehicles.map(
                                        ({ unitid, largeimageurl = "", name }) => (
                                            <ProductListItem
                                                key={unitid}
                                                onClick={selectProductItem(unitid)}
                                            >
                                                <ImagePreview>
                                                    <ImageWithZoom
                                                        popupSize={
                                                            isMdScreen
                                                                ? PHOTO_STYLE_MIDDLE
                                                                : PHOTO_STYLE_LARGE
                                                        }
                                                        placement={
                                                            isMdScreen ? "top" : "right"
                                                        }
                                                        src={(!largeimageurl) ?
                                                            process.env.PUBLIC_URL +
                                                            "/product-dot.svg" :
                                                            largeimageurl
                                                        }
                                                        imgSize={{ oemItem: true }}
                                                    />
                                                </ImagePreview>
                                                <Separator
                                                    colorBorder={"#E5E5E5"}
                                                    direction={
                                                        SeparatorDirection.Horizontal
                                                    }
                                                />
                                                <Box
                                                    height="fit-content"
                                                    minHeight="106px"
                                                    padding="12px"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    key={unitid}
                                                >
                                                    <Typography customVariant="body" marginTop="10px" marginBottom="10px">
                                                        {name}
                                                    </Typography>
                                                </Box>
                                            </ProductListItem>
                                        )
                                    )
                                )}

                            </ProductsWrapper>
                        </Box>
                    </>
                }
                {isFetching &&
                    <div>
                        <Typography>
                            {t("productsList.fetching")}
                        </Typography>
                    </div>
                }
            </Wrapper>
        </>
    );
}
