import React, { useCallback, useRef, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { Popper } from "../../../../../../../Popper";
import Typography from "../../../../../../../Typography";
import { Separator, SeparatorDirection } from "../../../../../../../Separator";

import { InfoWrapper, TitleBox } from "./style";

export const DescriptionInfo = ({
    description,
    driver_cabs = [],
    engines = [],
    wheelbases = [],
}) => {
    const [popperVisibility, setPopperVisibility] = useState(false);
    const textRef = useRef();
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const togglePopper = useCallback(() => {
        setPopperVisibility((curValue) => !curValue);
    }, []);

    const closePopper = useCallback(() => {
        setPopperVisibility(false);
    }, []);

    const openPopper = useCallback(() => {
        setPopperVisibility(true);
    }, []);

    const textProps = isPhoneScreen
        ? {
              onClick: togglePopper,
          }
        : {
              onMouseEnter: openPopper,
              onMouseLeave: closePopper,
          };

    return (
        <div style={{ position: "relative" }}>
            <Typography
                ref={textRef}
                {...textProps}
                color="#37474f"
                cursor="pointer"
                customVariant={isPhoneScreen ? "captionSlim" : "subheadingSlim"}
            >
                {description}
            </Typography>
            <Popper
                visibility={popperVisibility}
                anchorEl={textRef?.current}
                onClickAway={closePopper}
                placement="right"
                ignoreBackground
                disablePortal={false}
                style={{
                    left: 0,
                    top: "30%",
                    zIndex: 9999,
                }}
            >
                <InfoWrapper>
                    <Box display="flex" padding="24px" boxSizing="border-box">
                        <TitleBox>
                            <Typography
                                textAlign="left"
                                color="#37474F"
                                customVariant="body"
                                fontWeight={700}
                            >
                                Engines Code
                            </Typography>
                        </TitleBox>

                        <Box
                            display="flex"
                            marginLeft={3}
                            flexWrap="wrap"
                            boxSizing="border-box"
                        >
                            {Boolean(engines?.length) &&
                                engines.map((engineCode, index) => (
                                    <Typography
                                        key={engineCode}
                                        color="#37474F"
                                        customVariant="body"
                                        fontWeight={400}
                                    >
                                        {engineCode}
                                        {engines.length - 1 !== index && ","}
                                        &nbsp;
                                    </Typography>
                                ))}
                        </Box>
                    </Box>
                    <Separator direction={SeparatorDirection.Horizontal} />
                    <Box display="flex" padding="24px" boxSizing="border-box">
                        <TitleBox>
                            <Typography
                                color="#37474F"
                                customVariant="body"
                                fontWeight={700}
                                textAlign="left"
                            >
                                Wheel Bases
                            </Typography>
                        </TitleBox>

                        <Box
                            display="flex"
                            marginLeft={3}
                            flexWrap="wrap"
                            style={{
                                width: "350px",
                            }}
                        >
                            {Boolean(wheelbases?.length) &&
                                wheelbases.map(([label, value], index) => (
                                    <Box
                                        boxSizing="border-box"
                                        paddingBottom="10px"
                                        key={label + value}
                                    >
                                        <Typography
                                            color="#37474F"
                                            customVariant="body"
                                            fontWeight={400}
                                        >
                                            {`${label}/${value}`}
                                            {wheelbases.length - 1 !== index &&
                                                ","}
                                            &nbsp;
                                        </Typography>
                                    </Box>
                                ))}
                        </Box>
                    </Box>
                    <Separator direction={SeparatorDirection.Horizontal} />
                    <Box display="flex" padding="24px" boxSizing="border-box">
                        <TitleBox>
                            <Typography
                                textAlign="left"
                                color="#37474F"
                                customVariant="body"
                                fontWeight={700}
                            >
                                Engines Code
                            </Typography>
                        </TitleBox>

                        <Box
                            display="flex"
                            marginLeft={3}
                            style={{
                                flexWrap: "wrap",
                                wordBreak: "break-word",
                            }}
                        >
                            {Boolean(driver_cabs?.length) &&
                                driver_cabs.map((cab, index) => (
                                    <Typography
                                        key={cab}
                                        color="#37474F"
                                        customVariant="body"
                                        fontWeight={400}
                                    >
                                        {cab}
                                        {driver_cabs.length - 1 !== index &&
                                            ","}
                                        &nbsp;
                                    </Typography>
                                ))}
                        </Box>
                    </Box>
                </InfoWrapper>
            </Popper>
        </div>
    );
};
