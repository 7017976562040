import styled, { css } from "styled-components";
import Popper from "@material-ui/core/Popper";

export const TooltipBackground = styled.div`
        display: ${({ visibility }) => (visibility ? "block" : "none")};
        background: rgba(0, 0, 0, 0.4);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        z-index: 2000;
    `
    ;

export const StyledMUIPopper = styled(Popper)(
    css`
        z-index: 2000;
    `,
    ({ isphone }) =>
        isphone &&
        css`
            position: fixed !important;
            top: 50% !important;
            left: 5% !important;
            right: 5% !important;
            width: 90%;
            transform: translateY(-50%) !important;
        `
);
