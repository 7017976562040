import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "../../../components/Typography";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { FitBox } from "../../../components/Box";
import { useState } from "react";

export const SendResetLink = ({ onSubmitEmail }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
    const handleEmailChange = ({ target: { value } }) => {
        setErrorMessageVisible(false);
        setEmail(value);
    };
    const handleSubmitEmail = () => {
        const emailValidationRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (emailValidationRegex.test(email)) {
            onSubmitEmail(email);
        } else {
            setErrorMessageVisible(true);
        }
    };
    return (
        <>
            <Typography textAlign="left" customVariant="subheadingSlim">
                {t("passwordReset.sendResetLinkStep.text")}
            </Typography>
            <Input
                placeholder={t("email")}
                value={email}
                onChange={handleEmailChange}
                onEnter={handleSubmitEmail}
            ></Input>
            {errorMessageVisible && (
                <FitBox mt="-10px">
                    <Typography color="error" customVariant="caption">
                        {t("passwordReset.sendResetLinkStep.errorMsg")}
                    </Typography>
                </FitBox>
            )}
            <Button height="45px" onClick={handleSubmitEmail}>
                {t("submit")}
            </Button>
        </>
    );
};
