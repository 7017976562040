import React from "react";

import Header from "../../components/Header";
import Wrapper from "../../components/Wrapper";
import Drawer from "../../components/Drawer";
import SideProdLis from "../../components/SideListening/index";

export default function OemPage() {
    return (
        <div>
            <Header />
            <Wrapper>
                <Drawer />
                <SideProdLis />
            </Wrapper>
        </div>
    );
}
