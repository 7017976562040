import React, { useEffect, useState, useRef } from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CartsList from "./list";
import { BasketMenuArrow, CartWrapper, Root } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfoBasketById } from "../../../core/redux/asyncActions/carts";
import { StyledCircularProgress } from "../../CircularProgress";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function Menu({ overlayClick, anchorEl, closePopper }) {
    const countItems = useSelector((state) => state?.carts?.countItems || 0);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.global.lang);
    const {
        defaultBasket,
        cartsList = [],
        isLoadingBasket = false,
        sourceLocation
    } = useSelector((state) => state?.carts || {});

    const indexBasket = cartsList.findIndex((item) => item.id === defaultBasket);

    const [value, setValue] = useState(
        indexBasket && indexBasket >= 0 ? (indexBasket) : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (cartsList && cartsList.length) {
            const idCard = cartsList[value]?.id;
            if (idCard === undefined) {
                setValue(value - 1);
            } else {
                dispatch(fetchInfoBasketById(idCard, sourceLocation))
            }
        }
    }, [value, lang, dispatch, countItems, cartsList?.length])

    const menuRef = useRef(null);

    const handleClick = (e) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(e.target) &&
            e.target !== document.body
        ) {
            overlayClick();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return (
        <>
            <BasketMenuArrow left={anchorEl?.current} />
            <CartWrapper ref={menuRef}>
                <Root>
                    <AppBar position="static">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            onClick={(e) => e.stopPropagation()}
                            aria-label="scrollable auto tabs example"
                        >
                            {Boolean(cartsList.length) && (
                                cartsList.map(({ title, id }) => (
                                    <Tab key={id + title} label={title} {...a11yProps(0)} />
                                ))
                            )}
                        </Tabs>
                    </AppBar>
                    {isLoadingBasket && (
                        <Box
                            display="flex"
                            alignItems="center"
                            width="100%"
                            height="300px"
                            justifyContent="center"
                        >
                            <StyledCircularProgress disableShrink/>
                        </Box>
                    )}
                    {!isLoadingBasket && (
                        <TabPanel as="div" value={value} index={value}>
                            <CartsList data={cartsList[value]} closePopper={closePopper} />
                        </TabPanel>
                    )}
                </Root>
            </CartWrapper>
        </>
    );
}
