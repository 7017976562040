import React from "react";
import Header from "../../components/Header";
import Wrapper from "../../components/Wrapper";
import { OrderSide } from "./components/OrderHistoryItem";

export const OrderHistoryItem = () => {
    return (
        <div>
            <Header />
            <Wrapper>
                <OrderSide />
            </Wrapper>
        </div>
    );
};
