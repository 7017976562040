import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

import { ItemVehicleWrapper, TitleTypography, TxtTypography } from "./style";

const MobileItemVehicle = ({ data }) => {
    const {
        manufacturer,
        vehiclemodelseries_description: description,
        year_from: year,
        engine_output_from_kw: kvFrom,
        engine_output_to_kw: kvTo,
        engine_output_from_hp: hpFrom,
        engine_output_to_hp: hpTo,
        tonnage,
        axle_configuration: axle,
        body_type: build,
        engine_capacity_cc_technical: cc,
    } = { ...data };
    const { t } = useTranslation();

    return (
        <ItemVehicleWrapper
            item
            xs={12}
            container
            direction="row"
            justify="flex-start"
            alignItem="flex-start"
        >
            <Grid item xs={12}>
                <TitleTypography>
                    {manufacturer + " " + description}
                </TitleTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`${t("productsList.tableVehiclesHeader.model")}: ${
                        year.substr(4, 2) + "." + year.substr(2, 2)
                    }`}
                </TxtTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`${t("productsList.tableVehiclesHeader.build")}: ${build}`}
                </TxtTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`kV: ${kvTo ? kvFrom + "-" + kvTo : kvFrom}`}
                </TxtTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`${t(
                        "productsList.tableVehiclesHeader.tonnage"
                    )}: ${tonnage}`}
                </TxtTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`HP: ${hpTo ? hpFrom + "-" + hpTo : hpFrom}`}
                </TxtTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>
                    {`${t("productsList.tableVehiclesHeader.axle")}: ${axle}`}
                </TxtTypography>
            </Grid>
            <Grid item xs={6}>
                <TxtTypography>{`cc: ${cc}`}</TxtTypography>
            </Grid>
        </ItemVehicleWrapper>
    );
};

export default memo(MobileItemVehicle);
