import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useDispatch } from "react-redux";
import { StyledTable } from "../../Listing/components/TableProducts/style";
import Typography from "../../Typography";
import { fetchYqOemVehicle } from "../../../core/redux/asyncActions/global";
import { useHistory } from "react-router";
import { setSearchQuery, setSearchType, setSearchVisible } from "../../../core/redux/actions/global";

export const Table = ({ items, attributes, catalog, ssd, vehicleid, isMobileScreen }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleVehicleInfo = async (e, ssd, id) => {
        await dispatch(fetchYqOemVehicle(catalog, id, ssd));
        await dispatch(setSearchQuery(""));
        await dispatch(setSearchType("by_vehicle"));
        if (isMobileScreen) {
            await dispatch(setSearchVisible(false));
        }
        history.push(`/oem/${id}`);
    };

    const list = open ? items : items?.slice(0, 1);

    const tableHead = (attribute, i) => (
        <TableCell key={i + "h"}>
            <Typography
                textAlign="left"
                customVariant="caption"
                color="rgba(0, 0, 0, 0.87)"
            >
                {attribute?.name.toUpperCase()}
            </Typography>
        </TableCell>
    )

    const tableRow = (attribute, i) => (
        <TableCell key={i + "r"}>
            <Typography
                cursor="pointer"
                textAlign="left"
                customVariant="captionSlim"
                color="rgba(0, 0, 0, 0.87)"
            >
                {attribute?.value}
            </Typography>
        </TableCell>
    )

    return (
        <Box padding="0 5px" width="100%">
            <StyledTable style={{
                borderCollapse: "separate",
                borderSpacing: "0 10px"
            }}>
                <TableHead>
                    <tr>
                        {(items !== undefined) &&
                            items[0]?.attributes.map((attribute, i) => (
                                tableHead(attribute, i)
                            ))
                        }
                    </tr>
                </TableHead>
                <TableBody>
                    {(items !== undefined) ?
                        list.map(({ attributes, vehicleid, ssd }) => (
                            <TableRow key={vehicleid} ssd={ssd} onClick={(e) => handleVehicleInfo(e, ssd, vehicleid)}>
                                {attributes.map((attribute, i) => (
                                    tableRow(attribute, i)
                                ))}
                            </TableRow>
                        ))
                        : attributes.map((attribute, i) => (
                            <TableRow key={i} onClick={(e) => handleVehicleInfo(e, ssd, vehicleid)}>
                                {tableHead(attribute, i)}
                                {tableRow(attribute, i)}
                            </TableRow>))
                    }

                </TableBody>
            </StyledTable>
            {(items !== undefined && items.length !== 1) &&
                <Box
                    onClick={(e) => setOpen(!open)}
                    style={{
                        display: "flex", alignItems: "center",
                        justifyContent: "center", width: "fit-content",
                        cursor: "pointer"
                    }}>
                    <Typography
                        textAlign="left"
                        customVariant="body"
                        color="black"
                        cursor="pointer"
                    >
                        {t('header.carByParameters.modifications')}
                    </Typography>
                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Box>}
        </Box >
    );
};
