import styled from "styled-components";

export const SeparatorDirection = {
    Horizontal: "horizontal",
    Vertical: "vertical",
};

const Separator = styled.div`
    ${({ direction, my, mx, colorBorder = "#DEDEDE" }) =>
        direction === SeparatorDirection.Horizontal
            ? `
    border-bottom: 1px solid ${colorBorder};
    transform: scaleY(0.5);
    width: 100%;
    margin-top: ${my};
    margin-bottom: ${my};
  `
            : `
    border-left: 1px solid ${colorBorder};
    height: 100%;
    margin-left: ${mx};
    margin-right: ${mx};
  `}
`;

export { Separator };
