import styled from "styled-components";
import Box from "@material-ui/core/Box";

export const InfoWrapper = styled(Box)`
    background: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    max-width: 450px;
    width: 100%;
    flex-wrap: wrap;
    word-wrap: break-word;
`;

export const TitleBox = styled(Box)`
    width: 80px;
    white-space: nowrap;
`;
