import React from "react";
import Button from "@material-ui/core/Button";
import styled, { css } from "styled-components";

export const BUTTON_VARIANTS = {
    Filled: "Filled",
    Bordered: "Bordered",
    Light: "Light",
};

const getButtonVariantStyles = (buttonVariant, theme) => {
    switch (buttonVariant) {
        case BUTTON_VARIANTS.Bordered:
            return css`
                background: transparent;
                border: 1px solid ${theme.colors.orange};
                color: ${theme.colors.orange};
                font-weight: 700;
                font-size: 16px;

                &:hover {
                    background: transparent;
                    color: ${theme.colors.otherOrange};
                    border: ${theme.colors.otherOrange};
                }
            `;
        
        case BUTTON_VARIANTS.Light:
            return css`
                background: #ffb74d;
                color: white;

                &:hover {
                    background: ${theme.colors.orange};
                }
                
                &:disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
            `;
        case BUTTON_VARIANTS.Filled:
        default:
            return css`
                background: ${theme.colors.orange};
                color: white;

                &:hover {
                    background: ${theme.colors.orangeDark};
                }

                &:disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
            `;
    }
};

// Props that should not be forwarded to the HTML DOM elements
export const shouldForwardProp = (prop) => !["buttonVariant"].includes(prop);

const StyledButton = styled(Button).withConfig({ shouldForwardProp }).attrs({
    variant: "contained",
})`
    && {
        text-transform: none;
        width: ${({ width = "100%" }) => width};
        height: ${({ height = "100%" }) => height};
        ${({ buttonVariant, theme }) =>
            getButtonVariantStyles(buttonVariant, theme)}
    }    
`;

const CustomButton = ({
    children,
    buttonVariant = BUTTON_VARIANTS.Filled,
    ...restProps
}) => {
    return (
        <StyledButton
            variant="contained"
            buttonVariant={buttonVariant}
            disableElevation
            {...restProps}
        >
            {children}
        </StyledButton>
    );
};

export default CustomButton;
