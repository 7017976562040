import React, { useState } from "react";
import Input from "./Input";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const PasswordInput = ({ value, onChange, ...restProps }) => {
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassoword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Input
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={value}
            onChange={onChange}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPassoword}
                        onMouseDown={handleMouseDownPassword}
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            }
            {...restProps}
        />
    );
};
