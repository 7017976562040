import styled from "styled-components";
import { fullSize, flex } from "../../core/styles/common";

export const PageContainer = styled.div`
    background: ${({ theme }) => theme.colors.background};
    ${fullSize}
    ${flex("column")}
`;

export const DataContainer = styled.div`
    background: white;
    max-width: 800px;
    padding: ${({ theme }) => theme.spaces.l};
    color: ${({ theme }) => theme.colors.font.base};
    overflow: auto;

    && {
        ol {
            padding: 1rem;
            padding-left: calc(40px + 1rem);
        }

        p {
            font-weight: 400;
            text-align: left;
        }
    }
`;
