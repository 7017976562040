import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StyledDatePicker } from "../../../components/DatePicker";
import { Title, Wrapper } from "../../../components/SideListening/style";
import { COUNTRY_CURRENCY } from "../../../core/utils";
import {
    UserMenuSelectLabel,
} from "../../../components/Header/style";
import Button from "../../../components/Button";
import { SelectFormControl } from "../../../components/Drawer/style";
import { MultipleAutocomplete } from "../../../components/MultipleAutocomplete";
import { Table } from "./Table";
import { fetchInvoices } from "../../../core/redux/asyncActions/global";
import { useMediaQuery, useTheme as useThemeMaterial } from "@material-ui/core";
import { enGB, et, fi, lt, lv, nb, ru, sv } from 'date-fns/locale';
import { parse } from "date-fns";
import DateFnsUtils from '@date-io/date-fns';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { theme } from "../../../core/styles/theme";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const InvoicesSide = memo(() => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const themeMaterial = useThemeMaterial();
    const isMobileScreen = useMediaQuery(themeMaterial.breakpoints.down("sm"));
    const creditLimit = useSelector((state) => state.global?.invoices?.creditLimit || 0);
    const totalBalance = useSelector((state) => state.global?.invoices?.totalBalance || 0);
    const unusedCreditLimit = useSelector((state) => state.global?.invoices?.unusedCreditLimit || 0);
    const latePayments = useSelector((state) => state.global?.invoices?.latePayments || "-");
    const invoices = useSelector((state) => state.global?.invoices?.invoices || []);
    const isLoading = useSelector((state) => state.global.invoices?.isLoading || false);
    const { scope: currentCountry } = useSelector((state) => state.global.userData);
    const lang = useSelector((state) => state.global?.lang);
    const fullCredit = unusedCreditLimit + creditLimit;
    const [docNos, setDocNos] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [status, setStatus] = useState(null);
    const [docNo, setDocNo] = useState(null);
    const [locale, setLocale] = useState(enGB);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [openDoc, setOpenDoc] = useState(false);
    const [inputValueDoc, setInputValueDoc] = useState("");
    const statuses = [
        { value: 0, text: t("invoices.paid") },
        { value: 1, text: t("invoices.unpaid") },
        { value: 2, text: t("invoices.partiallyPaid") },
        { value: 3, text: t("invoices.unpaidLate") },
        { value: 4, text: t("invoices.partiallyPaidLate") }
    ];

    const getStatusText = (statusCode) => {
        switch (statusCode) {
          case 0:
            return t("invoices.paid");
          case 1:
            return t("invoices.unpaid");
          case 2:
            return t("invoices.partiallyPaid");
          case 3:
            return t("invoices.unpaidLate");
          case 4:
            return t("invoices.partiallyPaidLate");
        }
    };

    const filter = () => {
        let filtered = [];
        for (let i = 0; i < invoices.length; i++) {
            let st = true;
            let fd = true;
            let td = true;
            let dn;
            let total = invoices[i].amount_w_vat;
            let remaining = invoices[i].remaining_w_vat;
            let object = {
                id: i,
                date: parse(invoices[i].posting_date, "dd.MM.yyyy", new Date()),
                number: invoices[i].number,
                total: total,
                remaining: remaining,
                due: parse(invoices[i].due_date, "dd.MM.yyyy", new Date()),
                status: getStatusText(invoices[i].status)
            };
            if (!status || status.value === invoices[i].status) {
                st = true
            } else {
                st = false
            }
            if (!docNo || docNo.value === invoices[i].number) {
                dn = true
            } else {
                dn = false
            }
            if (!fromDate || object.date >= fromDate) {
                fd = true
            } else {
                fd = false
            }
            if (!toDate || object.date <= toDate) {
                td = true
            } else {
                td = false
            }
            if (st && fd && td && dn)
                filtered.push(object);
        }
        setFilteredInvoices(filtered);
    };

    const handleStatusChange = (e, selected) => {
        setStatus(selected);
    };

    const handleDocNoChange = (e, selected) => {
        setDocNo(selected);
    };

    const handleClearFilters = () => {
        setStatus(null);
        setDocNo(null);
        setFromDate(null);
        setToDate(null);
        filter();
    };

    const handleSearchInputKeyPress = (event) => {
        if (event.key === "Enter") {
            const searchPhrase = event.target.value;
            const filtered = invoices.filter((invoice) =>
                invoice.number.toLowerCase().includes(inputValueDoc.toLowerCase())
            );
            const formattedFilteredInvoices = filtered.map((invoice, index) => ({
                id: index,
                date: parse(invoice.posting_date, "dd.MM.yyyy", new Date()),
                number: invoice.number,
                total: parseFloat(invoice.amount_w_vat.toFixed(2)) === 0 ? "0.00" : parseFloat(invoice.amount_w_vat.toFixed(2)),
                remaining: parseFloat(invoice.remaining_w_vat.toFixed(2)) === 0 ? "0.00" : parseFloat(invoice.remaining_w_vat.toFixed(2)),
                due: parse(invoice.due_date, "dd.MM.yyyy", new Date()),
                status: getStatusText(invoice.status)
            }));
            setFilteredInvoices(formattedFilteredInvoices);
            setInputValueDoc(searchPhrase);
            setOpenDoc(false);
        }
    };


    useEffect(() => {
        if (lang === "en") {
            setLocale(enGB)
        }
        if (lang === "et") {
            setLocale(et)
        }
        if (lang === "fi") {
            setLocale(fi)
        }
        if (lang === "lt") {
            setLocale(lt)
        }
        if (lang === "lv") {
            setLocale(lv)
        }
        if (lang === "nb") {
            setLocale(nb)
        }
        if (lang === "ru") {
            setLocale(ru)
        }
        if (lang === "sv") {
            setLocale(sv)
        }
    }, [lang]);

    useEffect(() => {
        filter();
    }, [lang, invoices, status, docNo, fromDate, toDate]);

    useEffect(() => {
        dispatch(fetchInvoices());
    }, [dispatch]);

    useEffect(() => {
        if (status) {
            setStatus(statuses.find(stat => stat.value === status.value));
        }
    }, [lang]);

    useEffect(() => {
        let Doc = [];
        invoices.forEach(invoice => {
            Doc.push({ value: `${invoice.number}`, text: `${invoice.number}` });
        });
        setDocNos(Doc);
    }, [invoices]);


    const customTheme = createTheme({
        palette: {
            primary: {
                main: `${theme.colors.orange}`,
            },
        },
    })

    return (
        <Wrapper>
            <Title>{t("invoices.invoices")}</Title>
            <Grid container style={{ marginTop: "15px", width: "100%" }}>
                <Grid item container xs={12} md={12} style={{ marginBottom: "30px", width: "100%" }}>
                    <Grid item xs={12} md={3}>
                        <div style={{ fontSize: "20px" }}>
                            {t("invoices.availableCreditLimit")}
                        </div>
                        <div style={{ fontSize: "22px", fontWeight: "700" }}>
                            {
                                typeof unusedCreditLimit === "number" && unusedCreditLimit !== 0 ? (
                                    `${unusedCreditLimit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${COUNTRY_CURRENCY[currentCountry]}`
                                ) : ("-")
                            }
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <div style={{ fontSize: "20px" }}>
                            {t("invoices.totalCreditLimit")}
                        </div>
                        <div style={{ fontSize: "22px", fontWeight: "700" }}>
                            {
                                typeof fullCredit === "number" && fullCredit !== 0 ? (
                                    `${fullCredit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${COUNTRY_CURRENCY[currentCountry]}`
                                ) : ("-")
                            }
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <div style={{ fontSize: "20px" }}>
                            {t("invoices.balance")}
                        </div>
                        <div style={{ fontSize: "22px", fontWeight: "700" }}>
                            {totalBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {COUNTRY_CURRENCY[currentCountry]}
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <div style={{ fontSize: "20px" }}>
                            {t("invoices.latePayments")}
                        </div>
                        <div style={{ fontSize: "22px", fontWeight: "700" }}>
                            {
                                typeof latePayments === "number" && latePayments !== 0 ? (
                                    `${latePayments.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${COUNTRY_CURRENCY[currentCountry]}`
                                ) : ("-")
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={12}>
                    <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column", height: "80px", justifyContent: "flex-end" }}>
                        <UserMenuSelectLabel>
                            {t("invoices.invoiceDate")}
                        </UserMenuSelectLabel>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "space-between", justifyContent: "space-between", width: isMobileScreen ? "100%" : "90%" }}>
                            <ThemeProvider theme={customTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                    <StyledDatePicker
                                        // label={t("invoices.from")}
                                        emptyLabel={null}
                                        format="dd.MM.yyyy"
                                        value={fromDate}
                                        onChange={date => setFromDate(date ? date.setHours(0, 0, 0, 0) : null)}
                                        variant="dialog"
                                        minDate={new Date('2000-01-01')}
                                        maxDate={toDate || Date.now()}
                                        animateYearScrolling
                                        maxDateMessage=" "
                                        minDateMessage=" "
                                        clearable
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        okLabel={t("invoices.ok")}
                                        clearLabel={t("invoices.clear")}
                                        cancelLabel={t("invoices.cancel")}
                                    />
                                    <StyledDatePicker
                                        // label={t("invoices.to")}
                                        emptyLabel={null}
                                        format="dd.MM.yyyy"
                                        value={toDate}
                                        onChange={date => setToDate(date ? date.setHours(0, 0, 0, 0) : null)}
                                        variant="dialog"
                                        minDate={fromDate || new Date('2000-01-01')}
                                        maxDate={Date.now()}
                                        animateYearScrolling
                                        maxDateMessage=" "
                                        minDateMessage=" "
                                        clearable
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        okLabel={t("invoices.ok")}
                                        clearLabel={t("invoices.clear")}
                                        cancelLabel={t("invoices.cancel")}
                                    />
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3} style={{ display: "flex", flexDirection: "column", height: "80px", justifyContent: "flex-end" }}>
                        <UserMenuSelectLabel>
                            {t("invoices.status")}
                        </UserMenuSelectLabel>
                        <SelectFormControl type="noMargin" style={{ width: isMobileScreen ? "100%" : "90%", background: "rgba(232, 232, 232, 0.5)", height: "40px" }}>
                            <MultipleAutocomplete
                                selectedOptions={status}
                                options={statuses}
                                multiple={false}
                                onChange={handleStatusChange}
                            />
                        </SelectFormControl>
                    </Grid>
                    <Grid item xs={12} md={3} style={{ display: "flex", flexDirection: "column", height: "80px", justifyContent: "flex-end" }}>
                        <UserMenuSelectLabel>
                            {t("invoices.documentNo")}
                        </UserMenuSelectLabel>
                        <SelectFormControl type="noMargin" style={{ width: isMobileScreen ? "100%" : "90%", background: "rgba(232, 232, 232, 0.5)", height: "40px" }}>
                            <Autocomplete
                                multiple={false}
                                id="multiple-autocomplete"
                                options={docNos}
                                disableCloseOnSelect={false}
                                selectedOptions={docNo}
                                getOptionLabel={(option) => option.text || option.value || option.id || ""}
                                renderOption={(option) => (
                                    <React.Fragment>{option.text || option.ssd || option.value}</React.Fragment>
                                )}
                                getOptionSelected={(option, selectedOptions) => option.id === selectedOptions.id}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={handleDocNoChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        value={inputValueDoc}
                                        onKeyPress={handleSearchInputKeyPress}
                                    />
                                )}
                                noOptionsText={t("autocomplete.noOptions")}
                                open={openDoc}
                                onOpen={() => {
                                    if (inputValueDoc) {
                                        setOpenDoc(true);
                                    }
                                }}
                                onClose={() => setOpenDoc(false)}
                                inputValue={inputValueDoc}
                                onInputChange={(e, value) => {
                                    setInputValueDoc(value);
                                    if (!value) {
                                        setOpenDoc(false);
                                    } else {
                                        setOpenDoc(true);
                                    }
                                }}
                                popupIcon={<></>}
                                onBlur={inputValueDoc}
                            />
                        </SelectFormControl>
                    </Grid>
                    <Grid item xs={12} md={1} style={{ display: "flex", justifyContent: "space-evenly", alignItems: "flex-end", height: "80px" }}>
                        <Button buttonVariant="Light" style={{ width: "150px", height: "50px" }} onClick={handleClearFilters}>
                            {t("invoices.discardFilters")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12} >
                        <ThemeProvider theme={customTheme}>
                            <Table invoices={filteredInvoices} isLoading={isLoading} style={{ width: "100%" }} />
                        </ThemeProvider>
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    );
});
