import styled from "styled-components";
import Switch from "@material-ui/core/Switch";

export const OrangeSwitch = styled(Switch)`
        .MuiSwitch-switchBase {
            color: #ffb74d;
            &.Mui-checked {
                color: #ff9800;
            }; 
            &.Mui-checked + .MuiSwitch-track{
                background-color: #ff9800;
            }
        };
    `
;
