import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserMenuSeparator } from "../../../Header/style";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { ChooseCartButton, ChooseCartTitle, CartFlyoutWrapper, ChooseCartList } from "./style";
import Button from "../../../Button";
import { Input } from "../../../Form";
import { Box } from "../../../Box";
import Typography from "../../../Typography";

export const CartFlyout = React.forwardRef(
    ({ carts, onNewCart, onAddItem }, ref) => {
        const [newCartName, setNewCartName] = useState("");
        const [errorMessageVisible, setErrorMessageVisible] = useState(false);
        const { t } = useTranslation();
        const handleNewCartNameChange = useCallback(
            ({ target: { value } }) => {
                setNewCartName(value);
            },
            [setNewCartName]
        );
        const handleAddNewCart = useCallback(() => {
            if (newCartName) {
                setErrorMessageVisible(false);
                onNewCart(newCartName);
            }
            else {
                setErrorMessageVisible(true);
            }
        }, [onNewCart, newCartName]);

        const addItem = (id, title, e) => {
            e.stopPropagation()
            onAddItem(id, title)
        }

        return (
            <CartFlyoutWrapper>
                <div ref={ ref }>
                    <ChooseCartTitle>
                        {t("productsList.cartButton.flyout.title")}
                    </ChooseCartTitle>
                    <ChooseCartList>
                        {carts &&
                            carts.map(({  id = '', title = '' }) => (
                                <ChooseCartButton
                                    onClick={(e) => addItem(id, title, e)}
                                    key={id}
                                >
                                    <ShoppingCartIcon />
                                    <Typography color="white" marginLeft="10px" customVariant="body">
                                        {title}
                                    </Typography>
                                </ChooseCartButton>
                            ))}
                    </ChooseCartList>
                </div>
                <UserMenuSeparator />
                <ChooseCartTitle>
                    {t("productsList.cartButton.flyout.cartName")}
                </ChooseCartTitle>
                <Box mb="xs">
                    <Input
                        type="text"
                        id="new-cart-name"
                        name="new-cart-name"
                        value={newCartName}
                        onChange={handleNewCartNameChange}
                        placeholder={t(
                            "productsList.cartButton.flyout.inputPlaceholder"
                        )}
                        error={errorMessageVisible}
                        onClick={(e)=> e?.stopPropagation}
                    />
                </Box>
                {errorMessageVisible &&
                    <Box mb="xs" justifyContent="center">
                        <Typography color="error" customVariant="caption">
                            {t("productsList.cartButton.flyout.inputPlaceholder")}!
                        </Typography>
                    </Box>
                }
                <Button
                    buttonVariant={carts ? "Light" : ""}
                    onClick={handleAddNewCart}
                    height="48px"
                >
                    {t("productsList.cartButton.flyout.btnCaption")}
                </Button>
            </CartFlyoutWrapper>
        );
    }
);
