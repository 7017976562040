import * as Yup from "yup";

const validationMessages = {
    name: "register.validationMessages.name",
    surname: "register.validationMessages.surname",
    required: "register.validationMessages.required",
    userName: "register.validationMessages.userName",
    phoneNumber: "register.validationMessages.phoneNumber",
    password: "register.validationMessages.password",
    confirmPassword: "register.validationMessages.confirmPassword",
    tos: "register.validationMessages.tos",
    email: "register.validationMessages.email",
};

const regexes = {
    name: /^([a-zA-ZçÇğĞıİöÖşŞüÜ]{2,30})([ ]?[a-zA-ZçÇğĞıİöÖşŞüÜ]{0,30})$/,
    surname: /^([a-zA-ZçÇğĞıİöÖşŞüÜ]{2,30})([ ]?[a-zA-ZçÇğĞıİöÖşŞüÜ]{0,30})$/,
    phoneNumber: /[0]*\([0]*([0-9]{3}|0{1}((x|[0-9]){2}[0-9]{2}))\)\s*[0-9]{3,4}[- ][0-9]{2}\s*[0-9]{2}/,
    password: /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/,
    email: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
    userName: /^([a-zA-ZçÇğĞıİöÖşŞüÜ0-9@.+\-_ ]{3,150})$/,
};

export const RegisterSchema = Yup.object().shape({
    name: Yup.string().required(validationMessages.required),
    registrationNumber: Yup.string().required(validationMessages.required),
    email: Yup.string()
        .required(validationMessages.required)
        .matches(regexes.email, { message: validationMessages.email }),
    phone: Yup.string().required(validationMessages.required),
    address: Yup.string().required(validationMessages.required),
    city: Yup.string().required(validationMessages.required),
    postalCode: Yup.string().required(validationMessages.required),
    userName: Yup.string()
        .required(validationMessages.required)
        .matches(regexes.userName, { message: validationMessages.userName }),
    password: Yup.string()
        .required(validationMessages.required)
        .matches(regexes.password, { message: validationMessages.password }),
    confirmPassword: Yup.string()
        .required(validationMessages.required)
        .matches(regexes.password, { message: validationMessages.password })
        .oneOf([Yup.ref("password"), null], validationMessages.confirmPassword),
    firstName: Yup.string().required(validationMessages.required),
    lastName: Yup.string().required(validationMessages.required),
    userEmail: Yup.string()
        .required(validationMessages.required)
        .matches(regexes.email, { message: validationMessages.email }),
});
