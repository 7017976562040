import styled from "styled-components";
import { flex, fullSize } from "../../../../core/styles/common";

export const PageLayout = styled.div`
    background: ${({ theme }) => theme.colors.background};
    ${fullSize}
    ${flex("column")}
`;

export const LogoContainer = styled.div`
    width: 50%;
    max-width: 300px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
`;

export const FormContainer = styled.div`
    display: grid;
    row-gap: 16px;
    padding: ${({ theme }) => theme.spaces.s};
    max-width: 420px;
    min-width: 375px;
    width: 100%;
    grid-template-rows: min-content;
    background: #fff;
    padding: ${({ theme }) => `${theme.spaces.xl} ${theme.spaces.m}`};
`;
