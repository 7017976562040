import React, { useState, useEffect, useRef, useCallback } from "react";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { StyledFavourtiteUnchecked, StyledFavourtiteChecked, StyledNotificationBellUnchecked, StyledNotificationBellChecked } from "./style";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useDispatch, useSelector } from "react-redux";
import { addItemToBasket } from "../../../../core/redux/asyncActions/carts";
import { QuantityInput, QuantityInputWrapper, QuantityButtonWrapper } from "./style";
import { CartButton } from "../CartButton";
import TourPopup from "../../../Tour/TourPopup";
import { useTranslation } from "react-i18next";
import { setFavourite, removeFavourite, fetchFavourites, setAvailabilityNotifications, removeAvailabilityNotifications, fetchAvailabilityNotifications } from "../../../../core/redux/asyncActions/global";
import { REMOVE_FAVOURITE_SUCCESS, SET_FAVOURITE_SUCCESS, REMOVE_AVAILABILITY_NOTIFICATIONS_SUCCESS, SET_AVAILABILITY_NOTIFICATIONS_SUCCESS } from "../../../../core/redux/actionTypes/global";
import { Popper } from "../../../Popper";
import { FavoriteTipsFlyout } from "./FavoriteTipsFlyout";
import { NotificationTipsFlyout } from "./NotificationTipsFlyout";

export default function QuantityButton({ showTourTooltip, fav = false, data, cartSize, productUnitsCount, itemAkCode, itemNo }) {
    const {
        unit_price,
        count,
        cart_id = '',
        item_id = '',
        title
    } = { ...data };

    const sourceLocation = useSelector((state) => state.carts.sourceLocation);
    const { t } = useTranslation();
    const itemRef = useRef();
    const {
        favoritesList,
        availabilityNotificationsList
    } = useSelector((state) => state?.global);

    const userData = useSelector(
        (state) => state.global.userData
    );
    const tourAkCode = userData && userData.tour_ak_code ? userData.tour_ak_code.ak_code : "";

    const [popperVisibility, setPopperVisibility] = useState(false);
    const favoriteRef = useRef();
    const notificationRef = useRef();
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const closePopper = useCallback(() => {
        setPopperVisibility(false);
    }, [setPopperVisibility]);
    const openPopper = useCallback(() => {
        setPopperVisibility(true);
    }, [setPopperVisibility]);

    const textProps = {
        onMouseEnter: openPopper,
        onMouseLeave: closePopper,
    };
    const dispatch = useDispatch();
    const [productUnits, setProductUnits] = useState(0);
    const [productUnitsOrder, setProductUnitsOrder] = useState(0);
    const [isFavorite, setIsFavorite] = useState(false);
    const [isNotification, setIsNotification] = useState(false);

    useEffect(() => {
        if (Boolean(favoritesList?.results && favoritesList?.count !== 0)) {
            for (let i = 0; i < favoritesList?.results.length; i++) {
                if ((favoritesList?.results[i]?.item_id === data?.id) || (favoritesList?.results[i]?.item_id === data?.item_id)) {
                    setIsFavorite(true)
                    break;
                } else {
                    setIsFavorite(false)
                }
            }
        } else {
            setIsFavorite(false)
        }
    }, [favoritesList, item_id, data?.id]);

    useEffect(() => {
        if (Boolean(availabilityNotificationsList?.results && availabilityNotificationsList?.count !== 0)) {
            for (let i = 0; i < availabilityNotificationsList?.results.length; i++) {
                if ((availabilityNotificationsList?.results[i]?.item_id === data?.id) || (availabilityNotificationsList?.results[i]?.item_id === data?.item_id)) {
                    setIsNotification(true)
                    break;
                } else {
                    setIsNotification(false)
                }
            }
        } else {
            setIsNotification(false)
        }
    }, [availabilityNotificationsList, item_id, data?.id]);

    const handleCountInput = async (e) => {
        e?.stopPropagation()
        let value;
        if (e?.target?.value) {
            if (e.target.value === "") {
                value = 0;
            } else {
                value = e.target.value;
            }
        } else {
            value = e;
        }
        let intValue = parseInt(value);
        if (!!unit_price && (fav === false)) {
            setProductUnitsOrder(intValue);
        }
        else {
            setProductUnits(intValue);
            productUnitsCount(intValue);
        }
        if (isNaN(intValue)) {
            if (!!unit_price && (fav === false)) {
                setProductUnitsOrder(0);
            }
            else {
                setProductUnits(0);
                productUnitsCount(1);
            }
        }
    }

    const handleCountOrderChange = async (e) => {
        let value;
        if (e?.target?.value) {
            value = e.target.value;
        } else {
            value = e;
        }
        let intValue = parseInt(value)
        if (intValue !== count) {
            if (intValue === 0 || e?.target?.value === "") {
                handleRemoveItem()
            } else {
                await dispatch(addItemToBasket(cart_id, item_id, +value, title, data?.description, true, sourceLocation))
            }
        }
    }

    useEffect(() => {
        setProductUnitsOrder(undefined);
    }, [count]);

    const handleRemoveItem = (e) => {
        e?.stopPropagation()
        dispatch(addItemToBasket(cart_id, item_id, -count, title, data?.descriptionm, false, sourceLocation))
    };

    const handleKeyPress = async (e) => {
        e?.stopPropagation()
        if (e.charCode === 13) {
            if (!!unit_price && (fav === false)) {
                handleCountOrderChange(e)
            }
        }
    }

    const intProductUnits = parseInt(productUnits);

    const handleIncrementCount = (e) => {
        e?.stopPropagation()
        if (!!unit_price && (fav === false)) {
            if (productUnitsOrder !== undefined) {
                handleCountOrderChange(productUnitsOrder + 1)
            } else {
                handleCountOrderChange(count + 1)
            }
        } else {
            if (productUnits === 0) {
                setProductUnits(intProductUnits + 2);
                productUnitsCount(intProductUnits + 2);
            } else {
                setProductUnits(intProductUnits + 1)
                productUnitsCount(intProductUnits + 1);
            }
        }
    }

    const handleDecrementCount = (e) => {
        e?.stopPropagation()
        if (!!unit_price && (fav === false)) {
            if (productUnitsOrder !== undefined) {
                handleCountOrderChange(productUnitsOrder - 1)
            } else {
                handleCountOrderChange(count - 1)
            }
        } else {
            if (productUnits > 1) {
                setProductUnits(intProductUnits - 1)
                productUnitsCount(intProductUnits - 1);
            }
        }
    }

    const handleAddFavourite = async () => {
        const { type } = await dispatch(setFavourite(item_id || data?.id, data?.description));
        if (type === SET_FAVOURITE_SUCCESS) {
            dispatch(fetchFavourites());
        }
    }

    const handleRemoveFavourite = async () => {
        const { type } = await dispatch(removeFavourite(item_id || data?.id, data?.description));
        if (type === REMOVE_FAVOURITE_SUCCESS) {
            dispatch(fetchFavourites());
        }
    }

    const handleAddNotification = async () => {
        const { type } = await dispatch(setAvailabilityNotifications(item_id || data?.id, data?.description));
        if (type === SET_AVAILABILITY_NOTIFICATIONS_SUCCESS) {
            dispatch(fetchAvailabilityNotifications());
        }
    }

    const handleRemoveNotification = async () => {
        const { type } = await dispatch(removeAvailabilityNotifications(item_id || data?.id, data?.description));
        if (type === REMOVE_AVAILABILITY_NOTIFICATIONS_SUCCESS) {
            dispatch(fetchAvailabilityNotifications());
        }
    }

    const openFavoritePopper = () => {
        setPopperVisibility("favorite");
    };
    
    const openNotificationPopper = () => {
        setPopperVisibility("notification");
    };

    return (
        <>
            <QuantityButtonWrapper ref={itemRef} >
                {(fav === false && data && data.stock) && (
                    <div
                        ref={notificationRef}
                        {...textProps}
                        onMouseEnter={openNotificationPopper}
                        onMouseLeave={closePopper}
                        style={{
                            display: "inherit",
                            opacity: !data.stock.show_notification_bell ? "0" : "1",
                            pointerEvents: !data.stock.show_notification_bell ? "none" : "inherit"
                        }}
                    >
                        {isNotification ?
                            <StyledNotificationBellChecked onClick={handleRemoveNotification} />
                            : <StyledNotificationBellUnchecked onClick={handleAddNotification} />
                        }
                    </div>
                )}
                {(fav === false) && (isFavorite ?
                    <div
                        ref={favoriteRef}
                        {...textProps}
                        onMouseEnter={openFavoritePopper}
                        onMouseLeave={closePopper}
                        style={{ display: "inherit" }}
                    >
                        <StyledFavourtiteChecked onClick={handleRemoveFavourite} />
                    </div>
                    : <div
                        ref={favoriteRef}
                        {...textProps}
                        onMouseEnter={openFavoritePopper}
                        onMouseLeave={closePopper}
                        style={{ display: "inherit" }}
                    >
                        <StyledFavourtiteUnchecked onClick={handleAddFavourite} />
                    </div>
                )}
                <QuantityInputWrapper>
                    <QuantityInput
                        type="text"
                        inputmode="numeric"
                        placeholder={(!!unit_price && (fav === false)) ? "" : "1"}
                        onBlur={(!!unit_price && (fav === false)) ? handleCountOrderChange : handleCountInput}
                        onChange={handleCountInput}
                        onKeyPress={handleKeyPress}
                        value={((!!unit_price && (fav === false)) && productUnitsOrder !== undefined) ? (productUnitsOrder > 0 ? productUnitsOrder : "") :
                            (count > 0) ? count : (productUnits > 0 ? productUnits : "")}
                        onClick={(e) => e?.stopPropagation()}
                    />
                    <button>
                        <KeyboardArrowUpIcon onClick={handleIncrementCount} />
                    </button>
                    <button>
                        <KeyboardArrowDownIcon onClick={handleDecrementCount} />
                    </button>
                </QuantityInputWrapper>
                {((!unit_price) || (fav)) && (
                    <CartButton
                        showTourTooltip={showTourTooltip}
                        item={data}
                        count={(productUnits === 0 || !productUnits) ? 1 : productUnits}
                        size={cartSize}
                    />
                )}
                {(!!unit_price && (fav === false)) && (
                    <button onClick={handleRemoveItem}>
                        <DeleteOutlinedIcon />
                    </button>
                )}
            </QuantityButtonWrapper>
            {showTourTooltip && (
                <TourPopup
                    step={16}
                    anchorEl={itemRef?.current}
                    placement={'bottom-end'}
                    delay={200}
                >
                    {t('appTour.addToCart')}
                </TourPopup>
            )}
            {itemAkCode === tourAkCode && (
                <TourPopup
                    step={12}
                    placement={isMobileScreen ? "top-middle" : "left-start"}
                    anchorEl={itemRef?.current}
                >
                    {t("appTour.notificationsAndFavorites")}
                </TourPopup>
            )}
            {tourAkCode === "" && itemNo === 0 && (
                <TourPopup
                    step={12}
                    placement={isMobileScreen ? "top-middle" : "left-start"}
                    anchorEl={itemRef?.current}
                >
                    {t("appTour.favorites")}
                </TourPopup>
            )}
            {!isMobileScreen && (
                <div>
                    {(popperVisibility === "favorite") && (
                        <Popper
                            id={item_id || data?.id}
                            visibility={true}
                            anchorEl={favoriteRef?.current}
                            onClickAway={() => closePopper("favorite")}
                            ignoreBackground={true}
                        >
                            <FavoriteTipsFlyout isFavorite={isFavorite} />
                        </Popper>
                    )}
                    {(popperVisibility === "notification") && (
                        <Popper
                            id={item_id || data?.id}
                            visibility={true}
                            anchorEl={notificationRef?.current}
                            onClickAway={() => closePopper("notification")}
                            ignoreBackground={true}
                        >
                            <NotificationTipsFlyout isNotification={isNotification} />
                        </Popper>
                    )}
                </div>
            )}
        </>
    )
}