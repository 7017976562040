import React, { useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { theme } from "../../core/styles/theme";
import { LeftArrow, RightArrow } from "./style";

export default function StyledPagination ({count, setPageNumber, pageNumber}) {
    const handleCurrentPageChange = (_, pageNumber) => {
        setPageNumber(pageNumber);
    };

    const renderItem = useCallback((paginationItemProps) => {
        if (paginationItemProps.type === "previous") {
            return (
                <LeftArrow type={paginationItemProps.disabled ? "disabledArrow" : "activeArrow"}
                    onClick={!paginationItemProps.disabled ? paginationItemProps.onClick : undefined}
                />
            );
        }
        if (paginationItemProps.type === "next") {
            return (
                <RightArrow type={paginationItemProps.disabled ? "disabledArrow" : "activeArrow"}
                    onClick={!paginationItemProps.disabled ? paginationItemProps.onClick : undefined}
                />
            );
        }
        return <PaginationItem {...paginationItemProps} />;
    }, []);

    return( 
        <Pagination 
            style={{marginTop: `${theme.spaces.m}`}}
            count={Math.ceil(count)}
            variant="outlined"
            shape="rounded"
            page={pageNumber}
            onChange={handleCurrentPageChange}
            renderItem={renderItem}
        />
    );
};