import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { flex } from "../core/styles/common";

export const customLinkShouldForwardProp = (prop) =>
    !["ml", "underlineOnHover", "lineHeight"].includes(prop);

const CustomLink = styled(Link).withConfig({
    shouldForwardProp: customLinkShouldForwardProp,
})(
    ({ theme, fontSize, fontWeight, lineHeight, ml, underlineOnHover }) => css`
        && {
            color: ${theme.colors.font.base};
            cursor: pointer;
            font-size: ${fontSize || theme.typography.body.fontSize};
            font-weight: ${fontWeight || theme.typography.body.fontWeight};
            line-height: ${lineHeight || theme.typography.body.lineHeight};
            ${ml ? `margin-left: ${ml};` : ""}

            ${underlineOnHover
                ? css`
                      text-decoration: none;
                      &:hover {
                          text-decoration: underline;
                      }
                  `
                : css`
                      text-decoration: underline;
                  `}
        }
    `
);

const computeColorStyle = (theme, variantOutlined) => {
    let style = "";

    if (variantOutlined) {
        style = css`
            background: "transparent";
            color: ${theme.colors.orange};
            border: 1px solid ${theme.colors.orange};
            font-weight: 600;

            &:hover {
                color: ${theme.colors.otherOrange};
                border-color: ${theme.colors.otherOrange};
            }
        `;
    } else {
        style = css`
            color: "white";
            background: ${theme.colors.orange};

            &:hover {
                background: ${theme.colors.orangeDark};
            }
        `;
    }

    return style;
};

export const shouldForwardProp = (prop) => !["variantOutlined"].includes(prop);
export const LinkAsButton = styled(Link).withConfig({ shouldForwardProp })(
    ({ theme, variantOutlined }) => css`
        ${flex()}
        width: 100%;
        height: 45px;
        border-radius: ${theme.borderRadiuses.base};
        text-decoration: none;
        ${computeColorStyle(theme, variantOutlined)}
    `
);

export default CustomLink;
