import Input from "@material-ui/core/Input";
import styled, { css } from "styled-components";

const StyledInput = styled(Input)(
    ({ theme }) => css`
        position: relative;
        width: 100%;
        border: 1px solid #eeeeee;
        border-radius: ${theme.borderRadiuses.base};
        & input {
            padding: ${theme.spaces.s};
            color: ${theme.colors.font.dark};
        }
        &:before,
        &:after {
            content: none !important;
        }
    `
);

const InputWrapper = ({ children, onEnter, ...restProps }) => {
    const handleKeyDown = (evt) => {
        if (evt.keyCode === 13) {
            // Enter has been pressed
            onEnter?.();
        }
    };
    return (
        <StyledInput onKeyDown={handleKeyDown} {...restProps}>
            {children}
        </StyledInput>
    );
};

export default InputWrapper;
