import styled, { css } from "styled-components";

export const ImagePreview = styled.img`
    width: 175px;
    height: 140px;
    object-fit: contain;
`;

export const ImageFlyoutWrapper = styled.div(
    ({ height, width }) => css`
        height: ${height || "500px"};
        width: ${width || "501px"};
        background: white;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25),
            0px 4px 16px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        padding: 24px;

        && img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    `
);
