import { DataGrid } from '@mui/x-data-grid';
import styled from "styled-components";

export const StyledDataGrid = styled(DataGrid)`
    &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus {
        outline: none;
    }
    .MuiTablePagination-caption {
        display: none;
    }
`;