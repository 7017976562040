import styled, { css } from "styled-components";
import { breakpoints } from "../../../core/utils/style";
import Card from "@material-ui/core/Card";
import { theme } from "../../../core/styles/theme";
import SettingsIcon from "@material-ui/icons/Settings";
import Notifications from "@material-ui/icons/Notifications";

export const Container = styled.div``;
export const CustomScrollArea = styled.div`
    /* width */
    &::-webkit-scrollbar {
        border: 1px solid #465d66;
        width: 7px;
        height: 18px;
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar-thumb {
        height: 6px;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 6px;
        background-color: #465d66;
        -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
            inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;
export const CartListWrapper = styled(CustomScrollArea)`
    max-height: 40.2338vh;
    overflow: auto;
    padding-top: 8px;
    width: 100%;

    @media (max-height: 666px) and (max-width: 320px) {
        max-height: 33.2338vh;
    }

    @media (min-width: ${breakpoints.md}) {
        max-height: 31.98vh;
        padding-top: 16px;
    }
`;

export const CartWrapper = styled.div`
    background: #ffffff;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 55px;
    z-index: 100;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.25);
    overflow-y: auto;

    @media (min-width: ${breakpoints.md}) {
        position: absolute;
        left: inherit;
        right: 168px;
        bottom: inherit;
        top: 81px;
        width: 477px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25),
            0px 4px 16px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        overflow: hidden;
        z-index: 291;
    }

    .MuiTabs-scrollButtonsDesktop {
        display: inline-flex;
    }
    .MuiAppBar-root {
        background: none;
        .Mui-selected {
            color: ${theme.colors.otherOrange};
        } 
    }

    .MuiTabs-indicator {
        background-color: ${theme.colors.otherOrange};
    }

    .MuiBox-root {
        padding: 0;
    }

    .MuiTabs-root {
        @media (min-width: ${breakpoints.md}) {
            min-height: 46px;
        }
    }

    .MuiTab-root {
        @media (min-width: ${breakpoints.md}) {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
`;

export const CartInfoSection = styled.section`
    border-bottom: 1px solid #37474f;
    border-top: 1px solid #37474f;
    padding: 2.3316vh 16px 2.0725vh;

    @media (min-width: ${breakpoints.md}) {
        padding: 16px 24px;
    }
`;

export const CartInfoSectionLine = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 1.0362vh;
        @media (min-width: ${breakpoints.md}) {
            margin-bottom: 8px;
        }
    }
`;


export const CartInfoSectionLineOrder = styled.div`
    align-items: right;
    display: flex;
    justify-content: right;

    &:not(:last-child) {
        margin-bottom: 1.0362vh;
        @media (min-width: ${breakpoints.md}) {
            margin-bottom: 8px;
        }
    }

    p {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
`;

export const CartInfoText = styled.p`
    font-weight: normal;
    font-size: 14px;
    line-height: 2.5906vh;
    /* identical to box height, or 143% */

    letter-spacing: 0.15px;

    color: #37474f;
    @media (min-width: ${breakpoints.md}) {
        line-height: 20px;
    }
`;

export const CartItem = styled.div`
    align-items: flex-start;

    display: flex;
    padding: 2.0725vh 16px 2.0725vh 6px;

    &:not(:last-of-type) {
        border-bottom: 1px solid #37474f;
    }

    @media (min-width: ${breakpoints.md}) {
        padding: 16px 16px 15px 15px;
    }

`;

export const CartImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-right: 7px;
    width: 60px;
    
    @media (min-width: ${breakpoints.md}) {
        margin-right: 10px;
        width: 50px;
        height: 50px;
    }
`;

export const CartImage = styled.img`
    display: flex;
    max-width: 50px;
    object-fit: contain;
    font-size: 16px;

    @media (min-width: ${breakpoints.md}) {
        height: 100%;
        width: auto;
    }
`;

export const CartInfoBox = styled.div`
    flex: 1 1 auto;
    @media (min-width: ${breakpoints.md}) {
        display: flex;
        position: relative;
    }
`;

export const CartInfoHeader = styled.header`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2953vh;
    width: 130px; 
    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;

export const CartInfoTitle = styled.h6`
    font-weight: 500;
    font-size: 14px;
    line-height: 2.5906vh;
    letter-spacing: 0.15px;
    color: #37474f;
    max-width: 43.3333vw;
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.md}) {
        width: 144px;
        line-height: 20px;
        margin-right: 24px;
    }
`;

export const CartInfoPrice = styled.p`
    font-weight: bold;
    font-size: 14px;
    line-height: 3.1088vh;

    @media (min-width: ${breakpoints.md}) {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 16px;
        line-height: 16px;
        color: #37474f;
    }
`;
export const CartInfoListWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;
export const CartInfoList = styled.ul`
    list-style: none;
`;
export const CartInfoListItem = styled.li`
    font-weight: normal;
    font-size: 12px;
    line-height: 1.8134vh;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #37474f;

    @media (min-width: ${breakpoints.md}) {
        line-height: 14px;
    }

    &:not(:last-child) {
        margin-bottom: 1.2953vh;

        @media (min-width: ${breakpoints.md}) {
            margin-bottom: 8px;
        }
    }
`;
export const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    @media (min-width: ${breakpoints.md}) {
        position: absolute;
        right: 0;
        top: 39px;
    }
`;

export const CartButton = styled.div`
    padding: 6px;
    @media (min-width: ${breakpoints.md}) {
        padding: 3px;
    }
`;


export const BasketMenuArrow = styled.span`
    display: none;
    position: absolute;
    width: 13px;
    height: 13px;
    left: ${({ left }) => (left && left.offsetLeft + 15 + "px")};
    top: 74px;
 
    background: #ffffff;
    z-index: 290;
    box-shadow: 2px -2px 3px 0px rgba(0, 0, 0, 0.05);
    border-radius: 1.61213px;
    transform: rotate(-45deg);
  
        
    @media (min-width: ${breakpoints.md}) {
        display: block;
    }
`;

export const Arrow = styled.span`
    display: none;
    position: fixed;
    width: 13px;
    height: 13px;
    left: ${({ left }) => (left && left.offsetLeft + 15 + "px")};
    top: 74px;
    background: #ffffff;
    z-index: 291;
    box-shadow: 2px -2px 3px 0px rgba(0, 0, 0, 0.05);
    border-radius: 1.61213px;
    transform: rotate(-45deg);
    background-color: white;
  
    @media (min-width: ${breakpoints.md}) {
        display: block;
        width: 13px;
        height: 13px;
        right: 225px;
        background: ${theme.colors.white};
        z-index: 1000;
        box-shadow: 2px -2px 3px 0px rgba(0, 0, 0, 0.05);
        border-radius: 1.61213px;
        transform: rotate(-45deg);
    }
`;

export const Root = styled.div`
    flexgrow: 1;
    width: 100%; 
`;

export const StyledCard = styled(Card)`
    margin: 6px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    .MuiCardHeader-avatar {
        margin: 0;
    }
    ${(props) =>
        props.hasStock &&
        css`
          border-style: solid;
          border-width: 3px;
          border-color: rgb(127 238 127);
        `
    }
`;

export const imageWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-right: 10px;
    width: 50px;
    height: fit-content;
`;

export const StyledFavourtiteChecked = styled(SettingsIcon)`
    cursor: pointer;
     
    &.MuiSvgIcon-root {
        fill: ${theme.colors.orange};
    }
    &:hover {
            fill: ${theme.colors.grey};
    }
`;

export const StyledNotificationChecked = styled(Notifications)`
    cursor: pointer;
     
    &.MuiSvgIcon-root {
        fill: ${theme.colors.orange};
    }
    &:hover {
            fill: ${theme.colors.grey};
    }
`;

export const FavListWrapper = styled(CustomScrollArea)`
    height: 100%;
    overflow: auto;
    padding-top: 8px;
    width: 100%;
    cursor: default;
`;

export const NotificationsListWrapper = styled(CustomScrollArea)`
    height: 100%;
    overflow: auto;
    padding-top: 8px;
    width: 100%;
    cursor: default;
`;

export const FlyoutWrapper = styled.div`
    background: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 10px 20px;
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
`;