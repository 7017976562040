import Header from "../../components/Header";
import Wrapper from "../../components/Wrapper";
import RegisterForm from "./components/RegisterForm/index";

export default function RegisterPage() {
    return (
        <div>
            <Header page="register"/>
            <Wrapper>
                <RegisterForm />
            </Wrapper>
        </div>
    );
}

export { RegistrationSuccess } from "./components/RegistrationSuccess";
