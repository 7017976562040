import styled, { css } from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import { theme } from "../../core/styles/theme";
import Box from "@material-ui/core/Box";

export const OrderList = styled.div`
  margin-top: 2%;
`;

export const SelectFormControl = styled(FormControl)`
  border-radius: 5px;
  max-width: 300px;
  background-color: rgba(232,232,232,0.5);
  box-sizing: border-box;
  margin: 10px 0 20px 0 !important;
  width: calc(100% - 32px);

  fieldset {
    border: 0;
  }

  .MuiSelect-root {
    color: var(--main-color);
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink,
  .PrivateNotchedOutline-legendNotched-4 {
    display: none;
  }

  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    color: #37474f;
    font-size: 14px;
  }
`;

export const FormLabel = styled.label`
    color: #37474f;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    ${({ required }) =>
    required &&
    css`
            &:after {
                color: #f28a22;
                content: " *";
            }
        `}
    ${({ bold }) =>
      bold &&
      css`
        font-weight: 700;
      `}
`;

export const StyledBox = styled(Box)`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: ${theme.spaces.s};
    ${({ orderId }) =>
      orderId && css`
          display: none;
    `}
    ${({ delivery }) =>
      delivery && css`
        margin-bottom: 0;
    `}
    ${({ order }) =>
      order && css`
        height: 40px;
    `}
    ${({ loading }) =>
      loading && css`
        align-items: center;
        justify-content: center;
        margin-bottom: ${theme.spaces.m};
    `}
`;