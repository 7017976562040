// This file's purpose is to host shared styles only. Don't put styled components here.
import { css } from 'styled-components'

export const flex = (
  flexDirection = 'row',
  justifyContent = 'center',
  alignItems = 'center'
) => `
    display: flex;
    flex-direction: ${flexDirection};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
  `

export const fullHeight = css`
  height: 100%;
`

export const fullWidth = css`
  width: 100%;
`

export const fullSize = css`
  ${fullHeight}
  ${fullWidth}
`

export const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
