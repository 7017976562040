import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { ItemCard } from "../../../ItemCard";
import { ProductsList } from "../../../ProductsList";
import { StyledCircularProgress } from "../../../../../CircularProgress";

const AlternativeProducts = ({
    productsDetails,
    isLoadingDetails,
    isPhoneScreen,
}) => {
    return (
        <Box
            minHeight="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {isLoadingDetails && (
                <Box justifyContent="center" my="xl">
                    <StyledCircularProgress disableShrink/>
                </Box>
            )}
            {!isLoadingDetails &&
                (isPhoneScreen ? (
                    <Grid container spacing={1} width="100%">
                        {Boolean(productsDetails.length) &&
                            productsDetails.map((prod) => (
                                <Grid
                                    style={{ width: "100%" }}
                                    item
                                    key={`alternative-prod-${prod.id}`}
                                >
                                    <ItemCard data={prod} showQuantity={true} showMinQuantity={false} />
                                </Grid>
                            ))}
                    </Grid>
                ) : (
                    Boolean(productsDetails.length) && (
                            <ProductsList
                                products={productsDetails}
                                general={true}
                            />
                    )
                ))}
        </Box>
    );
};

export default memo(AlternativeProducts);
