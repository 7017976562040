import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import Header from "../../components/Header";
import Wrapper from "../../components/Wrapper";
import Drawer from "../../components/Drawer";
import { ProductListing } from "../../components/Listing";
import { fetchMenuProducts } from "../../core/redux/asyncActions/global";
import { fetchBaskets } from "../../core/redux/asyncActions/carts";

export default function HomePage() {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.global.lang);

    useEffect(() => {
        dispatch(fetchMenuProducts());
        dispatch(fetchBaskets())
    }, [language, dispatch]);

    return (
        <div>
            <Header page="home"/>
            <Wrapper>
                <Drawer />
                <ProductListing />
            </Wrapper>
        </div>
    );
}
