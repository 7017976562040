import styled from "styled-components";
import { breakpoints } from "../../../../core/utils/style";
import SettingsIcon from "@material-ui/icons/Settings";
import Notifications from "@material-ui/icons/Notifications";


import { theme } from "../../../../core/styles/theme";

export const QuantityInput = styled.input`
    background: #ededed;
    border-radius: 2px;
    width: 46px;
    font-size: 14px;
    height: 28px;
    border: 0;
    text-align: center;
   
    &::placeholder {
        color: #37474f;
        font-size: 14px;
        
        @media (min-width: ${breakpoints.md}) {
            font-size: 16px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        height: 32px;
        margin-right: 0;
        font-size: 16px;
    }
`;

export const QuantityInputWrapper = styled.div`
    background: #ededed;
    display: inline-flex;
    flex-wrap: nowrap;
    margin-right: 15px;
    
    svg {
        color: #37474f;
        font-size: 21px;

        &:hover {
            color: #f28a22;
        }
    }
`;

export const QuantityButtonWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const StyledFavourtiteUnchecked = styled(SettingsIcon)`
    margin-right: 15px;
    color: ${theme.colors.grey};
    &:hover {
        color: ${theme.colors.orange};
    }
`;

export const StyledFavourtiteChecked = styled(SettingsIcon)`
    margin-right: 15px;
    color: ${theme.colors.orange};
    &:hover {
        color: ${theme.colors.grey};
    }
`;

export const StyledNotificationBellUnchecked = styled(Notifications)`
    margin-right: 5px;
    color: ${theme.colors.grey};
    &:hover {
        color: ${theme.colors.orange};
    }
`;

export const StyledNotificationBellChecked = styled(Notifications)`
    margin-right: 5px;
    color: ${theme.colors.orange};
    &:hover {
        color: ${theme.colors.grey};
    }
`;