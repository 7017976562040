import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Modal from "@material-ui/core/Modal";
import Tab from "@material-ui/core/Tab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";

import {
    fetchProductDetailsVehicles,
    updatePricesDetails,
} from "../../../../core/redux/asyncActions/global";

import { ModalHeader } from "../../../ModalHeader";
import { ItemCard } from "../ItemCard";
import { TabPanel } from "./components/TabPanel";
import { InfoCodes } from "./components/InfoCodes";
import { AlternativeProducts } from "./components/AlternativeProducts";
import { LinkedVehicles } from "./components/LinkedVehicles";
import { ItemDetailsHeader } from "./components/ItemDetailsHeader/ItemDetailsHeader";

import {
    Filters,
    FilterLine,
    FilterTitle,
} from "../../style";
import { ModalContent, StyledModalBody, StyledTabs } from "./style";
import StyledPagination from "../../../Pagination";
import { OrangeSwitch } from "../../../Switch";
import { clearProductDetails } from "../../../../core/redux/actions/global";

const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
};

const tabInfo = [
    "productsList.detailsModal.tabs.alternativeProducts",
    "productsList.detailsModal.tabs.specification",
    "productsList.detailsModal.tabs.oemCodes",
    "productsList.detailsModal.tabs.linkedVehicles",
];

export const ItemDetailsModal = ({
    isShowTabs = true,
    clearSelectedItem,
    onCloseDetails
}) => {
    const productItem = useSelector(
        (state) => state.global?.currentProduct || null
    );
    const lang = useSelector(
        (state) => state.global?.lang);
    const visibility = !!productItem;
    const theme = useTheme();
    const { t } = useTranslation();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const setPageNumber = (pageNumber) => {
        setPage(pageNumber);
    };

    const images = useSelector(
        (state) => state.global?.productDetails?.list?.images || []
    );
    const {
        referenced_items_data: productsDetails = [],
        tecdoc_criteria: tecdocCriteria = [],
        oem_codes: oemCodes = [],
        vehicles: linkedVehicles = [],
    } = useSelector((state) => state.global?.productDetails?.list || {});
    const { isLoadingDetails, isLoadingVehicles } = useSelector(
        (state) => state.global?.productDetails || {}
    );

    const { with_discount, with_vat } = useSelector(
        (state) => state.global?.userData || {}
    );
    const [isDiscount, setDiscount] = useState(with_discount)
    const [isVat, setVat] = useState(with_vat)
    const [selectedTab, setSelectedTab] = useState(0);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleDiscountChange = ({ target: { checked } }) => {
        setDiscount(checked)
        dispatch(
            updatePricesDetails(
                `${productItem.id},${productsDetails.map((prod) => prod.id).join(",")}`,
                checked,
                isVat
            )
        );
    };

    const handleVatChange = ({ target: { checked } }) => {
        setVat(checked)
        dispatch(
            updatePricesDetails(
                `${productItem.id},${productsDetails.map((prod) => prod.id).join(",")}`,
                isDiscount,
                checked
            )
        );
    };

    useEffect(() => {
        const id = productItem.id;
        if (id && selectedTab === 3) {
            (async () => {
                const results = await dispatch(
                    fetchProductDetailsVehicles(id, page)
                );
                if (results.type !== "FETCH_PRODUCT_DETAILS_VEHICLES_FAILURE") {
                    setTotalPages(results?.payload?.count / 25);
                }
            })();
        }
    }, [dispatch, lang, productItem?.id, page, selectedTab]);

    const onClose = useCallback(() => {
        dispatch(clearProductDetails());
    }, [dispatch]);

    const handleClose = useCallback(() => {
        if (onCloseDetails) {
            onCloseDetails();
            dispatch(clearProductDetails());
        } else {
            dispatch(clearProductDetails());
        }
    }, [dispatch, onCloseDetails]);

    return (
        <Modal
            open={visibility}
            onClose={onClose}
            aria-labelledby="item-details-modal"
            aria-describedby="item-details-modal"
            disableEnforceFocus
            style={isPhoneScreen && { margin: "56px 3%" }}
        >
            <ModalContent isPhoneScreen={isPhoneScreen} style={{ overflowY: isOverlayVisible ? 'hidden' : 'auto' }}>
                <ModalHeader onClose={handleClose} />

                <StyledModalBody>
                    {isPhoneScreen ? (
                        <ItemCard
                            data={productItem}
                            showQuantity={true}
                            images={images}
                            productDetails={true}
                            setIsOverlayVisible={setIsOverlayVisible}
                        />
                    ) : (
                        <ItemDetailsHeader
                            data={productItem}
                            setIsOverlayVisible={setIsOverlayVisible}
                        />
                    )}
                    {isShowTabs && (
                        <Box padding="20px 0 0 0">
                            <Box overflow="auto">
                                <StyledTabs
                                    value={selectedTab}
                                    onChange={handleTabChange}
                                    aria-label="tabs"
                                >
                                    {tabInfo.map((tabKey, index) => (
                                        <Tab
                                            key={tabKey}
                                            label={t(tabKey)}
                                            {...a11yProps(index)}
                                        />
                                    ))}
                                </StyledTabs>
                            </Box>
                            <TabPanel selected={selectedTab === 0} index={0}>
                                {isPhoneScreen && (
                                    <Filters>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withDiscount")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                label=""
                                                control={
                                                    <OrangeSwitch
                                                        checked={isDiscount}
                                                        onChange={
                                                            handleDiscountChange
                                                        }
                                                    />
                                                }
                                            />
                                        </FilterLine>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withVAT")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                control={
                                                    <OrangeSwitch
                                                        checked={isVat}
                                                        onChange={
                                                            handleVatChange
                                                        }
                                                    />
                                                }
                                                label=""
                                            />
                                        </FilterLine>
                                    </Filters>
                                )}
                                {selectedTab === 0 && !isPhoneScreen && isShowTabs && (
                                    <Filters>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withDiscount")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                label=""
                                                control={
                                                    <OrangeSwitch
                                                        checked={isDiscount}
                                                        onChange={handleDiscountChange}
                                                    />
                                                }
                                            />
                                        </FilterLine>
                                        <FilterLine>
                                            <FilterTitle>
                                                {t("productsList.withVAT")}
                                            </FilterTitle>
                                            <FormControlLabel
                                                control={
                                                    <OrangeSwitch
                                                        checked={isVat}
                                                        onChange={handleVatChange}
                                                    />
                                                }
                                                label=""
                                            />
                                        </FilterLine>
                                    </Filters>
                                )}
                                <AlternativeProducts
                                    {...{
                                        productsDetails,
                                        isLoadingDetails,
                                        isPhoneScreen,
                                        onClose,
                                        clearSelectedItem,
                                    }}
                                />
                            </TabPanel>
                            <TabPanel selected={selectedTab === 1} index={1}>
                                <InfoCodes
                                    codes={tecdocCriteria}
                                    {...{
                                        isLoadingDetails,
                                    }}
                                />
                            </TabPanel>
                            <TabPanel selected={selectedTab === 2} index={2}>
                                <InfoCodes
                                    codes={oemCodes}
                                    {...{
                                        isLoadingDetails,
                                    }}
                                />
                            </TabPanel>
                            <TabPanel selected={selectedTab === 3} index={3}>
                                <LinkedVehicles
                                    {...{
                                        linkedVehicles,
                                        isLoadingVehicles,
                                        isPhoneScreen,
                                    }}
                                />
                            </TabPanel>
                        </Box>
                    )}
                    {selectedTab === 3 && Boolean(linkedVehicles.length) && (
                        <Box
                            display="flex"
                            justifyContent={
                                "center"
                            }
                            padding="0 0 20px"
                        >
                            <StyledPagination
                                count={totalPages}
                                pageNumber={page}
                                setPageNumber={setPageNumber}
                            />
                        </Box>
                    )}
                </StyledModalBody>
            </ModalContent>
        </Modal>
    );
};
