export const getAuthState = () => {
    try {
        const serializedState = localStorage.getItem("autokada_auth_state");

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (error) {
        console.error(error);
    }
};

export const saveAuthState = (authState) => {
    try {
        const serializedState = JSON.stringify(authState);
        localStorage.setItem("autokada_auth_state", serializedState);
    } catch (error) {
        console.error(error);
    }
};
