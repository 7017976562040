import React from "react";

export const MiniButton = ({ inputValue, buttonText, style }) => {
    return (
        <button
            type="submit"
            style={{
                marginLeft: "8px",
                backgroundColor: inputValue ? "#f28a22" : "#808080",
                color: "#ffffff",
                border: "none",
                padding: "0px 7px",
                borderRadius: "2px",
                cursor: inputValue ? "pointer" : "default",
                fontWeight: "bold",
                ...style
            }}
            disabled={!inputValue}
        >
            {buttonText}
        </button>
    );
};
