import {
    PublicProductImageWrapper,
    PublicProductItemContent,
    ProductListItem,
    PublicProductSpec,
    PublicProductSpecs,
    PopoutFlyoutWrapper,
    PopoutFlyoutContent,
    PopoutFlyoutCloseButton,
    PopoutFlyoutLoginButton,
} from "./style";
import { useHistory } from "react-router-dom";
import { useTheme as colorTheme } from "styled-components";
import React, { useState, useCallback, useRef } from "react";
import { Popper } from "../Popper";
import { HOME_PATH } from "../../core/routes";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Close from "@material-ui/icons/Close";
import { ImageWithZoom } from "./components/ImageWithZoom";
import { getImageBaseUrl } from "../../core/utils";
import { ItemCard } from "./components/ItemCard/public-item-card";

export default function PublicItem({ data, type }) {
    const {
        description: title,
        ak_code: akCode,
        vendor,
        image,
        autokada_code = '',
        name = '',
        stock_icon = '',
        vendor_image
    } = { ...data };

    const history = useHistory();
    const color = colorTheme();
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [popperVisibility, setPopperVisibility] = useState(false);
    const PopupRef = useRef();
    const { t } = useTranslation();

    const closePopper = useCallback(() => {
        setPopperVisibility(false);
    }, []);

    const openPopper = useCallback(() => {
        setPopperVisibility(true);
    }, []);

    const togglePopper = (e, type) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (type === "desktop" && window.innerWidth < 960) {
            return false;
        }
        openPopper()
    };

    const handleLoginClick = () => {
        history.push(HOME_PATH);
    };

    if (isPhoneScreen) {
        return (
            <Box marginBottom="8px">
                <ItemCard
                    onTitleClick={togglePopper}
                    data={data}
                />
                <Popper
                    visibility={popperVisibility}
                    anchorEl={togglePopper?.current}
                    onClickAway={closePopper}
                >
                    <PopoutFlyoutWrapper>
                        <PopoutFlyoutCloseButton>
                            <Close cursor="pointer" onClick={closePopper} />
                        </PopoutFlyoutCloseButton>
                        <PopoutFlyoutContent>
                            {t("productsList.publicFlyout.text")}
                            {" "}
                        </PopoutFlyoutContent>
                        <PopoutFlyoutContent
                            onClick={handleLoginClick}
                            style={{ color: color.colors.orange }}
                        >
                            {t("productsList.publicFlyout.link")}
                        </PopoutFlyoutContent>
                    </PopoutFlyoutWrapper>
                </Popper>
            </Box>
        );
    }

    return (
        <>
            <ProductListItem type={'listing'}>
                <PublicProductItemContent>
                    <PublicProductSpecs
                        ref={PopupRef}
                        onClick={togglePopper}>
                        <PublicProductImageWrapper>
                            <ImageWithZoom
                                popupSize={
                                    type === "listing"
                                        ? isPhoneScreen
                                            ? { width: "100%", height: "100%" }
                                            : { width: "500px", height: "500px" }
                                        : { width: "380px", height: "380px" }
                                }
                                src={
                                    image
                                        ? `${getImageBaseUrl()}${image}`
                                        : "/product-dot.svg"
                                }
                                imgSize={image && { imgWidth: "auto", imgHeight: "50px", width: "auto" }}
                            />
                        </PublicProductImageWrapper>
                        <PublicProductSpec>{autokada_code || akCode}</PublicProductSpec>
                        <PublicProductSpec>
                            {(vendor_image) ?
                                <img src={vendor_image} alt={name || vendor} style={{ maxWidth: "60px", maxHeight: "30px" }} /> :
                                name || vendor
                            }
                        </PublicProductSpec>
                        <PublicProductSpec>{title}</PublicProductSpec>
                        <PublicProductSpec>
                            <Box
                                flexDirection="row"
                                alignItems="center"
                                justifyContent={
                                    isPhoneScreen ? "flex-start" : "center"
                                }
                                color={stock_icon}
                            >
                                <CheckCircle id={data.id} />
                            </Box>
                        </PublicProductSpec>
                    </PublicProductSpecs>
                </PublicProductItemContent>
            </ProductListItem>
            <Popper
                visibility={popperVisibility}
                anchorEl={togglePopper?.current}
                onClickAway={closePopper}
            >
                <PopoutFlyoutWrapper>
                    <PopoutFlyoutCloseButton>
                        <Close cursor="pointer" onClick={closePopper} />
                    </PopoutFlyoutCloseButton>
                    <PopoutFlyoutContent>
                        {t("productsList.publicFlyout.text")}
                        {" "}
                    </PopoutFlyoutContent>
                    <PopoutFlyoutContent
                        onClick={handleLoginClick}
                        style={{ color: color.colors.orange }}
                    >
                        <PopoutFlyoutLoginButton>
                            {t("productsList.publicFlyout.link")}
                        </PopoutFlyoutLoginButton>
                    </PopoutFlyoutContent>
                </PopoutFlyoutWrapper>
            </Popper>
        </>
    );
}
