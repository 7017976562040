import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";

import styled, { css } from "styled-components";
import { breakpoints } from "../../../../core/utils/style";
import { HeaderWrapper } from "../../../ModalHeader/style";
import { ModalBody } from "../../../ModalBody";
import { BodyWrapper } from "../../../ModalBody/style";

export const ModalContent = styled.div(
    ({ isPhoneScreen }) => css`
        background: ${isPhoneScreen ? "#F2F2F2" : "#fff"};
        border-radius: 6px;
        width: 60%;
        max-height: 500px;
        overflow-y: scroll;
        top: 23.5%;
        
        position: absolute;
        left: 20%;
        ${!isPhoneScreen &&
        css`
            min-width: 850px;
        `}
        ${isPhoneScreen &&
        css`
            box-shadow: inset 0 0 9px -9px rgb(0 0 0 / 10%),
                inset 0px 9px 10px -6px rgb(0 0 0 / 20%);
        `}
        
        @media (max-width: ${breakpoints.md}) {
            transform: none;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            max-height: none;
            border-radius: 0;

            ${HeaderWrapper} {
                padding: 12px 12px 0;
            }

            ${BodyWrapper} {
                padding: 12px;
            }
        }
    `
);

export const QuantityInputWrapper = styled(Box)`
    width: 110px;
    margin-left: 16px;

    input {
        width: 88px;
    }
`;

export const StyledModalBody = styled(ModalBody)`
    flex-direction: column;
`;

export const StyledTabs = styled(Tabs)`
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;

    button.MuiTab-root {
        flex: 1;
        max-width: none;
    }

    button.Mui-selected {
        span {
            color: #37474f;
        }
    }

    button:not(.Mui-selected) {
        span {
            color: #bdbdbd;
        }
    }

    .MuiTabs-indicator {
        background-color: #37474f;
    }

    @media (max-width: ${breakpoints.md}) {
        min-width: 580px;
        button.MuiTab-root {
            flex: 0 1 auto;
        }
    }
`;
