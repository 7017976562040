import { Box } from "@material-ui/core";
import styled from "styled-components";
import Typography from "../../../Typography";
import { CartButton } from "../CartButton";

export const ItemCardWrapper = styled.div`
    padding: 16px 12px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    background: ${({ autoDelivery }) => (autoDelivery ? "#fce2c7" : "#ffffff")};
    display: flex;
    flex-direction: column;
    border-radius: 2px;
`;

export const ItemSpec = styled(Typography).attrs({
    customVariant: "captionSlim",
})`
    && {
        text-align: left;
    }
    svg {
        width: 13px;
        height: 13px;
    }
`;

export const SlimCartButton = styled(CartButton)`
    width: 60px;
    height: 28px;
`;

export const TitleWrapper = styled(Typography).attrs({
    color: "#37474F",
    customVariant: "body",
    textAlign: "left",
    cursor: "Pointer",
})`
    max-width: 200px;
    word-break: break-word;
`;

export const CarouselWrapper = styled(Box)`
    width: 60px;

    img {
        width: 60px;
        height: 60px;
    }

    div[class*="Carousel-prev"] {
        width: 10px;
        &:hover {
            button {
                background-color: initial;
                opacity: 1;
            }
        }

        button {
            left: -26px;
            top: -6px;
        }
    }

    svg {
        width: 20px;
    }
    div[class*="Carousel-next"] {
        width: 20px;
        &:hover {
            button {
                background-color: initial;
                opacity: 1;
            }
        }

        button {
            right: 15px;
            top: -6px;
        }
    }
`;
