import Typography from "@material-ui/core/Typography";
import styled, { css } from "styled-components";

// Props that should not be forwarded to the HTML DOM elements
export const shouldForwardProp = (prop) =>
    ![
        "color",
        "customVariant",
        "marginLeft",
        "marginRight",
        "marginBottom",
        "marginTop",
        "textAlign",
        "fontWeight",
    ].includes(prop);

export default styled(Typography).withConfig({ shouldForwardProp })(
    ({
        theme,
        customVariant = "bodySlim",
        color,
        textAlign = "center",
        textDecoration = "none",
        marginLeft,
        marginRight,
        marginBottom,
        marginTop,
        fontWeight,
        texttransform = "initial",
        cursor = "auto",
    }) => css`
        && {
            font-size: ${theme.typography[customVariant].fontSize};
            line-height: ${theme.typography[customVariant].lineHeight};
            font-weight: ${fontWeight ||
            theme.typography[customVariant].fontWeight};
            color: ${theme.colors.font[color] ||
            color ||
            theme.colors.font.base};
            text-align: ${textAlign};
            text-decoration: ${textDecoration};
            margin-left: ${theme.spaces[marginLeft] || marginLeft};
            margin-right: ${theme.spaces[marginRight] || marginRight};
            margin-bottom: ${theme.spaces[marginBottom] || marginBottom};
            margin-top: ${theme.spaces[marginTop] || marginTop};
            text-transform: ${texttransform};
            cursor: ${cursor};
        }
    `
);
