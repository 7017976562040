import React from "react";
import { ImageFlyoutWrapper } from "./style";

export const ImageFlyout = React.forwardRef(({ src, size }, ref) => {
    return (
        <ImageFlyoutWrapper ref={ref} {...size}>
            <img src={src} alt="product with zoom" loading="lazy" />
        </ImageFlyoutWrapper>
    );
});
