import styled, { css } from "styled-components";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { breakpoints } from "../../core/utils/style";
import { theme } from "../../core/styles/theme";

const activeArrow = css`
    cursor: pointer;
    color: ${theme.colors.orange};
    &:hover {
        borderRadius: 50%;
        transform: scale(1.1);
    }
    @media (min-width: ${breakpoints.sm}) {
        margin: 0 ${theme.spaces.m};
    }
`
const disabledArrow = css`
    cursor: default;
    color: ${theme.colors.grey}; 
    &:hover {
        borderRadius: 50%;
    }
    @media (min-width: ${breakpoints.sm}) {
        margin: 0 ${theme.spaces.m};
    }
`

export const LeftArrow = styled(ArrowBackRoundedIcon)`
    ${({ type }) =>
    type === "activeArrow" ?
    activeArrow
    : type === "disabledArrow" &&
    disabledArrow
    }
`;

export const RightArrow = styled(ArrowForwardRoundedIcon)`
    ${({ type }) =>
    type === "activeArrow" ?
    activeArrow
    : type === "disabledArrow" &&
    disabledArrow
    }
`;