import { Switch, Route} from "react-router-dom";
import routes from "./core/routes";
import Notification from "./components/Notification";
import { theme } from "./core/styles/theme";
import { ThemeProvider } from "styled-components";
import { AuthRoute } from "./pages/Login/components/AuthRoute";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./core/redux/asyncActions/global";
import { getAuthState } from "./core/api/authHelper";
import withTourController from "./components/Tour";
import React from "react";
import ClearPage from "./pages/ClearPage";

function App() {
    const dispatch = useDispatch();
    const userAuthenticatedStatus = useSelector(
        (state) => state.global.userAuthenticated
    );
    const authState = getAuthState();
    const userAuthenticated = userAuthenticatedStatus && authState?.accessToken;
    const isUserDataFetched = useSelector((state) => !!state.global.userData);

    if (userAuthenticated && !isUserDataFetched) {
        // fetching mandatory data for the app
        dispatch(fetchUserData());
    }

    return (
            <div className="App">
                <ThemeProvider theme={theme}>
                    <Switch>
                        <Route key="/c" path="/c" component={ClearPage} />
                        {routes.map((route) => {
                            const { path, Component, type } = route;
                            return (
                                <AuthRoute
                                    key={path}
                                    path={path}
                                    component={Component}
                                    type={type}
                                />
                            );
                        })}
                    </Switch>
                    <Notification />
                </ThemeProvider>
            </div>
    );
}

export default withTourController(React.memo(App));
