import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { TooltipBackground, StyledMUIPopper } from "./style";

export const Popper = ({
    id,
    children,
    visibility = false,
    anchorEl,
    onClickAway,
    ignoreBackground,
    ...otherPopperProps
}) => {
    const theme = useTheme();
    const phoneMatch = useMediaQuery(theme.breakpoints.down("sm"));

    const onTooltipClick = (e) => {
        e?.stopPropagation();
        onClickAway();
    };

    return (
        <>
            <TooltipBackground
                visibility={visibility && Boolean(!ignoreBackground)}
                onClick={onTooltipClick}
            />
            <StyledMUIPopper
                isphone={phoneMatch && Boolean(!ignoreBackground)}
                id={id}
                placement="left"
                open={visibility}
                anchorEl={anchorEl}
                modifiers={{
                    preventOverflow: {
                        boundariesElement: "viewport"
                    }
                }}
                {...otherPopperProps}
            >
                <ClickAwayListener onClickAway={onClickAway}>
                    {children}
                </ClickAwayListener>
            </StyledMUIPopper>
        </>
    );
};
