import React from "react";
import styled from "styled-components";
import Button from "./Button";
import { useTranslation } from "react-i18next";

const PopupContainer = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    text-align: center;
`;

const PopupText = styled.p`
    margin-bottom: 20px;
`;

const ConfirmationPopup = ({
    handleConfirmEmptyCartButton,
    handleCancelEmptyCartButton,
    popupText
}) => {
    const { t } = useTranslation();

    return (
        <PopupContainer>
            <PopupText>{popupText}</PopupText>
            <Button onClick={handleConfirmEmptyCartButton} buttonVariant="Filled" style={{ height: "44px"}}>
                {t("header.confirm").toUpperCase()}
            </Button>
            <Button onClick={handleCancelEmptyCartButton} buttonVariant="Light" style={{ height: "44px", marginTop: "8px"}}>
                {t("header.cancel").toUpperCase()}
            </Button>
        </PopupContainer>
    );
};

export default ConfirmationPopup;
