import React from "react";
import Typography from "../../components/Typography";
import { saveAuthState } from "../../core/api/authHelper";
import { saveState } from "../../core/utils";


const ClearPage = () => {
    window.localStorage.clear();
    window.localStorage.removeItem('autokada_state');
    saveAuthState({});
    saveState({});

    setTimeout(() => {
        window.location = '/'
    }, 2000);

    return (
        <Typography customVariant="displayS2" marginBottom="l">
            Storage deleted, redirecting..
        </Typography>
    );
};

export default ClearPage;
