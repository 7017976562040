import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "../reducers";

import * as config from "../../config/global-config";

import { loadState, saveState, throttle } from "../../utils/index";

let persistedState = loadState();

if (!persistedState || !persistedState.global?.version) {
    persistedState = undefined;
} else if (persistedState.global?.version !== config.storage_version) {
    persistedState = undefined;
}

const middlewares = [];
let logger;

middlewares.push(thunk);

if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require(`redux-logger`);
    logger = createLogger({
        collapsed: true,
    });
    middlewares.push(logger);
}

const store = createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(...middlewares))
);

store.subscribe(
    throttle(
        () => {
            saveState(store.getState());
        },
        500,
        { leading: false, trailing: true }
    )
);

export default store;
