import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { COUNTRY_CURRENCY, getImageBaseUrl } from "../../../../core/utils";
import { CartButtonSizes } from "../CartButton";
import { Price } from "../Price";
import { Stock } from "../Stock";
import { Table } from "../TableProducts";
import { ImageWithZoom } from "../ImageWithZoom";
import QuantityButton from "../QuantityButton";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const ProductsList = ({ showTourTooltip, products, general = true, type }) => {
    const { scope: currentCountry } = useSelector(
        (state) => state.global.userData || {}
    );
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { t } = useTranslation();

    const columns = useMemo(
        () => [
            general && {
                id: "img",
                valueGetter: "image",
                renderer: ({ value: url }) => (
                    <ImageWithZoom
                        popupSize={
                            type === "listing"
                                ? isMobileScreen
                                    ? { width: "100%", height: "100%" }
                                    : { width: "400px", height: "400px" }
                                : { width: "380px", height: "380px" }
                        }
                        src={
                            url
                                ? `${getImageBaseUrl()}${url}`
                                : "/product-dot.svg"
                        }
                        imgSize={url && { imgWidth: "auto", imgHeight: "50px", width: "auto" }}
                    />
                ),
            },
            {
                id: "akCode",
                label: t("productsList.tableHeader.AKCode").toUpperCase(),
                valueGetter: "ak_code",
            },
            {
                id: "oemCode",
                label: t("productsList.tableHeader.oem").toUpperCase(),
                valueGetter: "oem_code",
            },
            {
                id: "vendor",
                label: t("productsList.tableHeader.vendor").toUpperCase(),
                valueGetter: (rowData) => rowData,
                renderer: ({ value }) => (
                    <>
                        {(value?.vendor_image) ?
                            <img src={value?.vendor_image} alt={value?.vendor} style={{ maxWidth: "60px", maxHeight: "30px" }} id={value?.id} /> :
                            value?.vendor
                        }
                    </>
                ),
            },
            general && {
                id: "product",
                label: t("productsList.tableHeader.product").toUpperCase(),
                valueGetter: "description",
            },
            {
                id: "stock",
                label: t("productsList.tableHeader.stock").toUpperCase(),
                valueGetter: "stock",
                renderer: ({ value, rowData }) => (
                    <>
                        {value?.total &&
                            <Stock
                                stock={value}
                                id={rowData.id}
                                marginTop={20}
                            />}
                    </>
                ),
            },
            {
                id: "price",
                label: `${t("productsList.tableHeader.price").toUpperCase()}, ${COUNTRY_CURRENCY[currentCountry]
                    }`,
                valueGetter: "prices",
                renderer: ({ value, units }) => (
                    <Price
                        price={value}
                        color="#37474F"
                        customVariant="bodySlim"
                        style={{ cursor: "pointer" }}
                        productUnits={units}
                    />
                ),
            },
            {
                id: "quantity",
                valueGetter: (rowData) => rowData,
                renderer: ({ rowData, rowIndex, unitsCount }) => (
                    <>
                        <QuantityButton
                            showTourTooltip={showTourTooltip && rowIndex === 0}
                            data={rowData}
                            cartSize={CartButtonSizes.Small}
                            productUnitsCount={unitsCount}
                            itemAkCode={rowData.ak_code}
                            itemNo={rowIndex}
                        />
                    </>
                )
            },
        ],
        [currentCountry, t, general]
    );

    return (
        <Table
            items={products}
            columns={columns}
            type={type}
        />
    );
};
