import styled, { css } from "styled-components";
import { breakpoints } from "../../../../core/utils/style";

export const MiniMessageWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background: white;
    max-height: 250px;
    width: 500px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25),
        0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 0px 10px 35px 10px;
 
    @media (max-width: ${breakpoints.sm}) {
        max-height: 120px;
        width: 100%;
    }
`;

export const MessageText = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: ${({ fontColor }) => fontColor || "#000"};
    text-align: center;
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 5;
    pointer-events: ${props => props.show ? 'auto' : 'none'};
`;