import styled from "styled-components";

export const BackButton = styled.div`
    cursor: pointer;
    color: #99A1A5;
    display: flex;
    flex-wrap: no-wrap;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    flex-direction: row;
    align-items: center;
    width: max-content;

    &:hover {
        color: #f28a22;
        svg {
            color: #f28a22;
        }
    }
    svg {
        color: #99A1A5;
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
`;