import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Logo } from "../../components/Logo";
import Typography from "../../components/Typography";
import { PrivacyPolicyCheckbox } from "./components/PrivacyPolicyCheckbox";
import { LANGUAGES } from "../../core/translation/languages";
import Input from "../../components/Input";
import { PasswordInput } from "../../components/PasswordInput";
import Button from "../../components/Button";
import Link, { LinkAsButton } from "../../components/Link";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../core/redux/actions/global";
import { useTranslation } from "react-i18next";
import { login } from "../../core/redux/asyncActions/login";
import { LOGIN_FAILURE } from "../../core/redux/actionTypes/login";
import { setLoggingIn } from "../../core/redux/asyncActions/global";
import {
    PASSWORD_RESET_BASE,
    PRIVACY_POLICY,
    REGISTER_PATH,
} from "../../core/routes";
import { Box, FitBox } from "../../components/Box";
import {
    FieldsContainer,
    StyledLoginPage,
    LogoContainer,
    FormContainer,
    StyledLinearProgress,
    StyledSelect
} from "./style";
import { useTheme } from "styled-components";

const Login = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLoggingIn(false));
    }, [dispatch]);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [ppChecked, setPPChecked] = useState(false);
    const theme = useTheme();
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
    const languageId = useSelector((state) => state.global.lang);
    const loggingIn = useSelector((state) => state.global.loggingIn);
    const loginError = useSelector((state) => state.global.loginError);
    const { t } = useTranslation();
    const handleLanguageChange = ({ target: { value: newSelectedLang } }) => {
        dispatch(changeLanguage(newSelectedLang));
    };
    const handleUsernameChange = ({ target: { value } }) => {
        setErrorMessageVisible(false);
        setUsername(value);
    };
    const handlePasswordChange = ({ target: { value } }) => {
        setErrorMessageVisible(false);
        setPassword(value);
    };
    const handleLogin = async () => {
        const { type } = await dispatch(login(username, password));
        if (type === LOGIN_FAILURE) {
            setErrorMessageVisible(true);
        }
    };
    const handlePPCheckboxChange = ({ target: { checked } }) => {
        setPPChecked(checked);
    };

    return (
        <StyledLoginPage>
            <FormContainer>
                <LogoContainer>
                    <Logo />
                </LogoContainer>
                <Box justifyContent="space-between" alignItems="flex-end">
                    <Typography customVariant="displayS2">
                        {t("login.title")}
                    </Typography>
                    <StyledSelect
                        variant="standard"
                        value={languageId}
                        onChange={handleLanguageChange}
                    >
                        {Object.values(LANGUAGES).map((lang) => (
                            <MenuItem value={lang.id} key={lang.id}>
                                {lang.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </Box>
                <Box flexDirection="column" mb="-10px">
                    <Box flexWrap="wrap">
                        <Typography
                            customVariant="captionSlim"
                            textAlign="left"
                        >
                            {t("login.privacyPolicyEndorsement")}        
                        </Typography>
                            <Link
                                ml="2px"
                                to={PRIVACY_POLICY}
                                fontSize={theme.typography.captionSlim.fontSize}
                                lineHeight={
                                    theme.typography.captionSlim.lineHeight
                                }
                                fontWeight={
                                    theme.typography.captionSlim.fontWeight
                                }
                                underlineOnHover
                            >
                                {t("login.privacyPolicy").toLowerCase()}.
                            </Link>
                    </Box>

                    <PrivacyPolicyCheckbox
                        onChange={handlePPCheckboxChange}
                        checked={ppChecked}
                        disabled={loggingIn}
                    />
                </Box>
                <FieldsContainer flexDirection="column" py="xl" px="m">
                    {loggingIn && <StyledLinearProgress />}
                    <Input
                        placeholder={t("login.clientId")}
                        value={username}
                        onChange={handleUsernameChange}
                        onEnter={handleLogin}
                        name="user"
                        id="user"
                        autoComplete="username"
                        disabled={!ppChecked || loggingIn}
                    ></Input>
                    <PasswordInput
                        id="password"
                        name="password"
                        autoComplete="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder={t("login.password")}
                        onEnter={handleLogin}
                        disabled={!ppChecked || loggingIn}
                    ></PasswordInput>
                    <Button
                        height="45px"
                        disableElevation
                        onClick={handleLogin}
                        disabled={!ppChecked || loggingIn}
                    >
                        {t("login.login")}
                    </Button>
                    {errorMessageVisible && (
                        <Typography color="error" customVariant="caption">
                             {loginError?.msg === "not_allowed_ip" ? t("login.errorMessageIP") + " (" + loginError?.ip + ")" : t("login.errorMessageCredentials")}
                        </Typography>
                    )}
                    <FitBox ml="auto" mr="auto">
                        <Link to={PASSWORD_RESET_BASE}>
                            {t("login.forgotPassword")}
                        </Link>
                    </FitBox>
                </FieldsContainer>
                <Box px="m" height="45px">
                    <LinkAsButton to={REGISTER_PATH} variantOutlined>
                        {t("login.companyRegistration")}
                    </LinkAsButton>
                </Box>
                <Box justifyContent="center" alignItems="center" mt="20px">
                    <Link to={PRIVACY_POLICY}>{t("login.privacyPolicy")}</Link>
                </Box>
                <Box justifyContent="center">
                    <Typography color="lighter">{t("login.rights")}</Typography>
                </Box>
            </FormContainer>
        </StyledLoginPage>
    );
};

export default Login;