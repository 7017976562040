import { Link } from "react-router-dom";
import styled from "styled-components";

export const LogoWrapper = styled(Link)`
`;

export const StyledLogo = styled.img`
    display: block;
    width: 100%;
`;
