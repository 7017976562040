import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { MobileItemVehicle } from "./components/MobileVehicleList";

import { VehiclesList } from "./components/VehiclesList";
import { StyledCircularProgress } from "../../../../../CircularProgress";

const LinkedVehicles = ({
    linkedVehicles = [],
    isLoadingVehicles,
    isPhoneScreen,
}) => {
    return (
        <Box
            minHeight="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {isLoadingVehicles && (
                <Box justifyContent="center" my="xl">
                    <StyledCircularProgress disableShrink/>
                </Box>
            )}
            {!isLoadingVehicles &&
                (isPhoneScreen ? (
                    <Grid container spacing={2} direction="column">
                        {Boolean(linkedVehicles.length) &&
                            linkedVehicles.map((vehicle) => (
                                <Grid
                                    item
                                    key={`linked-vehicles-${vehicle.id}`}
                                >
                                    <MobileItemVehicle data={vehicle} />
                                </Grid>
                            ))}
                    </Grid>
                ) : (
                    Boolean(linkedVehicles.length) && (
                        <VehiclesList vehicles={linkedVehicles} />
                    )
                ))}
        </Box>
    );
};

export default memo(LinkedVehicles);
