import axios from "axios";
import resources from "../api/resources";
import settings from "../../settings.json";
import { getAuthState, saveAuthState } from "./authHelper";
import { saveState } from "../utils";
import { DEFAULT_PATH } from "../routes/index";

const currentEnv = process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV.trim()
    : "dev";
const axiosInstance = axios.create({
    baseURL: settings[currentEnv].baseUrl,
    responseType: "json",
    headers: {
        "Content-Type": "application/json",
    },
});

const isRefreshTokenExpired = (error) => {
    const requestBody = error.response.config.data;

    let parsedRequestBody = undefined;
    try {
        let parsedRequestBody = requestBody && JSON.parse(requestBody);
    } catch (e) {

    }
    const refreshToken = parsedRequestBody && parsedRequestBody.refresh;
    const isRefreshTokenRequest =
        error.response.config.url === resources.login.refreshToken;
    if (isRefreshTokenRequest && typeof (parsedRequestBody) == 'undefined') {
        return true;
    }
    return (
        isRefreshTokenRequest && error.response.status === 401 && !!refreshToken
    );
};

// request interceptor to add the auth token header to requests
axiosInstance.interceptors.request.use(
    (config) => {
        const persistedAppAuthState = getAuthState();
        const accessToken =
            persistedAppAuthState && persistedAppAuthState.accessToken;
        if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
// response interceptor to refresh token on receiving token expired error
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        if (isRefreshTokenExpired(error)) {
            // logout
            saveAuthState({});
            saveState({});
            window.location.pathname = DEFAULT_PATH;
            return Promise.reject(error);
        }
        const originalRequest = error.config;
        originalRequest._retryCount = originalRequest._retryCount || 0;
        const persistedAppAuthState = getAuthState();
        const refreshToken =
            persistedAppAuthState && persistedAppAuthState.refreshToken;
        if (
            refreshToken &&
            error.response.status === 401 &&
            originalRequest._retryCount <= 3
        ) {
            originalRequest._retryCount++;
            const res = await axiosInstance.post(resources.login.refreshToken, {
                refresh: refreshToken,
            });
            if (res.status === 200) {
                saveAuthState({
                    ...persistedAppAuthState,
                    accessToken: res.data.access,
                });
                return axiosInstance.request(originalRequest);
            }
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
