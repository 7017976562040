import styled from "styled-components";

export const PriceFlyoutWrapper = styled.div`
    background: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 10px 20px;
    width: 125px;
    display: flex;
    flex-wrap: wrap;
`;

export const PriceLine = styled.div`
    align-items: center;
    display: flex;
    height: 20px;
    width: 100%;
    justify-content: space-between;
`;