import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Modal from "@material-ui/core/Modal";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ModalContent } from "../../Listing/components/ItemDetailsModal/style";
import { ModalHeader } from "../../ModalHeader";
import Button from "../../Button";
import Typography from "../../Typography";
import {
    SearchBoxWrapper,
    SearchCategoryButton,
    UserMenuSelectLabel,
    SearchCategoryInput
} from "../style";
import { Table } from "./ListVehicles";
import { StyledCircularProgress } from "../../CircularProgress";
import { fetchYqParams, fetchYqListVehicles, fetchYqFindVehicle } from "../../../core/redux/asyncActions/global";
import { MultipleAutocomplete } from "../../MultipleAutocomplete";
import { SelectFormControl } from "../../Drawer/style";
import { SearchContainer } from "./style";

export const ByManufacturer = ({
    onClose,
    isManufacturerSearch
}) => {
    const [localSearchQuery, setLocalSearchQuery] = useState("");
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();
    const [manufacturer, setManufacturer] = useState(null);
    const [lastChoice, setLastChoice] = useState(null);
    const [search, setSearch] = useState(false);
    const [searchList, setSearchList] = useState(false);
    const [listVehicles, setListVehicles] = useState([]);
    const [errorList, setErrorList] = useState(false);
    const [errorFind, setErrorFind] = useState(false);
    const [open, setOpen] = useState(false);
    const bottonStyle = "Filled";

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
    });

    const {
        yqList,
        yqParams,
        yqListVehicles,
        yqFindVehicle
    } = useSelector((state) => state.global);

    const handleSearch = async () => {
        await dispatch(fetchYqFindVehicle(localSearchQuery, manufacturer?.code))
        setSearch(true);
        setSearchList(false);
    };


    const handleListVehicles = async () => {
        if (lastChoice) {
            await dispatch(fetchYqListVehicles(manufacturer?.code, lastChoice));
            setSearch(true);
            setSearchList(true);
        }
    };

    useEffect(() => {
        if (isManufacturerSearch) { setOpen(true) }
        else { setOpen(false) }
    }, [isManufacturerSearch])

    useEffect(() => {
        if (yqListVehicles?.error) setErrorList(true)
        if (!yqListVehicles?.error) setErrorList(false)
        if (yqFindVehicle?.error) setErrorFind(true)
        if (!yqFindVehicle?.error) setErrorFind(false)
    }, [yqListVehicles?.error, yqFindVehicle?.error])

    const handleSearchKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const handleBrand = async (e, manufacturer) => {
        if (manufacturer?.parameteridentification) {
            await dispatch(fetchYqParams(manufacturer?.code));
        }
        if (!yqParams?.isLoading) {
            setManufacturer(manufacturer);
        }
        setLocalSearchQuery("");
        setListVehicles([]);
        setErrorFind(false);
        setErrorList(false);
    }

    const onBack = () => {
        if (search) {
            setSearch(false)
        } else {
            setLocalSearchQuery("")
            setListVehicles([]);
            setManufacturer(null);
        }
    }

    const handleChoice = (e, selectedOptions) => {
        dispatch(fetchYqParams(manufacturer?.code, selectedOptions?.key));
        setLastChoice(selectedOptions?.key);
    };


    const handleClear = (e, ssd) => {
        dispatch(fetchYqParams(manufacturer?.code, ssd));
        setLastChoice(ssd);
    };

    useEffect(() => {
        setListVehicles([])
        let tempGroup = [];
        let tempObj = {};
        if (yqListVehicles?.results !== undefined) {
            for (let i = 0; i < yqListVehicles?.results.length; i++) {
                let title = yqListVehicles?.results[i].name;
                let brand = yqListVehicles?.results[i].brand;
                let catalog = yqListVehicles?.results[i].catalog;
                let ssd = yqListVehicles?.results[i]?.ssd;
                let vehicleid = yqListVehicles?.results[i]?.vehicleid;
                let attributes = yqListVehicles?.results[i]?.attributes;
                if (i === 0) {
                    tempObj = {
                        title: title,
                        brand: brand,
                        catalog: catalog,
                        items: [
                            {
                                ssd: ssd,
                                vehicleid: vehicleid,
                                attributes: attributes,
                            }
                        ]
                    }
                    tempGroup.push(tempObj)
                } else {
                    if (tempGroup.length > 0) {
                        var was = false;
                        for (let y = 0; y < tempGroup.length; y++) {
                            if (tempGroup[y].title === title) {
                                was = true;
                                tempGroup[y].items.push({
                                    ssd: ssd,
                                    vehicleid: vehicleid,
                                    attributes: attributes,
                                })
                            }
                        }
                        let tempObj2;
                        tempObj2 = {
                            title: title,
                            brand: brand,
                            catalog: catalog,
                            items: [
                                {
                                    ssd: ssd,
                                    vehicleid: vehicleid,
                                    attributes: attributes,
                                }
                            ]
                        };
                        if (!was) { tempGroup.push(tempObj2); }
                    }
                }
            }
            setListVehicles(tempGroup)
        }
    }, [yqListVehicles?.results]);

    const list = searchList ? listVehicles : yqFindVehicle?.results;

    const handleCloseModal = (choice) => {
        setOpen(choice);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="item-details-modal"
            aria-describedby="item-details-modal"
            disableEnforceFocus
            style={isMobileScreen && { margin: "56px 3%" }}
        >
            <ModalContent style={!isMobileScreen ? { maxHeight: "750px", height: "fit-content", minHeight: "400px", width: "950px", top: "12%", left: "25%", background: "#F4F4F4" } : { maxHeight: "100%", height: "fit-content" }} isPhoneScreen={isMobileScreen}>
                <ModalHeader onClose={onClose} onBack={onBack} showBackBtn={manufacturer ? true : false} />
                <Box style={{ height: "100%", width: "100%", display: "flex", justifyContent: "space-evenly", alignContent: "center", flexDirection: "column", padding: "20px" }}>
                    {(!manufacturer && !search) && (
                        <>
                            <Typography
                                color="rgba(0, 0, 0, 0.87)"
                                customVariant="body"
                                fontWeight={700}
                                style={{ fontSize: '26px', margin: "0 20px 20px", textAlign: "left" }}
                            >
                                {t('sideMenu.chooseAManufacturer')}:
                            </Typography>
                            {yqList?.isLoading ?
                                <Box style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                                    <StyledCircularProgress disableShrink/>
                                </Box>
                                : yqList?.results &&
                                <Grid container spacing={2} style={{ padding: isMobileScreen ? "15px" : "20px" }}>
                                    {yqList?.results.map((manufacturer, i) => (
                                        <Grid key={i} item md={4} style={{ display: "flex", justifyContent: "center" }}>
                                            <Button buttonVariant={bottonStyle} style={{ height: "50px" }} onClick={(e) => handleBrand(e, manufacturer)}>
                                                {manufacturer?.brand}
                                            </Button>
                                        </Grid>)
                                    )}
                                </Grid>}
                        </>)}
                    {(manufacturer && yqParams && !search) &&
                        <>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "30px", position: !isMobileScreen && "relative" }}>
                                <Typography
                                    color="rgba(0, 0, 0, 0.87)"
                                    customVariant="body"
                                    fontWeight={700}
                                    style={isMobileScreen ? { fontSize: '24px', paddingLeft: "5px" } : { fontSize: '26px' }}
                                >
                                    {manufacturer?.name}
                                </Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: (manufacturer?.parameteridentification) ? "space-between" : "center", height: "100%", flexDirection: isMobileScreen ? "column" : "row", alignItems: isMobileScreen && "center" }}>
                                {manufacturer?.vinsearch &&
                                    <SearchContainer style={{ justifyContent: "center" }} >
                                        <Box style={{
                                            padding: "25px 25px 20px",
                                        }}>
                                            <Typography
                                                color="rgba(0, 0, 0, 0.87)"
                                                customVariant="displayS"
                                            >
                                                {t('header.byVinFrame.title')}
                                            </Typography>
                                        </Box>
                                        <Box style={{
                                            padding: "30px 20px"
                                        }}>
                                            <Typography
                                                color="rgba(0, 0, 0, 0.87)"
                                                customVariant="captionSlim"
                                            >
                                                {t('header.byVinFrame.tip')}
                                            </Typography>
                                        </Box>
                                        <SearchBoxWrapper style={{ width: "85%", margin: 0, background: "rgba(232, 232, 232, 0.5)", height: "40px" }}>
                                            <SearchCategoryInput
                                                type="text"
                                                placeholder={t('header.byVinFrame.title')}
                                                value={localSearchQuery}
                                                onKeyDown={handleSearchKeyDown}
                                                onChange={({ target: { value } }) => setLocalSearchQuery(value)}
                                                onClick={(e) => (e.stopPropagation())}
                                            />
                                            <SearchCategoryButton>
                                                <SearchIcon onClick={handleSearch} />
                                            </SearchCategoryButton>
                                        </SearchBoxWrapper>
                                    </SearchContainer>}
                                {manufacturer?.parameteridentification &&
                                    <SearchContainer>
                                        <Box style={{
                                            padding: "25px 25px 20px",
                                        }}>
                                            <Typography
                                                color="rgba(0, 0, 0, 0.87)"
                                                customVariant="displayS"
                                            >
                                                {t('header.carByParameters.title')}
                                            </Typography>
                                        </Box>
                                        {yqParams?.results?.map((group) => (
                                            <Box key={group?.conditionid} style={{ margin: "5px 33px", color: "#F28A22", width: "85%" }}>
                                                <UserMenuSelectLabel>{group?.name}</UserMenuSelectLabel>
                                                <SelectFormControl type="noMargin" style={{ width: "100%", background: "rgba(232, 232, 232, 0.5)", height: "40px" }}>
                                                    <MultipleAutocomplete
                                                        key={group?.conditionid}
                                                        options={group?.options}
                                                        onChange={group?.ssd !== null ? (e) => handleClear(e, group?.ssd) : handleChoice}
                                                        multiple={false}
                                                        disabled={(group?.value && group?.ssd === null) ? true : false}
                                                        clearOnBlur={true}
                                                        filterOptions={filterOptions}
                                                        selectedOptions={{ value: group?.value, key: group?.ssd }}
                                                        autoHighlight
                                                        disableClearable={(group?.ssd === null) ? true : false}
                                                    />
                                                </SelectFormControl>
                                            </Box>
                                        ))}
                                        <Box style={{ margin: "20px 33px", width: "75%" }}>
                                            <Button buttonVariant={"Light"} style={{ height: "50px", width: "100%" }} onClick={handleListVehicles}>
                                                {t('header.carByParameters.listVehicles').toUpperCase()}
                                            </Button>
                                        </Box>

                                    </SearchContainer>
                                }
                            </Box>
                        </>}
                    {(search && manufacturer && (list !== undefined || yqFindVehicle)) &&
                        <>
                            {(errorList || errorFind || list === undefined) ?
                                <Typography
                                    color="error" customVariant="caption"
                                >
                                    {t('header.byVinFrame.errorVehicleSearch')}
                                </Typography>

                                :
                                ((yqFindVehicle?.isLoading || yqListVehicles?.isLoading || list?.isLoading) ?
                                    <Box style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                                        <StyledCircularProgress disableShrink/>
                                    </Box>
                                    :
                                    list.map((obj, i) => (
                                        <div key={i}>
                                            <Box style={{ display: "flex", justifyContent: "flex-start", margin: "20px 0" }}>
                                                <Typography
                                                    color="rgba(0, 0, 0, 0.87)"
                                                    customVariant="body"
                                                    fontWeight={700}
                                                    style={{ fontSize: '24px', padding: "0 5px" }}
                                                >
                                                    {obj?.brand} {obj?.title || obj?.name}
                                                </Typography>
                                            </Box>
                                            <Table
                                                items={obj?.items ? obj?.items : undefined}
                                                catalog={manufacturer?.code}
                                                attributes={obj?.attributes ? obj?.attributes : undefined}
                                                modalOpen={handleCloseModal}
                                                ssd={obj?.ssd ? obj?.ssd : undefined}
                                                vehicleid={obj?.vehicleid ? obj?.vehicleid : undefined}
                                                isMobileScreen={isMobileScreen}
                                            />
                                        </div>
                                    ))
                                )
                            }
                        </>}
                </Box>
            </ModalContent>
        </Modal >
    );
};
