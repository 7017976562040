import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_PATH } from "../../core/routes";
import TourPopup from "../Tour/TourPopup";
import { LogoWrapper, StyledLogo } from "./style";

export const Logo = ({ isMobileScreen, onClick }) => {
    const [logoRef, setLogoRef] = useState();

    const { t } = useTranslation();

    return (
        <>
            <LogoWrapper ref={ ref => setLogoRef(ref) } to={DEFAULT_PATH} onClick={ onClick }>
                <StyledLogo src="/logo_black.svg" alt="autokada"></StyledLogo>
            </LogoWrapper>
            { logoRef && (
                <TourPopup
                    step={ 2 }
                    placement={ isMobileScreen ? 'bottom-middle' : 'right' }
                    anchorEl={ logoRef }
                >
                    { t('appTour.logoButton') }
                </TourPopup>
            )}
        </>
    );
};
