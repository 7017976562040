export const ADD_CART = "ADD_CART";
export const ADD_ITEMS_TO_CART = "ADD_ITEMS_TO_CART";

export const FETCH_BASKET_REQUEST = "FETCH_BASKET_REQUEST";
export const FETCH_BASKET_SUCCESS = "FETCH_BASKET_SUCCESS";
export const FETCH_BASKET_FAILURE = "FETCH_BASKET_FAILURE";

export const ADD_ITEM_BASKET_REQUEST = "ADD_ITEM_BASKET_REQUEST";
export const ADD_ITEM_BASKET_SUCCESS = "ADD_ITEM_BASKET_SUCCESS";
export const ADD_ITEM_BASKET_FAILURE = "ADD_ITEM_BASKET_FAILURE";

export const FETCH_INFO_BASKET_REQUEST = "FETCH_INFO_BASKET_REQUEST";
export const FETCH_INFO_BASKET_SUCCESS = "FETCH_INFO_BASKET_SUCCESS";
export const FETCH_INFO_BASKET_FAILURE = "FETCH_INFO_BASKET_FAILURE";

export const ADD_BASKET_REQUEST = "ADD_BASKET_REQUEST";
export const ADD_BASKET_SUCCESS = "ADD_BASKET_SUCCESS";
export const ADD_BASKET_FAILURE = "ADD_BASKET_FAILURE";

export const ADD_DEFAULT_BASKET_REQUEST = "ADD_DEFAULT_BASKET_REQUEST";
export const ADD_DEFAULT_BASKET_SUCCESS = "ADD_DEFAULT_BASKET_SUCCESS";
export const ADD_DEFAULT_BASKET_FAILURE = "ADD_DEFAULT_BASKET_FAILURE";

export const DELETE_DEFAULT_BASKET_REQUEST = "ADD_DEFAULT_BASKET_REQUEST";
export const DELETE_DEFAULT_BASKET_SUCCESS = "ADD_DEFAULT_BASKET_SUCCESS";
export const DELETE_DEFAULT_BASKET_FAILURE = "ADD_DEFAULT_BASKET_FAILURE";

export const ORDER_INFO_REQUEST = "ORDER_INFO_REQUEST";
export const ORDER_INFO_SUCCESS = "ORDER_INFO_SUCCESS";
export const ORDER_INFO_FAILURE = "ORDER_INFO_FAILURE";

export const CHANGE_COUNT_ITEMS = "CHANGE_COUNT_ITEMS";

export const ORDER_SUBMIT_REQUEST = "ORDER_SUBMIT_REQUEST";
export const ORDER_SUBMIT_SUCCESS = "ORDER_SUBMIT_SUCCESS";
export const ORDER_SUBMIT_FAILURE = "ORDER_SUBMIT_FAILURE";

export const EMPTY_BASKET_REQUEST = "EMPTY_BASKET_REQUEST";
export const EMPTY_BASKET_SUCCESS = "EMPTY_BASKET_SUCCESS";
export const EMPTY_BASKET_FAILURE = "EMPTY_BASKET_FAILURE";

export const DELETE_BASKET_REQUEST = "DELETE_BASKET_REQUEST";
export const DELETE_BASKET_SUCCESS = "DELETE_BASKET_SUCCESS";
export const DELETE_BASKET_FAILURE = "DELETE_BASKET_FAILURE";

export const SET_SOURCE_LOCATION = "SET_SOURCE_LOCATION";