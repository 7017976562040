import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./core/translation/en";
import { lv } from "./core/translation/lv";
import { ru } from "./core/translation/ru";
import { lt } from "./core/translation/lt";
import { sv } from "./core/translation/sv";
import { nb } from "./core/translation/nb";
import { fi } from "./core/translation/fi";
import { et } from "./core/translation/et";
import { LANGUAGES } from "./core/translation/languages";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
const resources = {
    en,
    lv,
    ru,
    lt,
    sv,
    nb,
    fi,
    et,
};

i18n.use(LanguageDetector) // detect user language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: LANGUAGES["English"].id,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
