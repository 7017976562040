import Box from "@material-ui/core/Box";
import Carousel from "react-material-ui-carousel";
import styled from "styled-components";
import { breakpoints } from "../../../../../../core/utils/style";

export const StyledCarousel = styled(Carousel)`
    svg {
        color: #37474f;
    }

    cursor: pointer;
    button {
        background-color: inherit;
        padding: 0;
        margin: 0;
    }

    .MuiTouchRipple-root {
        display: none;
    }
    div[class*="Carousel-prev"] {
        width: 20px;
        &:hover {
            button {
                background-color: initial;
                opacity: 1;
            }
        }

        button {
            left: -6px;
            top: 5px;
        }
    }
    div[class*="Carousel-root"] {
        width: 200px;
        height: 200px;
    }
    div[class*="Carousel-next"] {
        width: 20px;
        right: 0;
        &:hover {
            button {
                background-color: initial;
                opacity: 1;
            }
        }

        button {
            right: -5px;
            top: -6px;
        }
    }
`;
export const QuantityInputWrapper = styled(Box)`
    width: 110px;
    height: 32px;
`;

export const MainBox = styled(Box)`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
`;

export const Trail = styled.div` 
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #37474F;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }

    @media (max-width: ${breakpoints.md}) {
        font-size: 10px;
        max-width: 32%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`