import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import debounce from 'lodash/debounce';
import {
    setSearchVisible,
    setSearchQuery,
    setSearchType,
    clearByCodeSearchList,
} from "../../../core/redux/actions/global";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { HOME_PATH, PUBLIC_SEARCH_PATH } from "../../../core/routes";
import Typography from "../../Typography";
import {
    SearchBoxWrapper,
    SearchCategoryButton,
    SearchCategoryFilter,
    SearchCategoryInput,
    BoxVariants,
    BoxVariantItem,
} from "../style";
import CartsItem from "./item";
import { fetchByCodeList, fetchYqCatalog } from "../../../core/redux/asyncActions/global";
import { StyledCircularProgress } from "../../CircularProgress";
import TourPopup from "../../Tour/TourPopup";
import { ByManufacturer } from "./ByManufacturer";

export const SEARCH_TYPE = {
    byCode: "by_code",
    byVehicle: "by_vehicle",
    byPlate: "by_plate",
    byManufacturer: "by_manufacturer"
};

export default function Search({ isMobileScreen, page }) {
    const searchRef = useRef();
    const searchBoxRef = useRef(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const {
        lang,
        country,
        byCodeSearchList,
        selectedItemCategory,
        selectedItemGroup,
        selectedItemModel,
        onlyInStock,
        selectedVendors,
        searchQuery,
        searchType,
        searchResetPage
    } = useSelector((state) => state.global);

    const vinSearchHistory = useSelector(
        (state) => state.global?.userData?.vin_search_history || []
    );
    const codeSearchHistory = useSelector(
        (state) => state.global?.userData?.code_search_history || []
    );

    const countryCodes = ["LV", "SE"];

    const isIncludesOemPath = window.location.pathname.includes("/oem");
    const isIncludesPlatePath = window.location.pathname.includes("/plate");
    const isVehicleSearch = searchType === SEARCH_TYPE.byVehicle;
    const isPlateSearch = searchType === SEARCH_TYPE.byPlate;
    const isManufacturerSearch = searchType === SEARCH_TYPE.byManufacturer;

    const searchHistory = isVehicleSearch ? vinSearchHistory : codeSearchHistory;

    const placeholder = isVehicleSearch ? t("header.searchByVehicle") : (isManufacturerSearch ? t("header.byManufacturer")
        : isPlateSearch ? t("header.searchByPlate") : t("header.searchByCode"));

    const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);
    const [isFocus, setFocusField] = useState(false);
    const [countryCode, setCountryCode] = useState("LV");

    const handleClick = (e) => {
        if (
            searchBoxRef.current &&
            !searchBoxRef.current.contains(e.target) &&
            e.target !== document.body
        ) {
            setFocusField(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    const handleSearch = (item = "") => {
        setFocusField(false);
        dispatch(setSearchQuery(item || localSearchQuery));
        if (page === "home") {
            if (isVehicleSearch && history.location.pathname !== isIncludesOemPath) {
                history.push(`/oem/${item || localSearchQuery}`);
            }
            if (searchType === SEARCH_TYPE.byCode && history.location.pathname !== HOME_PATH) {
                history.push(HOME_PATH);
            }
            if (searchType === SEARCH_TYPE.byPlate) {
                history.push(`/plate/${countryCode + localSearchQuery}`);
            }
        }
        if (page === "publicSearch") {
            if (history.location.pathname !== PUBLIC_SEARCH_PATH + "?search=" + localSearchQuery) {
                history.push(PUBLIC_SEARCH_PATH + "?search=" + localSearchQuery);
            }
        }
        if (isMobileScreen) {
            dispatch(setSearchVisible(false));
        }
    };

    const handleSearchKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };

    const fetchList = useRef(debounce((
        localSearchQuery,
        selectedItemCategory,
        selectedItemGroup,
        selectedItemModel,
        onlyInStock,
        selectedVendors) => {
        const fetchData = async (localSearchQuery) => {
            await dispatch(
                fetchByCodeList(
                    1,
                    selectedItemCategory?.cid,
                    selectedItemGroup?.gid,
                    selectedItemModel?.mid,
                    onlyInStock,
                    selectedVendors?.map((vendor) => vendor.id).join(","),
                    localSearchQuery
                )
            )
        }
        if (page !== "publicSearch") {
            (localSearchQuery?.length >= 1 ? fetchData(localSearchQuery) : dispatch(clearByCodeSearchList()))
        }
    }, 500)).current;

    useEffect(() => {
        if (!isVehicleSearch && !isManufacturerSearch && !isPlateSearch) {
            fetchList(
                localSearchQuery,
                selectedItemCategory,
                selectedItemGroup,
                selectedItemModel,
                onlyInStock,
                selectedVendors);
        }
    }, [
        lang,
        selectedItemCategory,
        selectedItemGroup,
        selectedItemModel,
        onlyInStock,
        selectedVendors.length,
        selectedVendors,
        localSearchQuery,
        page,
        searchQuery,
        fetchList,
        isVehicleSearch,
        isManufacturerSearch,
        country,
        isPlateSearch]);

    const handleSearchTypeChange = ({ target: { value } }) => {
        setLocalSearchQuery('');
        dispatch(setSearchType(value));
        if (value === SEARCH_TYPE.byManufacturer) {
            dispatch(fetchYqCatalog());
        }
    };

    const clearSearch = () => {
        setLocalSearchQuery('');
        dispatch(setSearchQuery(""));
        setFocusField(false);
        if (page === "home") {
            if (history.location.pathname !== HOME_PATH) {
                history.push(HOME_PATH);
            }
        }
        if (page === "publicSearch") {
            if (history.location.pathname !== PUBLIC_SEARCH_PATH + "?search=" + localSearchQuery) {
                history.push(PUBLIC_SEARCH_PATH + "?");
            }
        }
        if (isMobileScreen) {
            dispatch(setSearchVisible(false));
        }
    };

    var url_string = window.location.href;
    var url = new URL(url_string);
    var search = url.searchParams.get("search");

    useEffect(() => {
        if (!isIncludesOemPath && !isIncludesPlatePath) {
            dispatch(setSearchType(SEARCH_TYPE.byCode));
        }
        if (isIncludesPlatePath) {
            dispatch(setSearchType(SEARCH_TYPE.byPlate));
        }
        if (isIncludesOemPath) {
            dispatch(setSearchType(SEARCH_TYPE.byVehicle));
        }
        if (search !== null) {
            setLocalSearchQuery(search);
            dispatch(setSearchQuery(search));
            history.push(PUBLIC_SEARCH_PATH + "?search=" + search);
        }
    }, [dispatch, history, search, isIncludesPlatePath, isIncludesOemPath]);

    useEffect(() => {
        setLocalSearchQuery(searchQuery);
    }, [searchQuery, searchResetPage]);

    const onClickHistory = (item) => {
        setLocalSearchQuery(item);
        handleSearch(item);
    };

    return (
        <>
            <SearchBoxWrapper ref={searchRef} isMobileScreen={isMobileScreen}>
                {page === "home" &&
                    <>
                        <SearchCategoryFilter>
                            <FormControl>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={searchType}
                                    onChange={handleSearchTypeChange}
                                    style={{ color: "#000000" }}
                                >
                                    <MenuItem style={{ color: "#000000" }} value={SEARCH_TYPE.byCode}>
                                        {t("header.byCode")}
                                    </MenuItem>
                                    <MenuItem style={{ color: "#000000" }} value={SEARCH_TYPE.byVehicle}>
                                        {t("header.byVehicle")}
                                    </MenuItem>
                                    <MenuItem style={{ color: "#000000" }} value={SEARCH_TYPE.byPlate}>
                                        {t("header.byPlate")}
                                    </MenuItem>
                                    <MenuItem style={{ color: "#000000" }} value={SEARCH_TYPE.byManufacturer}>
                                        {t("header.byManufacturer")}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </SearchCategoryFilter>
                        {isPlateSearch &&
                            <SearchCategoryFilter style={{ margin: " 0 20px" }}>
                                <FormControl>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={countryCode}
                                        style={{ color: "#000000" }}
                                        onChange={({ target: { value } }) => setCountryCode(value)}
                                    > {countryCodes.map((code, i) => (
                                        <MenuItem key={i} style={{ color: "#000000" }} value={code}>
                                            {code}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </SearchCategoryFilter>}
                    </>
                }
                <SearchCategoryInput
                    type="text"
                    placeholder={placeholder}
                    value={localSearchQuery}
                    onKeyDown={handleSearchKeyDown}
                    onChange={({ target: { value } }) =>
                        setLocalSearchQuery(value)}
                    onFocus={() => setFocusField(true)}
                    onClick={(e) => (e.stopPropagation(), setFocusField(true))}
                />
                {(isFocus || localSearchQuery) && (
                    <SearchCategoryButton onClick={clearSearch}>
                        <CloseIcon />
                    </SearchCategoryButton>
                )}
                <SearchCategoryButton onClick={() => handleSearch("")}>
                    <SearchIcon />
                </SearchCategoryButton>
                {(isFocus && (Boolean(vinSearchHistory?.length) || Boolean(codeSearchHistory?.length)) && (isVehicleSearch || ((localSearchQuery.length === 0) && !isPlateSearch))) &&
                    <BoxVariants ref={searchBoxRef}>
                        <Box padding="14px">
                            <Typography
                                color="#37474F"
                                textAlign="left"
                                customVariant="subheadingSlim"
                                fontWeight={500}
                            >
                                {t("header.searchHistory")}
                            </Typography>
                        </Box>
                        {searchHistory.length > 0 &&
                            searchHistory.map((item, index) => (
                                <BoxVariantItem
                                    key={item + index}
                                    onClick={(e) => {
                                        onClickHistory(item);
                                    }}
                                >
                                    <Typography
                                        fontWeight={500}
                                        cursor="pointer"
                                        color="#37474F"
                                        textAlign="left"
                                        customVariant="subheadingSlim"
                                    >
                                        {item}
                                    </Typography>
                                </BoxVariantItem>))}
                    </BoxVariants>
                }
                {(!isVehicleSearch && !isManufacturerSearch && !isPlateSearch && isFocus && (localSearchQuery.length >= 1)) &&
                    ((byCodeSearchList?.isLoading) ?
                        <BoxVariants ref={searchBoxRef}>
                            <Box padding="14px" justifyContent="center" alignItems="center" width="100%" height="100%" display="flex">
                                <StyledCircularProgress disableShrink />
                            </Box>
                        </BoxVariants>
                        : byCodeSearchList?.count !== 0 &&
                        <BoxVariants ref={searchBoxRef}>
                            {byCodeSearchList?.results?.slice(0, 5).map((item, index) => (
                                <CartsItem key={index} data={item} />
                            )
                            )}
                        </BoxVariants>
                    )
                }
            </SearchBoxWrapper>
            <TourPopup
                step={1}
                anchorEl={searchRef.current}
                placement={isMobileScreen ? "bottom" : "right"}
            >
                {t('appTour.searchByCode')}
            </TourPopup>
            {/* <TourPopup
                step={ 6? }
                placement={ isMobileScreen ? "bottom" : "bottom" }
                anchorEl={ searchRef.current }
            >
                { t('appTour.searchByVin') }
            </TourPopup> */}
            <TourPopup
                step={13}
                placement={isMobileScreen ? "bottom" : "left-start"}
                anchorEl={searchRef.current}
            >
                {t('appTour.searchDone')}
            </TourPopup>
            {isManufacturerSearch && <ByManufacturer onClose={(e) => dispatch(setSearchType(SEARCH_TYPE.byCode))} isManufacturerSearch={isManufacturerSearch} />}
        </>
    );
}
