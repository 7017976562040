import styled from "styled-components";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { theme } from "../core/styles/theme";

export const StyledDatePicker = styled(KeyboardDatePicker)`
    width: 45%; 
    background: rgba(232, 232, 232, 0.5);
    height: 40px;
    color: orange;
    .MuiInput-underline::after {
        border-bottom: 1px solid ${theme.colors.orange};
    }
    .MuiFormLabel-root.Mui-focused {
        color: ${theme.colors.orange};
    }
    .MuiPickersToolbar-toolbar {
        background-color: ${theme.colors.orangeDark};
    }
    .MuiInputBase-input {
        padding: 12px;
    }
    .MuiSvgIcon-root {
        height: 20px;
        width: 20px;
    }
`;