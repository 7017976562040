import styled from "styled-components";
import Box from "@material-ui/core/Box";

import { breakpoints } from "../../core/utils/style";

export const Wrapper = styled.section`
    padding: 20px 16px;
    width: 100%;

    @media (min-width: ${breakpoints.md}) {
        padding: 32px 23px;
        width: calc(100vw - 332px); // drawer width
        min-height: calc(100vh - 64px); // header height
        overflow: auto;
        flex: 1 1 calc(100vw - 332px);
        display: flex;
        flex-direction: column;
    }
`;

export const BreadCrumbsWrapper = styled(Box)`
    margin-bottom: 24px;

    @media (max-width: ${breakpoints.sm}) {
        margin-bottom: 16px;
    }
`;

export const ProductsWrapper = styled(Box)`
    @media (max-width: ${breakpoints.sm}) {
        justify-content: space-between;
    }
`;

export const ProductListItem = styled(Box)`
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    min-height: 280px;
    height: fit-content;
    width: 183px;
    margin: 0 16px 16px 0;

    @media (max-width: ${breakpoints.sm}) {
        width: 159px;
        margin: 6px 6px 12px;
    }
`;

export const ImagePreview = styled.div`
    width: 184px;
    height: 184px;
    object-fit: cover;

    @media (max-width: ${breakpoints.md}) {
        width: 160px;
    }
`;

export const Title = styled.h3`
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 14px;
    text-transform: uppercase;

    @media (min-width: ${breakpoints.md}) {
        font-size: 25px;
    }
`;


export const Subtitle = styled.h4`
    color: #37474f;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 14px;
    text-transform: uppercase;
`;

export const BodyContent = styled.section`
    margin-top: 2%;
    display: flex;
    justify-content: center;
`;

export const Text = styled.div`
    padding: 8px;
`;
