import React from "react";
import { MiniMessageWrapper, MessageText } from "./style";
import { ModalHeader } from "../../../../components/ModalHeader/index";
// import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

export const MiniMessage = ({ message, fontColor, onClose }) => {
    return (
        <MiniMessageWrapper>
            <ModalHeader onClose={onClose} hideCloseBtn={false} />
            <MessageText fontColor={fontColor}>{message}</MessageText>
        </MiniMessageWrapper>
    );
};