import React from "react";
import { useTranslation } from "react-i18next";
import {
    StockKey,
    StockLine,
    StockValue,
    StockFlyoutWrapper,
    StockTitle,
    StockList,
    StockDot,
    DeliveryInfo,
} from "./style";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const StockFlyout = React.forwardRef(({ stock, isRowValue, showDot, showDetailedStock, salespersonShop, deliveryInfo, closePopper }, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const phoneMatch = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <StockFlyoutWrapper length={stock.length} ref={ref} detailedStock={showDetailedStock}>
            <StockTitle>{t("productsList.stockFlyout.title")}
            {phoneMatch && <CloseIcon onClick={closePopper}/>}</StockTitle>
            <StockList length={stock.length}>
                {stock &&
                    stock.length > 0 &&
                    stock.map((stockItem, index) => (
                        <StockLine key={index} salespersonShop={salespersonShop} stockItem={stockItem[0]}>
                            {isRowValue ? (
                                <StockKey>{stockItem.row_value || ''}</StockKey>
                            ) : (
                                <>
                                    {showDot && <StockDot type="flyout"/>} 
                                    <StockKey>{stockItem[0]}</StockKey>
                                    {stockItem[3] > 0 &&
                                    <StockValue value={stockItem[3]} type="first">
                                        (M:{stockItem[3]})
                                    </StockValue>
                                    }
                                    {stockItem[2] > 0 &&
                                    <StockValue value={stockItem[2]} type="third">
                                        ({stockItem[2]})
                                    </StockValue>
                                    }
                                    {showDetailedStock === false && stockItem[1] > 0 ? (
                                        <StockValue value={stockItem[1]} purchased={stockItem[2]}>
                                            {
                                                stockItem[1] <= 5 ? stockItem[1] :
                                                stockItem[1] > 5 && stockItem[1] <= 10 ? ">5" :
                                                stockItem[1] > 10 && stockItem[1] <= 100 ? ">10" :
                                                stockItem[1] > 100 ? ">100" :
                                                ""
                                            }
                                        </StockValue>
                                    ) : (
                                        <StockValue value={stockItem[1]} purchased={stockItem[2]}>{stockItem[1]}</StockValue>
                                    )}
                                </>
                            )}
                        </StockLine>
                    ))
                }
            </StockList>
            {deliveryInfo && <DeliveryInfo>
                {t("productsList.stockFlyout.deliveryInfo").split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </DeliveryInfo>}
        </StockFlyoutWrapper>
    );
});
