import * as registerActions from "../actions/register";
import api from "../../api";
import resources from "../../api/resources";
import Cookies from "js-cookie";

export const register = (payload) => {
    return async (dispatch) => {
        dispatch(registerActions.registerRequest());
        try {
            const token = Cookies.get("csrftoken");
            const { data } = await api.post(resources.register, {
                ...payload,
                register: "Register",
                csrfmiddlewaretoken: token,
            });
            return dispatch(registerActions.registerSuccess(data));
        } catch (error) {
            return dispatch(
                registerActions.registerFailure(error.response.data)
            );
        }
    };
};
