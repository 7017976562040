import styled, { css } from "styled-components";
import { CartButtonSizes } from ".";
import { theme } from "../../../../core/styles/theme";
import { breakpoints } from "../../../../core/utils/style";
import { CustomScrollArea } from "../../../Header/Carts/style";

export const CartFlyoutWrapper = styled.div`
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 24px;
    max-width: 340px;
`;

export const ChooseCartList = styled(CustomScrollArea)`
    max-height: 40.2338vh;
    overflow: auto;
    padding-top: 8px;

    @media (max-height: 666px) and (max-width: 320px) {
        max-height: 33.2338vh;
    }

    @media (min-width: ${breakpoints.md}) {
        max-height: 31.98vh;
        padding-top: 16px;
    }
`;

export const QuantityButton = styled.button(
    ({ size, disabled }) => css`
        background: ${disabled ? "#D7D7D7" : "#f28a22"};
        border-radius: 2px;
        color: white;
        height: 32px;
        line-height: 28px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
    
        @media (max-width: ${breakpoints.sm}) {
            height: 28px;
            padding: 5px 18px;
        }

        ${size === CartButtonSizes.Large &&
        css`
            padding: 12px 31px;
            height: 48px;
        `}

        &:hover {
            color: rgba(255, 255, 255, 0.7);
        }

        .MuiSvgIcon-root {
            fill: white;
            height: 18px;
        }
    `
);

export const QuantityButtonText = styled.span`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: white;
    white-space: nowrap;
`;

// TODO: instead of writing individual components for each label, create multiple variants of Typography and
// use <Typography/> cmp instead. This comment is applicable to all similar components
// Examples:
// <Typography customVariant="header" />
// <Typography customVariant="body" />
// <Typography customVariant="subheadingSlim" />
export const ChooseCartTitle = styled.h5`
    width: auto; 
    cursor: default;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    margin-bottom: 10px;
    color: #37474f;
`;

export const ChooseCartButton = styled.button`
    background:${theme.colors.orange};
    border-radius: 2px;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    margin-bottom: 20px;
    color: white;

    &:last-of-type {
        margin-bottom: 16px;
    }
    &:hover {
        background: ${theme.colors.orangeDark};
    }
`;
