import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

export const MultipleAutocomplete = ({
    placeholder,
    filterOptions,
    options,
    selectedOptions,
    onChange,
    onInputChange,
    multiple = true,
    disabled = false,
    style = false,
    clearOnBlur = false,
    disableClearable = false,
}) => {
    const defaultStyle = { width: "100%" };
    const { t } = useTranslation();
    return (
        <Autocomplete
            multiple={multiple}
            id="multiple-autocomplete"
            options={options}
            disableCloseOnSelect={false}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.text || option.value || option.id || ""}
            renderOption={(option) => (
                <React.Fragment>{option.text || option.ssd || option.value}</React.Fragment>
            )}
            getOptionSelected={(option, selectedOptions) => option.id === selectedOptions.id}
            disabled={disabled}
            onInputChange={onInputChange}
            value={selectedOptions}
            style={style ? style : defaultStyle}
            size="small"
            onChange={onChange}
            clearOnBlur={clearOnBlur}
            disableClearable={disableClearable}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={placeholder}
                    placeholder={placeholder}
                />
            )}
            noOptionsText={t("autocomplete.noOptions")}
            openText={t("autocomplete.open")}
            closeText={t("autocomplete.close")}
        />
    );
};
