import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "../../components/Typography";
import { PageContainer, DataContainer } from "./style";
import { en } from "../../core/translation/en";

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <PageContainer>
            <DataContainer>
                <Typography customVariant="displayS2" marginBottom="l">
                    {t("privacyPolicy.title")}
                </Typography>
                <ol>
                    {Object.keys(en.translation.privacyPolicy.bulletPoints).map(
                        (key) => (
                            <li key={key}>
                                <Typography customVariant="subheading">
                                    {t(`privacyPolicy.bulletPoints.${key}`)}
                                </Typography>
                            </li>
                        )
                    )}
                </ol>
            </DataContainer>
        </PageContainer>
    );
};

export default PrivacyPolicy;
