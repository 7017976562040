import styled, { css } from "styled-components";
import { breakpoints } from "../../core/utils/style";

export const Wrapper = styled.header`
    background: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 16px 0 17px;
    position: sticky;
    top: 0;
    z-index: 1000;

    svg {
        fill: var(--main-color);
    }

    @media (min-width: ${breakpoints.sm}) {
        padding: 0 27px 0 24px;
    }
    @media (min-width: ${breakpoints.md}) {
        height: 64px;
        position: relative;
    }
`;

export const MenuButton = styled.button`
    margin-right: 17px;

    @media (min-width: ${breakpoints.md}) {
        display: none;
    }
`;

export const LogoWrapper = styled.div`
    max-width: 36.94vw;
    cursor: pointer;

    @media (min-width: ${breakpoints.md}) {
        max-width: 212px;
    }
`;

export const LogoContainer = styled.div`
    max-width: 36.94vw;
    @media (min-width: ${breakpoints.md}) {
        max-width: 212px;
    }
`;

export const ButtonContainer = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: ${breakpoints.xs}) {
        margin-left: auto;
    }
`;

export const SearchButton = styled.button`
    margin-left: 1vw;
    position: relative;
    @media (min-width: ${breakpoints.md}) {
        display: none;
    }
    svg {
        fill: #000000;
    }
`;
export const HeaderButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5vw;
    position: relative;

    @media (min-width: ${breakpoints.md}) {
        background: rgba(224, 224, 224, 0.5);
        border-radius: 50%;
        margin-left: 10px;
        margin-right: 0;
        width: 40px;
        height: 40px;
    }

    svg {
        fill: #000000;
    }
    @media (max-width: ${breakpoints.sm}) {
        margin-left: 4.5vw;
    }
`;


export const DesktopHeaderSeparator = styled.span`
    display: none;
    width: 1px;
    height: 41px;
    margin: 11px;
    background: #e5e5e5;
    @media (min-width: ${breakpoints.md}) {
        display: inline-flex;
    }
`;

export const ProductQuantity = styled.span`
    background: #f28a22;
    display: flex;
    color: white;
    position: absolute;
    right: -8px;
    top: -8px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    font-weight: 500;
    font-size: 12px;
    height: 16px;
    @media (min-width: ${breakpoints.md}) {
        right: -2px;
        top: 0;
    }
`;

export const AvailableItemCount = styled.span`
    background: green;
    display: flex;
    color: white;
    position: absolute;
    left: -8px;
    top: -8px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    font-weight: 500;
    font-size: 12px;
    height: 16px;
    @media (min-width: ${breakpoints.md}) {
        left: -2px;
        top: 0;
    }
`;

export const UserButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4.5vw;

    @media (min-width: ${breakpoints.md}) {
        background: rgba(224, 224, 224, 0.5);
        border-radius: 50%;
        cursor: pointer;
        margin-left: 0;
        margin-right: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }

    svg {
        fill: #000000;
    }
`;

export const TagButton = styled.button`
    margin-left: 5vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${breakpoints.md}) {
        background: rgba(224, 224, 224, 0.5);
        border-radius: 50%;
        cursor: pointer;
        margin-right: 0;
        width: 40px;
        height: 40px;
        position: relative;
        margin-left: auto;
    }

    svg {
        fill: #000000;
    }
    
    @media (max-width: ${breakpoints.sm}) {
        margin-left: 4.5vw;
    }
`;

export const UserDropdownTrigger = styled.button`
    display: none;
    @media (min-width: ${breakpoints.md}) {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin-left: 12px;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const UserDropdownCountry = styled.span`
    text-transform: uppercase;
    padding-left: 3px;
    margin-right: 10px;
`;

export const SearchBoxWrapper = styled.div`
    position: ${({ isMobileScreen }) =>
        isMobileScreen ? "fixed" : "relative"};
    background: white;
    display: flex;
    height: 56px;
    width: ${({ isMobileScreen }) => (isMobileScreen && "100%")};
    left: 0;
    right: 0;
    top: 0;
    padding: 3px 5px;
    align-items: center;
    z-index: 250;
    @media (min-width: ${breakpoints.md}) {
        background: rgba(232, 232, 232, 0.5);
        border-radius: 2px;
        height: 40px;
        padding: 3px 15px;
        margin-left: 120px;
        margin-right: 33px;
    }
`;

export const BoxVariants = styled.div`
    height: 100%;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    position: absolute;
    top: 46px;
    left: 0;
    z-index: 100;
    background-color: white;
    box-shadow: 0 8px 28px -6px rgba(24, 39, 75, 0.12),
        0px 18px 88px -4px rgba(24, 39, 75, 0.14);
`;

export const BoxVariantItem = styled.div`
    cursor: pointer;
    height: 40px;
    padding: 10px 14px;
    z-index: 1000;
    position: relative;
    &:hover {
        background-color: #f3f3f3;
    }
`;


export const BoxVariantsUser = styled.div`
    min-height: 100%;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    background-color: white;
    padding: 10px 0 0 0;
`;

export const BoxVariantUserItem = styled.div`
    cursor: pointer;
    padding: 5px 14px;
    height: 55px;
    z-index: 1000;
    position: relative;
    &:hover {
        background-color: #f3f3f3;
    }
`;

export const BoxVariantItemTitle = styled.h6`
    font-size: 3vw;
    line-height: 24px;
    font-weight: 400;
    @media (min-width: ${breakpoints.md}) {
        font-size: 13px;
    }
`;

export const BoxVariantItemDescription = styled.h6`
    font-size: 3vw;
    line-height: 24px;
    font-weight: 500;
    color: #37474f;
    @media (min-width: ${breakpoints.md}) {
        font-size: 10px;
    }
`;

export const SearchCategoryFilter = styled.div`
    border-right: 1px solid #e5e5e5;
    label + .MuiInput-formControl {
        margin-top: 0;
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after {
        border-bottom: 0;
    }
    .MuiSelect-select.MuiSelect-select {
        font-size: 14px;
        padding-top: 0;
        padding-bottom: 0;
        &:focus {
            background: transparent;
        }
    }
`;

export const SearchCategoryInput = styled.input`
    background: none;
    border: 0;
    margin-left: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    flex: 1;
    min-width: 100px;

    @media (min-width: ${breakpoints.md}) {
        margin-left: 0.625vw;
    }
`;

export const SearchCategoryButton = styled.button`
    margin-left: auto;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    svg {
        fill: #000000;
    }
`;

export const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0;
    right: 0;
    top: ${({ isMobileScreen }) => (isMobileScreen ? "56px" : 0)};;
    bottom: 0;
    z-index: 1000;
    @media (min-width: ${breakpoints.md}) {
        display: none;
    }
`;
export const UserMenuWrapper = styled.div`
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 24px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 91.11vw;
    z-index: 1100;

    @media (min-width: ${breakpoints.md}) {
        position: absolute;
        top: 75px;
        transform: none;
        width: 390px;
        right: 23px;
        z-index: 300;
    }

    .MuiFormControl-root {
        margin-bottom: 24px;
        display: flex;
    }
`;

export const UserMenuArrow = styled.span`
    display: none;
    position: absolute;
    width: 13px;
    height: 13px;
    right: 9px;
    top: -6px;

    background: #ffffff;
    z-index: 10;
    box-shadow: 2px -2px 3px 0px rgba(0, 0, 0, 0.05);
    border-radius: 1.61213px;
    transform: rotate(-45deg);

    @media (min-width: ${breakpoints.md}) {
        display: block;
    }
`;

export const UserMenuSearchWrapper = styled.div`
    background: #ffffff;
    border: 1px solid #37474f;
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    padding: 6px 14px;
    justify-content: space-around;
    svg {
        margin-left: auto;
    }
    @media (min-width: ${breakpoints.md}) {
        padding: 12px 14px;
    }
`;

export const UserMenuSearchInput = styled.input`
    border: 0;
    color: #37474f;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    flex: 1 1 auto;
    min-width: 100px;

    &::placeholder {
        color: #37474f;
        font-size: 14px;
    }
`;

export const UserMenuSeparator = styled.hr`
    height: 1px;
    border: 0;
    background: #a6a6a6;
    margin: 16px 0;
`;

export const UserMenuSelectLabel = styled.label`
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    color: #37474f;
`;

export const UserMenuActionButton = styled.button`
    align-items: center;
    background: #f28a22;
    border-radius: 2px;
    color: #ffffff;
    display: flex;
    font-weight: bold;
    font-size: 14px;
    height: 36px;
    justify-content: center;
    letter-spacing: 0.15px;
    width: 100%;

    @media (min-width: ${breakpoints.md}) {
        height: 48px;

        &:hover {
            color: rgba(255, 255, 255, 0.8);
        }
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    ${({ dark }) =>
        dark &&
        css`
            background: #37474f;
        `}
`;

export const PublicDesktopHeaderSeparator = styled.span`
    display: none;
    width: 1px;
    height: 41px;
    margin: 11px;
    background: #e5e5e5;
    @media (min-width: ${breakpoints.md}) {
        display: inline-flex;
        margin-left: auto;
    }
`;

export const PublicLangButton = styled.button`
    position: relative;
    margin-left: 10px;   
    @media (min-width: ${breakpoints.md}) {
        cursor: pointer;
        margin-left: 0;
        margin-right: 0;
        position: relative;
    }
`;

export const IconBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: auto;
   
    @media (max-width: ${breakpoints.xs}) {
        margin-left: 0;
    }
`;