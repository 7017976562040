import React from "react";
import Typography from "../../../Typography";
import { PriceFlyoutWrapper } from "../Price/style";
import { useTranslation } from "react-i18next";

export const FavoriteTipsFlyout = React.forwardRef(({ isFavorite }, ref) => {
    const { t } = useTranslation();
    let message = "";

    message = isFavorite ? <Typography>{t('productsList.favouriteFlyout.remove')}</Typography> :
        <Typography>{t('productsList.favouriteFlyout.add')}</Typography>;
    return (
        <PriceFlyoutWrapper style={{ marginRight: "10px" }} ref={ref}>
            {message}
        </PriceFlyoutWrapper>
    );
});