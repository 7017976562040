import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { VALIDATE_RESET_LINK_SUCCESS } from "../../core/redux/actionTypes/passwordReset";
import { validateResetLink } from "../../core/redux/asyncActions/passwordReset";
import { PASSWORD_RESET_STEPS } from "./constants";

export const useUrlBasedSteps = () => {
    const { token, uid } = useParams();
    const dispatch = useDispatch();
    const validateResetLinkCb = async () => {
        const {
            type,
            payload: { ok },
        } = await dispatch(validateResetLink(token, uid));
        setStep(
            type === VALIDATE_RESET_LINK_SUCCESS && ok
                ? PASSWORD_RESET_STEPS.ResetPassword
                : PASSWORD_RESET_STEPS.InvalidResetLink
        );
    };
    const autoDetectInitialStep = () => {
        if (token && uid) {
            validateResetLinkCb();
            return PASSWORD_RESET_STEPS.ValidateResetLink;
        }
        return PASSWORD_RESET_STEPS.SendResetLink;
    };
    const [step, setStep] = useState(autoDetectInitialStep);

    return { step, setStep, token, uid };
};
