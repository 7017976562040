import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { theme } from "../../../core/styles/theme";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Typography from "../../../components/Typography";
import { COUNTRY_CURRENCY } from "../../../core/utils";
import { Price } from "../../../components/Listing/components/Price";

import { fetchDetailsByOem } from "../../../core/redux/asyncActions/global";
import { Stock } from "../../../components/Listing/components/Stock";

import {
    Container,
    Number,
    TabelBoldElement,
    TableContainer,
    TableColumn,
    PriceContainer,
    CustomAccordionSummary,
    ColumnContainer,
    QuantityContainer,
} from "../style";


import { ProductsList } from "../../../components/Listing/components/ProductsList";
import QuantityButton from "../../../components/Listing/components/QuantityButton";
import TourPopup from "../../../components/Tour/TourPopup";
import { StyledCircularProgress } from "../../../components/CircularProgress";

const Order = ({
    number,
    orderName,
    oem,
    items,
    currentCountry,
    isactive,
    isMobileScreen,
    showTourTooltip
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [orderRef, setOrderRef] = useState();

    const [isExpand, setExpand] = useState(false);
    const [itemDetails, setItemDetails] = useState([]);
    const [isLoadingDetails, setLoadingDetails] = useState(false);

    const loadOemDetails = async (e, expanded) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setExpand(expanded);
        if (expanded) {
            setLoadingDetails(true);
            const data = await dispatch(fetchDetailsByOem(oem));
            if (data.type !== "FETCH_OEM_DETAILS_FAILURE") {
                const { results = [] } = data;
                setItemDetails(results);
            }
        }
        setLoadingDetails(false);
    };

    const handleTooltipShow = async () => {
        setExpand(true);
        setLoadingDetails(true);

        const data = await dispatch(fetchDetailsByOem(oem));
        if (data.type !== "FETCH_OEM_DETAILS_FAILURE") {
            const { results = [] } = data;
            setItemDetails(results);
        }
        setLoadingDetails(false);
    }

    return (
        <>
            <Accordion
                onChange={loadOemDetails}
                expanded={isExpand}
                style={{
                    pointerEvents: !items ? "none" : "auto",
                }}
            >
                <CustomAccordionSummary
                    ref={ref => setOrderRef(ref)}
                    expandIcon={<ExpandMoreIcon />}
                    isdisabled={!items}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Container>
                        <Number
                            isactive={isactive}
                            color={isactive && "white"}
                            customVariant="body"
                        >
                            {number}
                        </Number>
                        <Box padding="0 10px">
                            <Typography
                                customVariant="body"
                                textAlign="left"
                                color={theme.colors.font.darkBlue}
                            >
                                {orderName}
                            </Typography>
                        </Box>
                        <Box padding="0 10px">
                            <Typography
                                customVariant="body"
                                textAlign="left"
                                color={theme.colors.font.darkBlue}
                            >
                                {oem}
                            </Typography>
                        </Box>
                    </Container>
                </CustomAccordionSummary>
                <AccordionDetails>
                    <Box display="flex" justifyContent="center" width="100%">
                        {isLoadingDetails ? (
                            <StyledCircularProgress disableShrink/>
                        ) : (
                            <Box width="100%">
                                {!isMobileScreen && Boolean(itemDetails.length) && (
                                    <ProductsList
                                        showTourTooltip={showTourTooltip}
                                        products={itemDetails}
                                        general={false}
                                    />
                                )}
                                {Boolean(itemDetails.length && isMobileScreen) &&
                                    itemDetails.map(
                                        ({
                                            id,
                                            ak_code,
                                            oem_code,
                                            vendor,
                                            vendor_image,
                                            prices,
                                            stock,
                                        }, index) => (
                                            <TableContainer
                                                key={oem_code}
                                                width="100%"
                                                marginBottom="20px"
                                                paddingBottom="16px"
                                            >
                                                <ColumnContainer>
                                                    <TableColumn>
                                                        <TabelBoldElement>
                                                            {t(
                                                                "productsList.tableHeader.AKCode"
                                                            )}
                                                            {isMobileScreen && ":"}
                                                        </TabelBoldElement>
                                                        <Box padding="16px">
                                                            {ak_code || "-"}
                                                        </Box>
                                                    </TableColumn>
                                                    <TableColumn>
                                                        <TabelBoldElement>
                                                            {t(
                                                                "productsList.tableHeader.oem"
                                                            )}
                                                            {isMobileScreen && ":"}
                                                        </TabelBoldElement>
                                                        <Box padding="20px">
                                                            {oem_code}
                                                        </Box>
                                                    </TableColumn>
                                                    <TableColumn>
                                                        <TabelBoldElement>
                                                            {t(
                                                                "productsList.tableHeader.vendor"
                                                            )}
                                                            {isMobileScreen && ":"}
                                                        </TabelBoldElement>
                                                        <Box padding="20px">
                                                            {(vendor_image) ?
                                                                <img src={vendor_image} alt={vendor} style={{ height: "10px" }} /> :
                                                                vendor
                                                            }
                                                        </Box>
                                                    </TableColumn>
                                                    <TableColumn>
                                                        <TabelBoldElement>
                                                            {t(
                                                                "productsList.tableHeader.stock"
                                                            )}
                                                            {isMobileScreen && ":"}
                                                        </TabelBoldElement>
                                                        <Box padding="16px">
                                                            <Stock
                                                                stock={stock}
                                                                id={oem_code}
                                                            />
                                                        </Box>
                                                    </TableColumn>
                                                    {isMobileScreen && (
                                                        <TableColumn>
                                                            <TabelBoldElement>
                                                                {t(
                                                                    "productsList.tableHeader.price"
                                                                )}
                                                                {":"}
                                                            </TabelBoldElement>
                                                            <Box padding="16px">
                                                                <Price
                                                                    price={prices}
                                                                >
                                                                    {(
                                                                        value,
                                                                        mandatoryProps
                                                                    ) => (
                                                                        <Typography
                                                                            {...mandatoryProps}
                                                                            color={
                                                                                theme
                                                                                    .colors
                                                                                    .font
                                                                                    .darkBlue
                                                                            }
                                                                            customVariant="body"
                                                                            fontWeight={
                                                                                700
                                                                            }
                                                                            cursor="pointer"
                                                                        >
                                                                            {COUNTRY_CURRENCY[
                                                                                currentCountry
                                                                            ] +
                                                                                value}
                                                                        </Typography>
                                                                    )}
                                                                </Price>
                                                            </Box>
                                                        </TableColumn>
                                                    )}
                                                </ColumnContainer>
                                                <PriceContainer>
                                                    <QuantityContainer display="flex">
                                                        <QuantityButton
                                                            showTourTooltip={index === 0 && showTourTooltip}
                                                            data={{
                                                                id: id,
                                                                ak_code,
                                                                oem_code,
                                                                vendor,
                                                                prices,
                                                                stock,
                                                            }} />
                                                    </QuantityContainer>
                                                </PriceContainer>
                                            </TableContainer>
                                        )
                                    )}
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            {showTourTooltip && orderRef && (
                <TourPopup
                    delay={200}
                    step={14}
                    anchorEl={orderRef}
                    placement={isMobileScreen ? "top" : "left-start"}
                    onShow={handleTooltipShow}
                >
                    {t('appTour.openDetails')}
                </TourPopup>
            )}
        </>
    );
};

export default memo(Order);
