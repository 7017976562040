import Checkbox from "@material-ui/core/Checkbox";
import styled, { css } from "styled-components";

export default styled(Checkbox)(
    ({ theme }) => css`
        svg {
            position: absolute;
            width: 16px;
            height: 16px;
            color: ${theme.colors.font.base};
            left: 10px;
        }
    `
);
