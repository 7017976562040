import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "../../../components/Checkbox";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

const StyledFormControlLabel = styled(FormControlLabel)(
    ({ theme }) => css`
        & > span:nth-child(2) {
            margin-left: 10px;
            font-size: ${theme.typography.captionSlim.fontSize};
            font-weight: ${theme.typography.captionSlim.fontWeight};
            line-height: ${theme.typography.captionSlim.lineHeight};
            color: ${theme.colors.font.base};
        }
    `
);

export const PrivacyPolicyCheckbox = ({ onChange, checked, ...otherProps }) => {
    const { t } = useTranslation();

    return (
        <StyledFormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    name="ppCheckbox"
                    size="small"
                    {...otherProps}
                />
            }
            label={t("login.privacyPolicyAgreement")}
        />
    );
};