import React, { useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";

import { StyledTable } from "./style";
import { useDispatch } from "react-redux";
import { fetchProductDetails } from "../../../../core/redux/asyncActions/global";
import { StyledCircularProgress } from "../../../CircularProgress";

export const Table = ({ columns, items }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleItemPopup = async (e, item) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (!isLoading) {
            setIsLoading(true);
            await dispatch(fetchProductDetails(item.id, 1));
            setIsLoading(false);
        }
    };

    const [productUnits, setProductUnits] = useState([]);

    const count = (id) => {
        var unitCount = 1;
        productUnits.map((units) => ((units[0] === id) ? (unitCount = units[1]) : undefined))
        return unitCount;
    }

    if (!columns || !columns.length) return null;

    return (
        <Box padding="0 5px" width="100%" position="relative">
            {isLoading && (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "sticky",
                    top: "50%",
                    zIndex: 2
                }}>
                    <StyledCircularProgress />
                </div>
            )}
            <StyledTable style={{
                borderCollapse: "separate",
                borderSpacing: "0 10px",
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? "none" : "auto"
            }}>
                <TableHead>
                    <tr>
                        {columns.map((col) => (
                            (col.id !== undefined) && (
                                <TableCell key={`header-${col.id}`} style={((`${col.id}` === "oemCode" || `${col.id}` === "vendor" || `${col.id}` === "product" || `${col.id}` === "akCode") ? { textAlign: "left" } : { textAlign: "center" })}>
                                    {col.label}
                                </TableCell>)
                        ))}
                    </tr>
                </TableHead>
                <TableBody>
                    {items && Boolean(items?.length) &&
                        items.map((item, index) => (
                            <TableRow key={item.id}>
                                {columns.map((col) => {
                                    const id = `${col.id}`;
                                    const cellValue =
                                        typeof col.valueGetter === "function"
                                            ? col.valueGetter(item)
                                            : item[col.valueGetter];
                                    const Renderer = col.renderer;
                                    const productUnitsCount = (unitsCount) => {
                                        let isNotInArray = true;
                                        productUnits.map((units) => (
                                            (units[0] === item.id) && (
                                                units[1] = unitsCount,
                                                isNotInArray = false,
                                                setProductUnits([...productUnits]))
                                        ))
                                        if (isNotInArray) { setProductUnits([...productUnits, [item.id, unitsCount]]); }
                                    }
                                    return Renderer ? (
                                        (col.id !== undefined) && (
                                            <TableCell
                                                key={`cell-${id}`}
                                                id={col.id}
                                                style={(id !== "quantity")
                                                    ? (id === "img" ? { cursor: "pointer", textAlign: "center", width: "7%" } : (id === "vendor" ? { cursor: "pointer", textAlign: "left", width: "9%" } : { cursor: "pointer", textAlign: "center", width: "8%" }))
                                                    : { textAlign: "center", width: "17%" }}
                                                onClick={(handleItemPopup && id !== "quantity") ? (((e) => (handleItemPopup(e, item)))) : undefined}
                                            >
                                                <Renderer
                                                    value={cellValue}
                                                    rowData={item}
                                                    units={count(item.id)}
                                                    unitsCount={productUnitsCount}
                                                    rowIndex={index}
                                                />
                                            </TableCell>)
                                    ) : (
                                        (col.id !== undefined) && (
                                            <TableCell
                                                key={`cell-${id}`}
                                                id={col.id}
                                                style={(
                                                    (id === "akCode")
                                                        ? { width: "9%", textAlign: "left", cursor: "pointer" } : (id === "product" ? { width: "33%", textAlign: "left", cursor: "pointer", wordBreak: "break-word" }
                                                            : (id === "oemCode") ? { width: "9%", textAlign: "left", cursor: "pointer" } : {})
                                                )}
                                                onClick={handleItemPopup ? (((e) => (handleItemPopup(e, item)))) : undefined}
                                            >
                                                {cellValue}
                                            </TableCell>)
                                    );
                                })}
                            </TableRow>
                        ))}
                </TableBody>
            </StyledTable>
        </Box>
    );
};
