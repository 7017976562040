import styled, { css } from "styled-components";
import { breakpoints } from "../../../../core/utils/style";

export const ImagePreview = styled.div(
    ({ imgHeight, width, height, oemItem }) => css`    
         ${!oemItem ?
            `
            height: ${height || "50px"};
            width: ${width || "100%"}; 
            justify-content: center;
            align-items: center;
            display: flex;
        
            && img {
                width: 100%;
                height: ${imgHeight || "20px"};
                object-fit: contain;  
                max-width: 50px; 
            }
            @media (max-width: ${breakpoints.md}) {
                width: 50px; 
            }
        `
            :
            `
            height: 184px;
            width: 160px; 
            justify-content: center;
            align-items: center;
            display: flex;
            && img {
                width: 160px;
                height: 184px;
                object-fit: contain;  
            }
            @media (min-width: ${breakpoints.md}) {
                height: 184px;
                width: 184px; 
                justify-content: center;
                align-items: center;
                display: flex;
                && img {
                    width:  184px;
                    height: 183.183px;
                    object-fit: contain;  
                }
              }
            `
        } 
    `
);

export const ImageFlyoutWrapper = styled.div(
    ({ height, width }) => css`
        height: ${height || "500px"};
        width: ${width || "501px"};
        background: white;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25),
            0px 4px 16px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        padding: 24px;

        && img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    `
);
