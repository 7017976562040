import React, { useEffect, useRef, useState } from "react";
import Fade from "@material-ui/core/Fade";
import throttle from 'lodash/throttle';
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';

import { useTourController } from ".";

import { Arrow, Popup, Content, Text, Step, ButtonNext, ButtonSkip } from './style';

const THRESHOLD = 10;

const TourPopup = ({
  step,
  delay,
  anchorEl,
  placement,
  onShow,
  onHide,
  last,
  scroll = 'center',
  children
}) => {
  const { t } = useTranslation();
  const stepRef = useRef(step);
  const arrowRef = useRef();
  const prevState = useRef('hidden');

  const [shown, setShown] = useState(!delay);
  const { currentStep, next, skipTour } = useTourController();
  const [shouldShown, setShouldShown] = useState(currentStep === step);

  useEffect(() => {
    if (!shown) return;

    if (shouldShown) {
      scroll && anchorEl?.scrollIntoView({ block: scroll, behavior: "auto" });

      typeof onShow === 'function' && onShow();

      prevState.current = 'shown';
    }

    if (!shouldShown && prevState.current === 'shown') {
      typeof onHide === 'function' && onHide()
      if (last) skipTour();

      prevState.current = 'hidden';
    }
  }, [shouldShown, shown, anchorEl])

  useEffect(() => {
    setShouldShown(currentStep === step);
  }, [currentStep, step])

  useEffect(() => {
    if (shown) return;
    if (!shouldShown) return;

    const timeout = setTimeout(() => {
      setShown(true);
    }, delay);

    return () => {
      clearTimeout(timeout);
    }
  }, [delay, shouldShown, shown])

  useEffect(() => {
    if (!shown) return;

    if (currentStep !== step) return;

    const handleHighlightAnchor = throttle(() => {
      if (!anchorEl) return;

      const boundingRect = anchorEl.getBoundingClientRect();
      const overlay = document.getElementById('tour-overlay');

      if (!overlay) return;

      const overlayWidth = overlay.clientWidth;
      const overlayHeight = overlay.clientHeight;
      const topEdge = boundingRect.top - THRESHOLD;
      const bottomEdge = boundingRect.bottom + THRESHOLD;
      const leftEdge = boundingRect.left - THRESHOLD;
      const rightEdge = boundingRect.right + THRESHOLD;

      overlay.style.clipPath = `polygon(0 0, ${overlayWidth}px 0,
        ${overlayWidth}px ${topEdge}px,
        ${leftEdge}px ${topEdge}px,
        ${leftEdge}px ${bottomEdge}px,
        ${rightEdge}px ${bottomEdge}px,
        ${rightEdge}px ${topEdge}px,
        ${overlayWidth}px ${topEdge}px,
        ${overlayWidth}px ${overlayHeight}px,
        0 ${overlayHeight}px,
        0 0)`;
    }, 50)

    handleHighlightAnchor();

    window.addEventListener('resize', handleHighlightAnchor);
    window.addEventListener('orientationchange', handleHighlightAnchor);
    window.addEventListener('scroll', handleHighlightAnchor);

    return () => {
      window.removeEventListener('resize', handleHighlightAnchor);
      window.removeEventListener('orientationchange', handleHighlightAnchor);
      window.removeEventListener('scroll', handleHighlightAnchor);
    }
  }, [currentStep, shown, anchorEl]);

  if (!shown) return null;

  return (
    <Popup
      open={step === currentStep}
      anchorEl={anchorEl}
      placement={placement}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
        },
        offset: {
          enabled: true,
          offset: "-30, 20"
        },
        arrow: {
          enabled: false,
          element: arrowRef.current,
        }
      }}
      transition
    >
      {({ ref, placement, ArrowProps, TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Content ref={ref}>
            <ButtonSkip onClick={skipTour}><CloseIcon /></ButtonSkip>
            <Step>{stepRef.current}</Step>
            <Text>
              {children}
            </Text>
            <ButtonNext size="small" variant="contained" onClick={next}>{last ? t('appTour.finish') : t('appTour.next')}</ButtonNext>
            <Arrow ref={arrowRef} placement={placement} {...ArrowProps} />
          </Content>
        </Fade>
      )}
    </Popup>
  )
}

export default React.memo(TourPopup);