import api from "../../api";
import resources from "../../api/resources";
import * as cartsAction from "../actions/carts";
import querystring from "query-string"
import { setNotification } from "../actions/global";
import store from "../store";

let language;

store.subscribe(() => {
    language = store.getState().global.lang;
});

export const fetchBaskets = () => {
    return async (dispatch) => {
        dispatch(cartsAction.fetchBasketRequest());
        try {
            const { data = {} } = await api.get(resources.basket.list);
            const { results = [] } = data;

            const count = results.reduce((acc, item) => { return acc + item.item_count }, 0)

            dispatch(cartsAction.changeCountItems(count || 0))
            return dispatch(cartsAction.fetchBasketSuccess(results));
        } catch (error) {
            return dispatch(cartsAction.fetchBasketFailure(error));
        }
    };
};

export const addItemToBasket = (cartId, itemId, count, titleCard, itemName, isOrderPage = false, sourceLocation) => {
    return async (dispatch) => {
        dispatch(cartsAction.fetchAddItemBasketRequest());
        try {
            await api.post(
                resources.basket.add_item,
                querystring.stringify({
                    cart_id: cartId,
                    item_id: itemId,
                    count: count < 0 ? 0 : count,
                    ...(isOrderPage ? { source: 'order' } : {}),
                }),
                {
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded",
                    },
                }
            );

            dispatch(fetchInfoBasketById(cartId, sourceLocation));

            const { data = {} } = await api.get(resources.basket.list);
            const { results = [] } = data;

            const quantity = results.reduce((acc, item) => { return acc + item.item_count }, 0)

            dispatch(cartsAction.changeCountItems(quantity || 0))

            dispatch(
                setNotification(
                    {
                        titleCard: titleCard,
                        itemId: itemId,
                        actionName: count < 0 ? 'removed' : 'added',
                        itemName
                    }
                )
            );
            return dispatch(cartsAction.fetchAddItemBasketSuccess());
        } catch (error) {
            return dispatch(cartsAction.fetchAddItemBasketFailure(error));
        }
    };
};

export const fetchInfoBasketById = (cartId, location = '') => {
    return async (dispatch) => {
        dispatch(cartsAction.fetchInfoBasketRequest());
        try {
            const { data } = await api.get(resources.basket.info, {
                params: {
                    language,
                    id: cartId,
                    ...(location ? { location } : {})
                },
            });
            dispatch(orderInfoRequest());
            return dispatch(
                cartsAction.fetchInfoBasketSuccess({ cartId, cartInfo: data })
            );
        } catch (error) {
            return dispatch(cartsAction.fetchInfoBasketFailure(error));
        }
    };
};

export const addBasket = (cartName) => {
    return async (dispatch) => {
        dispatch(cartsAction.addBasketRequest());
        try {
            const { data } = await api.post(
                resources.basket.info,
                JSON.stringify({
                    title: cartName,
                }),
                {}
            );

            dispatch(cartsAction.addBasketSuccess(data));

            return data.id;
        } catch (error) {
            dispatch(cartsAction.addBasketFailure(error));
            return null;
        }
    };
};

export const addDefaultBasket = (id, title) => {
    return async (dispatch) => {
        dispatch(cartsAction.addDefaultBasketRequest());
        try {
            await api.patch(
                resources.basket.info,
                JSON.stringify({
                    id,
                    default_cart: true,
                }),
                {}
            );
            dispatch(
                setNotification(
                    {
                        titleCard: title,
                        actionName: 'setDefaultCart',
                    }
                )
            );
            return dispatch(cartsAction.addDefaultBasketSuccess(id));
        } catch (error) {
            return dispatch(cartsAction.addDefaultBasketFailure());
        }
    };
};

export const deleteDefaultBasket = (id, title) => {
    return async (dispatch) => {
        dispatch(cartsAction.deleteDefaultBasketRequest());
        try {
            await api.patch(
                resources.basket.info,
                JSON.stringify({
                    id,
                    default_cart: false,
                }),
                {}
            );
            dispatch(
                setNotification(
                    {
                        titleCard: title,
                        actionName: 'removeDefaultCart',
                    }
                )
            );
            dispatch(cartsAction.deleteDefaultBasketSuccess());
            return dispatch(cartsAction.deleteDefaultBasketSuccess());
        } catch (error) {
            return dispatch(cartsAction.deleteDefaultBasketFailure());
        }
    };
};

export const orderInfoRequest = () => {
    return async (dispatch) => {
        dispatch(cartsAction.orderInfoRequest());
        try {
            const { data } = await api.get(
                resources.order.info,
                {
                    params: {
                        language,
                    },
                }
            );
            dispatch(cartsAction.orderInfoSuccess(data));
            return data;
        } catch (error) {
            return dispatch(cartsAction.orderInfoFailure(error));
        }
    };
};

export const orderSubmitRequest = (
    cartId,
    deliveryType,
    isWareHouse,
    sourceLocation,
    destinationLocation,
    deliveryAddresses,
    comments,
    addressName,
    addressAddress,
    addressCity,
    addressPostalCode,
    addressPhone,
    addressEmail
) => {
    const objectRequest = {
        cart_id: cartId,
        delivery_type: deliveryType,
        comments
    }
    if (isWareHouse) {
        objectRequest.source_location = sourceLocation.id
        objectRequest.destination_location = destinationLocation.id
    }
    if (!isWareHouse && deliveryAddresses) {
        objectRequest.delivery_address_id = deliveryAddresses
    }
    if (deliveryAddresses === -1) {
        objectRequest.address_name = addressName
        objectRequest.address_address = addressAddress
        objectRequest.address_city = addressCity
        objectRequest.address_zip = addressPostalCode
        objectRequest.address_phone = addressPhone
        objectRequest.address_email = addressEmail
    }
    if (sourceLocation && sourceLocation.id) {
        objectRequest.source_location = sourceLocation.id;
    }

    return async (dispatch) => {
        try {
            dispatch(cartsAction.orderSubmitRequest());
            const { data } = await api.post(
                resources.order.submit,
                JSON.stringify(objectRequest),
                {}
            );

            dispatch(cartsAction.orderSubmitSuccess(data));
            return data;
        } catch (error) {
            return dispatch(cartsAction.orderInfoFailure());
        }
    };
};

export const deleteBasket = (id, title) => {
    return async (dispatch) => {
        dispatch(cartsAction.deleteBasketRequest());
        try {
            await api.delete(
                resources.basket.drop,
                {
                    params: {
                        id,
                    },
                }
            );
            dispatch(fetchBaskets())

            dispatch(
                setNotification(
                    {
                        titleCard: title,
                        actionName: 'deletedCart',
                    }
                )
            );
            return dispatch(cartsAction.deleteBasketSuccess(id));
        } catch (error) {
            return dispatch(cartsAction.deleteBasketFailure());
        }
    };
};

export const emptyBasket = (id, title) => {
    return async (dispatch) => {
        dispatch(cartsAction.emptyBasketRequest());
        try {
            await api.delete(
                resources.basket.info,
                {
                    params: {
                        id,
                    },
                }
            );
            dispatch(fetchBaskets())

            dispatch(
                setNotification(
                    {
                        titleCard: title,
                        actionName: 'emptiedCart',
                    }
                )
            );
            return dispatch(cartsAction.emptyBasketSuccess(id));
        } catch (error) {
            return dispatch(cartsAction.emptyBasketFailure());
        }
    };
};


