import Snackbar from "@material-ui/core/Snackbar";
import styled from "styled-components";
import { breakpoints } from "../../core/utils/style";

export const StyledSnackBar = styled(Snackbar).attrs(({ isphone }) => ({
    anchorOrigin: isphone
        ? { vertical: "bottom", horizontal: "center" }
        : { vertical: "top", horizontal: "right" },
}))`
    align-items: center;
    background: #465d66;
    border-radius: 2px;
    display: flex;
    min-height: 48px;
    max-width: 420px;
    max-height: 300px;
    justify-content: space-between;
    padding: 0 12px 0 16px;
    position: fixed;
    z-index: 10000;

    .MuiSnackbarContent-root {
        background-color: inherit;
        box-shadow: none;
        padding: 0;

        .MuiSnackbarContent-action {
            padding-left: 0;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        padding: 0 24px 0 48px;
        top: 70px !important;
    }

    @media (max-width: ${breakpoints.sm}) {
        padding: 0 16px;
    }
`;
