import React, { useCallback, useRef, useState, useEffect } from "react";
import { Popper } from "../../../Popper";
import { ImagePreview } from "./style";
import { ImageFlyout } from "./ImageFlyout";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const AsyncImage = (props) => {
    const [loadedSrc, setLoadedSrc] = useState(null);
    useEffect(() => {
        setLoadedSrc(null);
        if (props.src) {
            const handleLoad = () => {
                setLoadedSrc(props.src);
            };
            const image = new Image();
            image.addEventListener('load', handleLoad);
            image.src = props.src;
            return () => {
                image.removeEventListener('load', handleLoad);
            };
        }
    }, [props.src]);
    if (loadedSrc === props.src) {
        return (
            <img {...props} alt="product" loading="lazy" />
        );
    }
    return null;
};


export const ImageWithZoom = ({ id, src, popupSize, placement = "right", imgSize }) => {
    const [popperVisibility, setPopperVisibility] = useState(false);
    const imageRef = useRef();
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const closePopper = useCallback(() => {
        setPopperVisibility(false);
    }, [setPopperVisibility]);
    const togglePopper = useCallback(() => {
        setPopperVisibility((curValue) => !curValue);
    }, [setPopperVisibility]);
    const openPopper = useCallback(() => {
        setPopperVisibility(true);
    }, [setPopperVisibility]);

    const imagePreviewProps = isPhoneScreen
        ? {
            onClick: togglePopper,
        }
        : {
            onMouseEnter: openPopper,
            onMouseLeave: closePopper,
        };

    return (
        <>
            <ImagePreview ref={imageRef} {...imagePreviewProps} {...imgSize}>
                <AsyncImage src={src} />
            </ImagePreview>
            {src !== "/product-dot.svg" &&
                <Popper
                    id={id}
                    visibility={popperVisibility}
                    anchorEl={imageRef?.current}
                    onClickAway={closePopper}
                    placement={placement}
                    ignoreBackground={true}
                >
                    <ImageFlyout src={src} size={popupSize} />
                </Popper>}
        </>
    );
};
