import React from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "../../components/Logo";
import Typography from "../../components/Typography";
import { PageLayout, FormContainer, LogoContainer } from "./style";
import { Box } from "../../components/Box";
import { useDispatch } from "react-redux";
import {
    changePassword,
    sendResetLink,
} from "../../core/redux/asyncActions/passwordReset";
import {
    CHANGE_PASSWORD_SUCCESS,
    SEND_RESET_LINK_SUCCESS,
} from "../../core/redux/actionTypes/passwordReset";
import { PASSWORD_RESET_STEPS } from "./constants";
import { useUrlBasedSteps } from "./useUrlBasedSteps";
import { DEFAULT_PATH } from "../../core/routes";
import { SendResetLink } from "./components/SendResetLink";
import { ResetLinkSuccessfullySent } from "./components/ResetLinkSuccessfullySent";
import { ResetPassword } from "./components/ResetPassword";
import { LinkAsButton } from "../../components/Link";
import { StyledCircularProgress } from "../../components/CircularProgress";

const PasswordReset = () => {
    const { step, setStep, token, uid } = useUrlBasedSteps();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleSubmitEmail = async (email) => {
        const { type } = await dispatch(sendResetLink(email));
        if (type === SEND_RESET_LINK_SUCCESS) {
            setStep(PASSWORD_RESET_STEPS.ResetLinkSuccessfullySent);
        }
    };
    const handleSubmitNewPassword = async (newPassword, confirmPassword) => {
        const { type } = await dispatch(
            changePassword(newPassword, confirmPassword, token, uid)
        );
        if (type === CHANGE_PASSWORD_SUCCESS) {
            setStep(PASSWORD_RESET_STEPS.ResetPasswordSuccess);
        }
    };

    const renderStepsCorrespondingUI = () => {
        switch (step) {
            case PASSWORD_RESET_STEPS.SendResetLink:
                return <SendResetLink onSubmitEmail={handleSubmitEmail} />;
            case PASSWORD_RESET_STEPS.ResetLinkSuccessfullySent:
                return <ResetLinkSuccessfullySent />;
            case PASSWORD_RESET_STEPS.ValidateResetLink:
                return (
                    <Box justifyContent="center">
                        <StyledCircularProgress disableShrink/>
                    </Box>
                );
            case PASSWORD_RESET_STEPS.InvalidResetLink:
                return (
                    <Typography textAlign="left">
                        {t("passwordReset.invalidResetLinkStep.text")}
                    </Typography>
                );
            case PASSWORD_RESET_STEPS.ResetPassword:
                return (
                    <ResetPassword onChangePassword={handleSubmitNewPassword} />
                );
            case PASSWORD_RESET_STEPS.ResetPasswordSuccess:
                return (
                    <>
                        <Typography textAlign="left">
                            {t("passwordReset.resetPasswordSuccessStep.text")}
                        </Typography>
                        <LinkAsButton to={DEFAULT_PATH}>
                            {t("passwordReset.resetPasswordSuccessStep.login")}
                        </LinkAsButton>
                    </>
                );
            default:
                return <SendResetLink onSubmitEmail={handleSubmitEmail} />;
        }
    };

    return (
        <PageLayout>
            <FormContainer>
                <LogoContainer>
                    <Logo />
                </LogoContainer>
                {renderStepsCorrespondingUI()}
            </FormContainer>
        </PageLayout>
    );
};

export default PasswordReset;
