import { Select } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import styled, { css } from "styled-components";
import { Box } from "../../components/Box";
import { flex, fullSize } from "../../core/styles/common";

export const StyledLoginPage = styled.div`
    background: ${({ theme }) => theme.colors.background};
    ${fullSize}
    ${flex("column")}
    overflow-y: auto;
    min-height: 610px;
`;

export const FormContainer = styled.div`
    display: grid;
    row-gap: 16px;
    padding: ${({ theme }) => theme.spaces.s};
    max-width: 420px;
    min-width: 375px;
    width: 100%;
    grid-template-rows: min-content;
`;

export const LogoContainer = styled.div`
    width: 50%;
    max-width: 300px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
`;

export const FieldsContainer = styled(Box)(
    ({ theme }) => css`
        background: #fff;
        border: 2px solid ${theme.colors.border.darker};
        display: grid;
        grid-row-gap: 20px;
        border-radius: ${theme.borderRadiuses.base};
    `
);

export const StyledLinearProgress = styled(LinearProgress)`
    &.MuiLinearProgress-root {
        background-color: white;

        .MuiLinearProgress-barColorPrimary {
            background-color: #f28a22;
        }
    }
`;

export const StyledSelect = styled(Select)`
    & > div {
        padding-top: 0;
        padding-bottom: 0;
    }
    &:before,
    &:after {
        content: none !important;
    }
`;