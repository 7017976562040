import React from "react";
import { HeaderWrapper } from "./style";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { BackButton } from "../../pages/OrderHistoryItem/style";
import BackspaceIcon from "@material-ui/icons/Backspace";

export const ModalHeader = ({ hideCloseBtn, showBackBtn = false, onClose, onBack, ...otherProps }) => {
    return (
        <HeaderWrapper {...otherProps}>
            {showBackBtn && (
                <BackButton style={{ left: 0 }} onClick={onBack}>
                    <BackspaceIcon />
                </BackButton>

            )}
            {!hideCloseBtn && (
                <CloseRoundedIcon onClick={onClose} fill="#37474F" />
            )}
        </HeaderWrapper>
    );
};
