import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
} from "../actionTypes/login";

export const loginRequest = () => ({
    type: LOGIN_REQUEST,
});

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS,
});

export const loginFailure = (payload) => ({
    type: LOGIN_FAILURE,
    payload,
});

export const logout = () => ({
    type: LOGOUT,
});
