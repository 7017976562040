import styled from "styled-components";
import Typography from "../../../../components/Typography";

export const CenteredErrorMessage = styled(Typography).attrs({
    color: "error",
    customVariant: "caption",
})`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
`;
