import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useCallback, useRef, useState } from "react";
import { Popper } from "../../../Popper";
import Typography from "../../../Typography";
import { PriceFlyout } from "./PriceFlyout";

export const Price = ({ id, children, price, productUnits=1, fontSize, orderPage=false }) => {
    const [popperVisibility, setPopperVisibility] = useState(false);
    const textRef = useRef();
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const togglePopper = useCallback(() => {
        setPopperVisibility((curValue) => !curValue);
    }, [setPopperVisibility]);
    const closePopper = useCallback(() => {
        setPopperVisibility(false);
    }, [setPopperVisibility]);
    const openPopper = useCallback(() => {
        setPopperVisibility(true);
    }, [setPopperVisibility]);

    const textProps = isPhoneScreen
        ? {
              onClick: togglePopper,
          }
        : {
              onMouseEnter: openPopper,
              onMouseLeave: closePopper,
          };

    function isDefined(value) {
        return value !== undefined;
    }

    const pricing = (typeof price !== 'number' && price.map((item) => (
    (item[0]<=productUnits) ?  item[1] : undefined)).reverse().filter(isDefined));

    const quantity = (typeof price !== 'number' && price.map((item) => (
        (item[0]<=productUnits) ? item[0] : undefined)).reverse().filter(isDefined));

    const currentPrice = price && (typeof price === 'number' ? price : ((pricing.length === 1 || productUnits === 0)  ? price[0]?.[1] : pricing[0]));
 
    return (
        <>
            {children && typeof children === "function" ? (
                children(typeof price === 'number' || (price && price[0]?.[1]), { ref: textRef, ...textProps })
            ) : (
                <>
                <Typography
                    ref={textRef}
                    {...textProps}
                    customVariant={
                        isPhoneScreen ? "body" : "subheadingSlim"
                    }
                    style={{cursor: "pointer", width: "100%", fontSize: fontSize}}
                    fontWeight={600}
                >
                    {orderPage ? parseFloat(currentPrice).toFixed(2) : currentPrice}</Typography>
                    <Typography
                    fontWeight={600}
                    customVariant={
                        isPhoneScreen ? "body" : "subheadingSlim"
                    }
                     style={{cursor: "pointer", fontSize: fontSize, color: "#bcbcbc"}}>
                        {quantity[0] && ((quantity[0] !== 1) && ` (${quantity[0]}+)`)}
                    </Typography>
                </>
            )}
            {!orderPage && 
            <Popper
                id={id}
                visibility={popperVisibility}
                anchorEl={textRef?.current}
                onClickAway={closePopper}
                ignoreBackground={true}
            >
                <PriceFlyout price={price} />
            </Popper>}
        </>
    );
};
