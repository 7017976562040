export const SEND_RESET_LINK_REQUEST = "SEND_RESET_LINK_REQUEST";
export const SEND_RESET_LINK_SUCCESS = "SEND_RESET_LINK_SUCCESS";
export const SEND_RESET_LINK_FAILURE = "SEND_RESET_LINK_FAILURE";

export const VALIDATE_RESET_LINK_REQUEST = "VALIDATE_RESET_LINK_REQUEST";
export const VALIDATE_RESET_LINK_SUCCESS = "VALIDATE_RESET_LINK_SUCCESS";
export const VALIDATE_RESET_LINK_FAILURE = "VALIDATE_RESET_LINK_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
