import * as loginActions from "../actions/login";
import api from "../../api";
import resources from "../../api/resources";
import { saveAuthState } from "../../api/authHelper";
import { saveState } from "../../utils";

export const login = (username, password) => {
    return async (dispatch) => {
        dispatch(loginActions.loginRequest());
        try {
            const {
                data: { access, refresh },
            } = await api.post(resources.login.login, {
                username,
                password,
            });
            saveState({});

            saveAuthState({
                accessToken: access,
                refreshToken: refresh,
            });
            return dispatch(loginActions.loginSuccess(access, refresh));
        } catch (error) {
            return dispatch(loginActions.loginFailure(error));
        }
    };
};
